import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P11-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page11/Key/E1answerKey.png",
    component: Circle_Write,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { marginLeft: 20, width: 800, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Correct the mistakes with the bold articles in the text.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 250, textAlign: "center" },
        answers: [
          "the best friend",
          "a dog",
          "the United States",
          "a camper van",
          "a serious illness",
          "The film",
          "Colorado",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          textDecoration: "line-through",
          // border: "2px solid",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a best friend",
          "the dog",
          "United States",
          "the camper van",
          "the serious illness",
          "A film",
          "the Colorado",
          "",
        ],
        answers: ["0-0", "1-0", "3-0", "4-0", "5-0", "6-0"],
        initialValue: [],
      },
      Layout: `



           <div style='display:flex'>
           <div style='position:relative'>
                <img src="img/FriendsPlus/Page11/E1/1.jpg" style='height:22cm'/>
                <div style='line-height:31px;width:175mm;font-size:22px;position:absolute;top:428px;left:34px'>
                  Photographer Ben Moon has made the emotional short film
                  about <sup>1</sup><input id=0 type=Circle /> he’s ever had: 
                  <sup>2</sup><input id=1 type=Circle /> called Denali.
                  Denali was just two years old when Ben rescued him from
                  an animal shelter. They spent nearly fifteen years together,
                  travelling around <sup>3</sup><input id=2 type=Circle />
                   in <sup>4</sup><input id=3 type=Circle />, going
                  to the beach and spending time with friends. And when Ben
                  was getting over <sup>5</sup><input id=4 type=Circle />, Denali was constantly
                  by his side, sleeping on his hospital bed. <sup>6</sup><input id=5 type=Circle /> won two
                  awards at a festival in <sup>7</sup><input id=6 type=Circle />, and has taken the
                  internet by storm with its moving story of the close
                  friendship Ben and Denali shared for so long.
                </div> 
           
          </div>
          <div>
          <b>Answer:</b> <br>
          <b>1</b>&ensp;#<br>
          <b>2</b>&ensp;#<br>
          <b>3</b>&ensp;#<br>
          <b>4</b>&ensp;#<br>
          <b>5</b>&ensp;#<br>
          <b>6</b>&ensp;#<br>
          <b>7</b>&ensp;#<br>
          </div>
          </div>
            
          
           
            
        `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P11-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page11/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 70, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adore",
          "envy",
          "look down on",
          "look up to",
          "respect",
          "trust",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with <i>a/an, the</i> or – (no article).`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `


          <div style='width:27cm'>
            <b>1</b>&ensp;# film we just watched must be # worst I’ve seen!<br>
            <b>2</b>&ensp;She works as # volunteer, helping # elderly.<br>
            <b>3</b>&ensp;Although I don’t generally like # cats, I like # one next door.<br>
            <b>4</b>&ensp;Just put # shopping on # table over there, will you?<br>
            <b>5</b>&ensp;We met # family from # Caribbean at the carnival.<br>
            <b>6</b>&ensp;# first camping trip I went on wasn’t in # Europe.<br>
            <b>7</b>&ensp;My dad’s got # new job, but # job is in another city.<br>
            <b>8</b>&ensp;I’ve got # idea: why don’t we get # takeaway later?<br>
          </div>
          
          
          `,
        answer: [
          "The",
          "the",
          "a",
          "the",
          "-",
          "the",
          "the",
          "the",
          "a",
          "the",
          "The",
          "-",
          "a",
          "the",
          "an",
          "a",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P11-E3",
    // audio: "Audios/1.04.mp3",
    exerciseKey: "img/FriendsPlus/Page11/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { marginLeft: 20, width: 600, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Choose the best options (a, b or c) to complete the
sentences.`,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["1-0", "0-4", "2-4", "3-8", "4-4"],
        initialValue: [],
      },
      Layout: `     

            <b>1</b>&ensp;The forecast says there will be just _______ rain at the weekend.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>little <br> a little <br> a lot of</div>
                 </div>
            <b>2</b>&ensp;_______ the best music I’ve heard is on this album.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>Some of <br> Both of <br> Several of</div>
                 </div>
            <b>3</b>&ensp;_______ of my shirts needs washing!
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>Every <br> Every one <br> All</div>
                 </div>
            
          
            <b>4</b>&ensp;_______ drivers follow the new driving regulations.
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                      <div>Some of <br> None <br> Few</div>
                  </div>
            <b>5</b>&ensp;I wouldn’t recommend _______ of the cafés in town.
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                      <div>none <br> either <br> every one</div>
                  </div>
              
           
            
        `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P11-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page11/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Choose the option that is not possible in each sentence.`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["about (x3)", "for (x2)", "on"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-8", "1-4", "2-4", "3-0", "4-8", "5-0", "6-8"],
        initialValue: [],
      },
      Layout: `


            <b>1</b>&ensp;_____ of these scarves would be a good present for Leo.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>Either <br> Both <br> Every</div>
                 </div>
            <b>2</b>&ensp;_____ her friends was at home when she called.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>Every one of <br> None <br> None of</div>
                 </div>
            <b>3</b>&ensp;There’s _____ time before the next lesson begins.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>little <br> few <br> a little</div>
                 </div>
            <b>4</b>&ensp;_____ of us agreed that the exercise was impossible.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>Every <br> Each <br> Every one</div>
                 </div>
            <b>5</b>&ensp;Let’s buy _____ these cakes for the picnic.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>both <br> a few of <br> several</div>
                 </div>
            <b>6</b>&ensp;She organised _____ of her books into alphabetical order.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=5 type=Circle /></div>
                    <div>every <br> all <br> every one</div>
                 </div>
            <b>7</b>&ensp;_____ buddy movies are popular in the USA.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=6 type=Circle /></div>
                    <div>Some <br> Many <br> A lot</div>
                 </div>
            
          
           
            
        `,
    },
  },
  5: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P11-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page11/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      // marginBottom: -70,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 100, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the words below. There are
two extra words
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
         <div style='width:25cm'>
            <b>1</b>&ensp;It was a pretty boring party as there were so # people there.<br>
            <b>2</b>&ensp;Dan got every # of the answers right in yesterday’s homework.<br>
            <b>3</b>&ensp;I only have # little cash – could you lend me some?<br>
            <b>4</b>&ensp;She tried on five pairs of shoes, but # of them fitted.<br>
            <b>5</b>&ensp;‘Would you prefer orange or apple juice?’ ‘I don’t mind – I like # of them.’<br>
            <b>6</b>&ensp;The forecast says there’ll be # sunshine today.<br>
         
          
        </div>
          `,
        answer: ["few", "one", "a", "none", "both", "no"],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P11-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page11/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 100,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with one word in each gap`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
          <div style='width:25cm'>
            In <sup>1</sup># recent survey, two thousand British
            men and women were asked about their friendships.
            The women turned out to be twice as good at making
            friends for life, with one in three saying that their best
            friend was someone they had met at school. In contrast,
            only one in six men were best friends with <sup>2</sup>#
            school friend. Although the men had between fifty
            and a hundred Facebook friends and fifty friends’
            numbers in their phones, they only had a <sup>3</sup>#
            close friends – on average, about five. <sup>4</sup>#
            sexes admitted to having at least one person in their
            friendship group they couldn’t stand; although not
            quite as <sup>5</sup># men as women confessed to this, it
            was still a surprising 13%! <sup>6</sup># survey concluded
            that all <sup>7</sup># us can expect to have fewer close
            friends as we get older, with only about three.
            
          </div>
          
          
          `,
        answer: ["a", "a", "few", "Both", "many", "The", "of"],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P11-E7",
    audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page11/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
