import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P27-E1",
    // audio: "Audios/1.15.mp3",
    exerciseKey: "img/FriendsPlus/Page27/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 40,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "cover your mouth",
          "cross yourlegs",
          "give a thumbs up point",
          "scratch your head",
          "shrug your shoulders",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct options (a–c) to complete the sentences.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "3-4", "1-0", "2-4", "4-8"],
        initialValue: [],
      },
      Layout: `


            <b>1</b>&ensp;I can’t get this tablet to work; I read the instructions properly.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>oughtn’t to have <br> can’t have <br> shouldn’t have</div>
                 </div>
            <b>2</b>&ensp;I ordered the DVD a week ago so it arrived here by now.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>should have <br> could have <br> may have</div>
                 </div>
            <b>3</b>&ensp;I can’t find my phone anywhere. I left it at school.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>should have <br> may have <br> ought to have</div>
                 </div>
            <b>4</b>&ensp;Julie’s not at home. She decided to go out after all.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>was supposed to have <br> must have <br> ought to have</div>
                 </div>
            <b>5</b>&ensp;You promised to help Ed if you didn’t have time.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>mustn’t have <br> couldn’t have <br> shouldn’t have</div>
                 </div>
              
        `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P27-E2",
    // audio: "Audios/1.16.mp3",
    exerciseKey: "img/FriendsPlus/Page27/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 350, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["bite", "frown", "grin", "nod", "shake", "yawn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with up to five words, including
the words in brackets.
`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
               <div style='width:27cm'>
                 <b>1</b>&ensp;Someone #(might / told / me) there was a hole in my jumper!<br>
                 <b>2</b>&ensp;Sandra #(supposed / be / here) by now. I wonder where she is.<br>
                 <b>3</b>&ensp;You #(ought / have / gone) swimming if you have a cold.<br>
                 <b>4</b>&ensp;Wendy didn’t look very happy when I saw her; she #(could / worried / about) going to the dentist.<br>
                 <b>5</b>&ensp;The fridge is empty; Mum #(can’t / been / the) shops yet.<br>
                 <b>6</b>&ensp;Last night #(must / been / cold) – the lake’s frozen.<br>
               </div>
            
            
          `,
        answer: [
          "might have told me",
          "is supposed to be here / was supposed to be here",
          "oughtn't to have gone / ought not to have gone",
          "could have been worried about / could be worried about",
          "can not have gone to the / can't have gone to the",
          "must have been very cold / must have been cold",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P27-E3",
    // audio: "Audios/1.17.mp3",
    exerciseKey: "img/FriendsPlus/Page27/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      background: "none",
      borderBottom: "none",
      width: 144,
      height: 30,
      padding: 0,
      fontSize: 23,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["can’t", "could (x2)", "may", "must", "should"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the email with the correct form of the verbs below.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
            <div style='position:relative'>
               <img src="img/FriendsPlus/Page27/E3/1.jpg" style='height:16cm'/>
              <div style='position:absolute;top:159px;left:253px'>#</div>
              <div style='position:absolute;top:193px;left:414px'>#</div>
              <div style='position:absolute;top:262px;left:509px'>#</div>
              <div style='position:absolute;top:331px;left:46px'>#</div>
              <div style='position:absolute;top:366px;left:423px'>#</div>
              <div style='position:absolute;top:401px;left:369px'>#</div>
             
            </div>

            
            
          `,
        // InputRong: true,
        answer: [
          "could have",
          "should have",
          "may have",
          "must have",
          "could have",
          "can't have|can not have",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P27-E4",
    // audio: "Audios/1.18.mp3",
    exerciseKey: "img/FriendsPlus/Page27/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with <i>needn’t have</i> or <i>didn’t need to</i> and the verb in brackets. In which sentences are both
modals possible?
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       
        <div style='width:26cm'>
          <b>1</b>&ensp;I managed to mend my sunglasses so luckily I #(get) a new pair.<br>
          <b>2</b>&ensp;It was sunny so we #(take) an umbrella.<br>
          <b>3</b>&ensp;We #(catch) a cab as my mum gave us a lift.<br>
          <b>4</b>&ensp;Alison #(buy) a ticket – I had a spare one.<br>
          <b>5</b>&ensp;I #(worry) about the test – when I saw the paper I realised I knew all the answers.<br>
        </div>
          `,
        answer: [
          "did not need to get / didn't need to get",
          "did not need to take / need not have taken / didn't need to take / needn't have taken",
          "did not need to catch / didn't need to catch",
          "did not need to buy / need not have bought / didn't need to buy / needn't have bought",
          "need not have worried / needn't have worried",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P27-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page27/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "25cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "could",
          "didn’t need to",
          "needn’t",
          "ought not to",
          "was supposed to",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the words below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
          <div style='width:26cm'>
            <b>1</b>&ensp;You # have reminded me to do the homework – I did it last night.<br>
            <b>2</b>&ensp;Tanya # be here half an hour ago. Where is she?<br>
            <b>3</b>&ensp;We # have told Jenny about Luca’s party. He didn’t want her to know about it.<br>
            <b>4</b>&ensp;Someone # have warned me that the band’s new album was so awful!<br>
            <b>5</b>&ensp;They knew the way so they # ask for directions<br>
          </div>
          `,
        answer: [
          "need not|needn't",
          "was supposed to",
          "ought not to",
          "could",
          "didn't need to",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P27-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page27/Key/E6answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 850,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      marginLeft: 20,
      width: 850,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Rewrite the sentences using the words in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       
        <div style='width:26cm'>
          <b>1</b>&ensp;Lunch was provided so it wasn’t necessary for them to take sandwiches. (need)<br>#<br>
          <b>2</b>&ensp;She studied hard so she probably passed the exam. (should)<br>#<br>
          <b>3</b>&ensp;I think he’s foreign so it’s possible he didn’t understand you. (might)<br>#<br>
          <b>4</b>&ensp;John isn’t in Rome – I saw him earlier. (can’t)<br>#<br>
          <b>5</b>&ensp;It would have been better if you’d listened to my advice. (ought)<br>#<br>
          <b>6</b>&ensp;Why didn’t you introduce me to your friends? (might)<br>#<br>
        </div>
          `,
        answer: [
          "Lunch was provided so they didn't need to take sandwiches.|Lunch was provided so they did not need to take sandwiches.",
          "She studied hard so she should have passed the exam.",
          "I think he's foreign so he might not have understood you.|I think he is foreign so he might not have understood you.",
          "John can't be in Rome - I saw him earlier.",
          "You ought to have listened to my advice.",
          "You might have introduced me to your friends!",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P27-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page27/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P27-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page27/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
