import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P9-E1",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 150, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct answers (a–c) to complete the text.`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "✓ <br> ✓ <br> ✓",
          "",
          "",
        ],
        answers: ["0-4", "1-0", "2-8", "3-4", "4-0", "5-8"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page9/E1/1.jpg" style='height:23cm'/>
      <div style='display:flex'>  
        <div style='margin-right:200px'>  
        <div style='display:flex'>  
          <b>1</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=0 type=Circle ></div>
                <div style='margin-left:10px'>nowhere <br> never <br> no sooner</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=1 type=Circle ></div>
                <div style='margin-left:10px'>hardly <br> not until <br> only if</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=2 type=Circle ></div>
                <div style='margin-left:10px'>Not only <br> In no way <br> Nowhere</div>
             </div>
          </div>
          
        </div>
        <div>
          <div style='display:flex'>
            <b>4</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=3 type=Circle ></div>
                <div style='margin-left:10px'>At no time <br> Only when <br> Rarely</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=4 type=Circle ></div>
                <div style='margin-left:10px'>No sooner <br> Only once <br> Under no circumstances</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>not only <br> hardly <br> not until</div>
             </div>
          </div>
          
          </div>
          </div>
          <div>
           

        `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P9-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    // stylesTextInput: { width: 150, paddingTop: 3 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adore",
          "envy",
          "look down on",
          "look up to",
          "respect",
          "trust",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Write the words in brackets in the correct order to
complete the sentences.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
              <b>1</b>&ensp;Rarely (so / she / felt / unhappy / had).<br>#<br>
              <b>2</b>&ensp;Nowhere (find / will / you / a / friend / better).<br>#<br>
              <b>3</b>&ensp;Only (argued / have / ever / once / we).<br>#<br>
              <b>4</b>&ensp;In no (did / to / upset / I / anyone / way / mean)!<br>#<br>
              <b>5</b>&ensp;At (should / lies / tell / time / no / you).<br>#<br>
              <b>6</b>&ensp;Under (can / him / no / you / circumstances / trust).<br>#<br>
          </div>
          
          `,
        answer: [
          "had she felt so unhappy",
          "will you find a better friend",
          "once have we ever argued",
          "way did I mean to upset anyone",
          "no time should you tell lies",
          "no circumstances can you trust him",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P9-E3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      border: "2px solid",
      width: 40,
      height: 40,
      borderRadius: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Match the sentence halves.`,
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: ["0-13", "1-12", "2-9", "3-7", "11-4", "5-8", "10-6"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "do_you_usually_do / are_you_usually_doing",
          "I’m_seeing / I_see",
          "We’re_learning / We_learn",
          "does_the_World_Cup_Final_start / is_the_World_Cup_Final_starting",
          "Does_your_aunt_work / Is_your_aunt_working",
          "is_always_using / always_uses",
          "Are_you_going / Do_you_go",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
         <input id=0 type=boxMatch />
         <input id=1 type=boxMatch />
<input id=2 type=boxMatch />
<input id=3 type=boxMatch />
<input id=4 type=boxMatch />
<input id=5 type=boxMatch />
<input id=6 type=boxMatch />
<input id=7 type=boxMatch />
<input id=8 type=boxMatch />
<input id=9 type=boxMatch />
<input id=10 type=boxMatch />
<input id=11 type=boxMatch />
<input id=12 type=boxMatch />
<input id=13 type=boxMatch />
          
            <div style='line-height:47px;display:flex'>
                  <div style='margin-right:300px'>
                    <b>1</b>&ensp;be an adult<br>
                    <b>2</b>&ensp;be a centenarian<br>
                    <b>3</b>&ensp;be a toddler<br>
                    <b>4</b>&ensp;be an infant<br>
                    <b>5</b>&ensp;be elderly<br>
                    <b>6</b>&ensp;be in your teens<br>
                    <b>7</b>&ensp;be middle-aged<br>
                  </div>
                  <div>
                    <b>a</b>&ensp;100+<br>
                    <b>b</b>&ensp;60+<br>
                    <b>c</b>&ensp;13–19<br>
                    <b>d</b>&ensp;40–60<br>
                    <b>e</b>&ensp;1–3<br>
                    <b>f</b>&ensp;0–1<br>
                    <b>g</b>&ensp;18+<br>
                  </div>
            </div>
                  
          
        `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P9-E4",
    // audio: "Audios/2-09.mp3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the dialogue with one word in each gap. Use
negative structures.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["about (x3)", "for (x2)", "on"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `


    <div style='display:flex'>
        <div>
            <b>Ada</b><br>
            <b>Jan</b><br>
            <b>Ada</b><br><br>
            <b>Jan</b><br><br>
            <b>Ada</b><br>
            <b>Jan</b><br><br>
            <b>Ada</b><br>
            <b>Jan</b><br><br>
            <b>Ada</b>
        </div>
        <div style='margin-left:20px;width:20cm'>
               Hi, Jan! <sup>1</sup># you go out with Sara last night?
              <br> Yes, we went out for a pizza.
              <br> I was really sorry <sup>2</sup># to be able to join you.
              Did you have a good time?
              <br> Not really, no. No sooner <sup>3</sup># we arrived
              <sup>4</sup># we had a row!
              <br> Oh, that’s not good.
              <br> I know. And not <sup>5</sup># that, but she isn’t
              speaking to me now.
              <br> <sup>6</sup># it be a good idea to phone her and
              apologise?
              <br> Probably – I regret not <sup>7</sup># to her at the time,
              but I was too angry.
              <br> Well, under <sup>8</sup># circumstances should you
              forget to say sorry!
        </div>
    </div>
          
          `,
        answer: [
          "Didn't",
          "not",
          "had",
          "than",
          "only",
          "Wouldn't",
          "apologising",
          "no",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P9-E5",
    // audio: "Audios/2-10.mp3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -70,
      marginLeft: 20,
      width: 700,
      paddingTop: 3,
    },
    stylesTextInput: { width: 350, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["flatter", "insult", "nag", "praise", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the second sentence so that it means the same
as the first. Write up to five words in each sentence.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
         <div style='width:25cm'>
         <div style='display:flex'> 
            <b>1</b> 
              <div style='margin-left:15px'>
                We mustn’t under any circumstances lose this key.<br>
                Under # lose this key.
              </div>
          </div>
          <div style='display:flex'>
              <b>2</b> 
              <div style='margin-left:15px'>
                This band isn’t at all special.<br>
                In # at all special.
              </div>
          </div>
          <div style='display:flex'>
              <b>3</b> 
              <div style='margin-left:15px'>
                I regretted going as soon as I got there.<br>
                No sooner # I regretted going.
              </div>
          </div>
          <div style='display:flex'>
              <b>4</b> 
              <div style='margin-left:15px'>
                My friends haven’t judged me at any time.<br>
                At no # judged me.
              </div>
          </div>
          <div style='display:flex'>
              <b>5</b> 
              <div style='margin-left:15px'>
                Bill wished he’d kept his news a secret.<br>
                Bill regretted # a secret.
              </div>
          </div>
          <div style='display:flex'>
              <b>6</b> 
              <div style='margin-left:15px'>
                It was fortunate that we didn’t get lost.<br>
                We were lucky #.
              </div>
          </div>
          <div style='display:flex'>
              <b>7</b> 
              <div style='margin-left:15px'>
                I’m sure Jerry went into town last night.<br>
                 # town last night?
              </div>
          </div>
          <div style='display:flex'>
              <b>8</b> 
              <div style='margin-left:15px'>
                I can’t make a decision until I’ve spoken to Pat.<br>
                Not # Pat can I make a decision.
              </div>
          </div>
          
          
        </div>
          `,
        answer: [
          "no circumstances must we",
          "no way is this band",
          "had I got there than",
          "time have my friends",
          "not keeping his news",
          "not to get lost",
          "Didn't Jerry go into",
          "until I've spoken to|until I have spoken to",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P9-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 185,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with the correct form of the verbs below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
          <div style='position:relative'>
            <img src="img/FriendsPlus/Page9/E6/1.jpg" style='height:18cm'/>
            <div style='position:absolute;top:122px;left:48px;width:179mm'>
              Well, it’s been a bit of a strange day. My mum <br> <sup>1</sup>#
              me off this morning for arriving home late last night, but then
              she <sup>2</sup># me for my good marks in the test! On
              the bus to school, my best mate Harry kept <sup>3</sup>#
              me about lending him my mountain bike. He went on and on
              about it for the whole journey! Once at school, everyone made
              jokes and  <sup>4</sup># me about my new haircut. Well,
              everyone except for Chloe! She <br> <sup>5</sup># me on my
              ‘stylish’ hair and said it looked great! But then I realised that
              I’d left my English homework at home. My English teacher
              <sup>6</sup># me not to forget my homework again and
              gave me extra work as a punishment!
            </div>
            
            
          </div>
          
          
          `,
        answer: [
          "told",
          "praised",
          "nagging",
          "teased",
          "complimented",
          "warned",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P9-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
