import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E1",
    audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page3/Key/E1answerKey.png",
    component: Circle_Write,
    // textAlign:'center',

    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.08 colorimg='#d1b4d8'></headphone> Read the <span style='color:#ca2a5a'>Listening Strategy</span>. Then listen to the 
          sentences and choose the tone of voice (a or b) each 
          speaker uses.`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-0", "5-0", "6-0", "7-4"],
        initialValue: [],
      },
      Layout: `
            <div style='display:flex'>
                    <div style='margin-right:100px'>
                          <b>1</b>&ensp;Is that letter for me?
                          <div style='display:flex'>
                              <div style='margin-left:20px;margin-right:5px'><input id=0 type='Circle'/></div>
                              <div >
                                  nervous <br>
                                  surprised<br>
                              </div>
                          </div>
                          <b>2</b>&ensp;No, that’s not right. Let me show you.
                          <div style='display:flex'>
                              <div style='margin-left:20px;margin-right:5px'><input id=1 type='Circle'/></div>
                              <div >
                                 angry <br>
                                 patient<br>
                              </div>
                          </div>
                          <b>3</b>&ensp;This is my seat.
                          <div style='display:flex'>
                              <div style='margin-left:20px;margin-right:5px'><input id=2 type='Circle'/></div>
                              <div >
                                certain <br>
                                uncertain<br>
                              </div>
                          </div>
                          <b>4</b>&ensp;That makes me feel much better.
                          <div style='display:flex'>
                              <div style='margin-left:20px;margin-right:5px'><input id=3 type='Circle'/></div>
                              <div >
                                sarcastic <br>
                                enthusiastic<br>
                              </div>
                          </div>
                    </div>

                    <div>
                          <b>5</b>&ensp;That didn’t hurt at all.
                          <div style='display:flex'>
                              <div style='margin-left:20px;margin-right:5px'><input id=4 type='Circle'/></div>
                              <div >
                                surprised <br>
                                sarcastic<br>
                              </div>
                          </div>
                          <b>6</b>&ensp;The film’s quite good.
                          <div style='display:flex'>
                              <div style='margin-left:20px;margin-right:5px'><input id=5 type='Circle'/></div>
                              <div >
                                unsure <br>
                                enthusiastic<br>
                              </div>
                          </div>
                          <b>7</b>&ensp;He’s never on time.
                          <div style='display:flex'>
                              <div style='margin-left:20px;margin-right:5px'><input id=6 type='Circle'/></div>
                              <div >
                                irritated <br>
                                patient<br>
                              </div>
                          </div>
                          <b>8</b>&ensp;This party’s brilliant.
                          <div style='display:flex'>
                              <div style='margin-left:20px;margin-right:5px'><input id=7 type='Circle'/></div>
                              <div >
                                sarcastic <br>
                                enthusiastic<br>
                              </div>
                          </div>
                    </div>
            </div>
                  
          
        `,
    },
  },
  2: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E2",
    audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page3/Key/E2answerKey.png",
    component: T6,

    stylesTextInput: { width: 350, paddingTop: 3 },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.08 colorimg='#d1b4d8'></headphone> Listen again and repeat the sentences, copying 
          the tone of voice.`,
        color: "black",
        left: -20,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display:flex'>
                      <div style='margin-right:100px'>
                            <b>1</b>&ensp;Is that letter for me?
                            <div style='display:flex'>
                                <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                                <div >
                                    nervous <br>
                                    surprised<br>
                                </div>
                            </div>
                            <b>2</b>&ensp;No, that’s not right. Let me show you.
                            <div style='display:flex'>
                                <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                                <div >
                                   angry <br>
                                   patient<br>
                                </div>
                            </div>
                            <b>3</b>&ensp;This is my seat.
                            <div style='display:flex'>
                                <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                                <div >
                                  certain <br>
                                  uncertain<br>
                                </div>
                            </div>
                            <b>4</b>&ensp;That makes me feel much better.
                            <div style='display:flex'>
                                <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                                <div >
                                  sarcastic <br>
                                  enthusiastic<br>
                                </div>
                            </div>
                      </div>
  
                      <div>
                            <b>5</b>&ensp;That didn’t hurt at all.
                            <div style='display:flex'>
                                <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                                <div >
                                  surprised <br>
                                  sarcastic<br>
                                </div>
                            </div>
                            <b>6</b>&ensp;The film’s quite good.
                            <div style='display:flex'>
                                <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                                <div >
                                  unsure <br>
                                  enthusiastic<br>
                                </div>
                            </div>
                            <b>7</b>&ensp;He’s never on time.
                            <div style='display:flex'>
                                <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                                <div >
                                  irritated <br>
                                  patient<br>
                                </div>
                            </div>
                            <b>8</b>&ensp;This party’s brilliant.
                            <div style='display:flex'>
                                <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                                <div >
                                  sarcastic <br>
                                  enthusiastic<br>
                                </div>
                            </div>
                      </div>
              </div>
          
          `,
        answer: [
          "My mum asked me why I was home so late.",
          "if I had left school on time that day.",
          "when I was going to tidy my room.",
          "if I had seen my brother that afternoon.",
          "if I’d help her with the shopping the next day.",
          "if I'd like her to give me some money for a pizza.",
        ],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E3",
    exerciseKey: "img/FriendsPlus/Page3/Key/E3answerKey.png",
    component: T6,
    recorder: true,
    hideBtnFooter: true,
    // textAlign:"center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 650, paddingTop: 3 },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Now try saying the sentences in exercise 1 in the other 
          tone of voice.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
                    <div style='margin-right:100px'>
                          <b>1</b>&ensp;Is that letter for me?
                          <div style='display:flex'>
                              <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                              <div >
                                  nervous <br>
                                  surprised<br>
                              </div>
                          </div>
                          <b>2</b>&ensp;No, that’s not right. Let me show you.
                          <div style='display:flex'>
                              <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                              <div >
                                 angry <br>
                                 patient<br>
                              </div>
                          </div>
                          <b>3</b>&ensp;This is my seat.
                          <div style='display:flex'>
                              <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                              <div >
                                certain <br>
                                uncertain<br>
                              </div>
                          </div>
                          <b>4</b>&ensp;That makes me feel much better.
                          <div style='display:flex'>
                              <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                              <div >
                                sarcastic <br>
                                enthusiastic<br>
                              </div>
                          </div>
                    </div>

                    <div>
                          <b>5</b>&ensp;That didn’t hurt at all.
                          <div style='display:flex'>
                              <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                              <div >
                                surprised <br>
                                sarcastic<br>
                              </div>
                          </div>
                          <b>6</b>&ensp;The film’s quite good.
                          <div style='display:flex'>
                              <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                              <div >
                                unsure <br>
                                enthusiastic<br>
                              </div>
                          </div>
                          <b>7</b>&ensp;He’s never on time.
                          <div style='display:flex'>
                              <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                              <div >
                                irritated <br>
                                patient<br>
                              </div>
                          </div>
                          <b>8</b>&ensp;This party’s brilliant.
                          <div style='display:flex'>
                              <div style='margin-left:22px;margin-right:10px'><b>a</b> <br> <b>b</b></div>
                              <div >
                                sarcastic <br>
                                enthusiastic<br>
                              </div>
                          </div>
                    </div>
            </div>
        
        `,
        answer: [
          "My mum asked me why I was home so late.",
          "if I had left school on time that day.",
          "when I was going to tidy my room.",
          "if I had seen my brother that afternoon.",
          "if I’d help her with the shopping the next day.",
          "if I'd like her to give me some money for a pizza.",
        ],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E4",
    audio: "Audios/2-09.mp3",
    exerciseKey: "img/FriendsPlus/Page3/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.09 colorimg='#d1b4d8'></headphone> Listen and repeat the different intonation 
          patterns for statements.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 100, paddingTop: 3 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#92278f",
        marginLeft: 0,
      },
    ],

    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page3/E4/1.jpg" style='height:6cm'/>
        
        `,
        answer: [
          `Dan asked Zoe if she was going to the concert the next evening. Zoe said she wasn't. She said she didn't have any money. Dan said he'd lend her some. He asked her how much she needed. Zoe said that was really kind of him, but she had to finish her homework too. Dan asked her if she'd like him to help her finish it. Zoe asked him if he spoke German. Dan said he didn't. He asked her if her cousin did. Zoe said she did. She said perhaps she should ask her.`,
        ],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E5",
    audio: "Audios/2-10.mp3",
    exerciseKey: "img/FriendsPlus/Page3/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      borderRadius: 5,
      border: "2px solid",
      width: 40,
      height: 37,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=2.10 colorimg='#d1b4d8'></headphone> Listen to these statements being said in two 
          different ways. Number them 1 and 2 in the order you 
          hear them. Practise saying them.`,
        color: "black",
        left: 0,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          
          <div style='display:flex;line-height:46px'>
                      <div style='margin-right:100px'>
                            <b>1</b>&ensp;You like this kind of story.
                            <div style='display:flex'>
                                <div style='margin-left:25px;margin-right:10px'> <b>a</b> <br> <b>b</b></div>
                                <div>
                                    nervous#<br>
                                    surprised#<br>
                                </div>
                            </div>
                            <b>2</b>&ensp;This is just what I wanted.
                            <div style='display:flex'>
                                <div style='margin-left:25px;margin-right:10px'> <b>a</b> <br> <b>b</b></div>
                                <div >
                                   angry#<br>
                                   patient#<br>
                                </div>
                            </div>
                            <b>3</b>&ensp;This isn’t quite what I was expecting.
                            <div style='display:flex'>
                                <div style='margin-left:25px;margin-right:10px'> <b>a</b> <br> <b>b</b></div>
                                <div >
                                  certain#<br>
                                  uncertain#<br>
                                </div>
                            </div>
                      </div>
                      <div>
                            <b>4</b>&ensp;He thinks it’s a reasonable price.
                            <div style='display:flex'>
                                <div style='margin-left:25px;margin-right:10px'> <b>a</b> <br> <b>b</b></div>
                                <div >
                                  sarcastic#<br>
                                  enthusiastic#<br>
                                </div>
                            </div>
                            <b>5</b>&ensp;This steak is the best I’ve ever eaten.
                            <div style='display:flex'>
                                <div style='margin-left:25px;margin-right:10px'> <b>a</b> <br> <b>b</b></div>
                                <div >
                                  surprised#<br>
                                  sarcastic#<br>
                                </div>
                            </div>
                            <b>6</b>&ensp;That’s really nice of him.
                            <div style='display:flex'>
                                <div style='margin-left:25px;margin-right:10px'> <b>a</b> <br> <b>b</b></div>
                                <div >
                                  unsure #<br>
                                  enthusiastic #<br>
                                </div>
                            </div>
                      </div>
              </div>
          `,
        answer: [`1`, "2", "2", `1`, `1`, "2", "2", `1`, "2", `1`, `1`, "2"],
      },
    ],
  },
  6: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E6",
    audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page3/Key/E6answerKey.png",
    component: Circle_Write,
    // textAlign:'center',

    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name=2.11 colorimg='#d1b4d8'></headphone> Read the <span style='color:#ca2a5a'>Listening Strategy</span>. Then listen to the 
          sentences and choose the tone of voice (a or b) each 
          speaker uses.`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-4", "1-12", "2-0", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
            <div style=''>
                <b>1</b>&ensp;How does Max feel when he starts telling his story?
                <div style='display:flex'>
                    <div style='margin-left:20px;margin-right:10px'>
                        <input id=0 type='Circle'/>
                    </div>
                    <div>
                        Reluctant to tell it.<br>
                        Impatient with interruptions.<br>
                        Unsure how the story goes.<br>
                        Worried that it is too scary.<br>
                    </div>
                </div>
                <b>2</b>&ensp;What is the story about?
                <div style='display:flex'>
                    <div style='margin-left:20px;margin-right:10px'>
                        <input id=1 type='Circle'/>
                    </div>
                    <div>
                        A ghost.<br>
                        A student who is lost.<br>
                        What to do in bad weather.<br>
                        The inhabitants of a village in Ireland.<br>
                    </div>
                </div>
                <b>3</b>&ensp;What do the people listening think of the story?
                <div style='display:flex'>
                    <div style='margin-left:20px;margin-right:10px'>
                        <input id=2 type='Circle'/>
                    </div>
                    <div>
                        It’s gripping.<br>
                        It’s hilarious throughout.<br>
                        It’s annoying.<br>
                        It’s too similar to the first one.<br>
                    </div>
                </div>
                <b>4</b>&ensp;What does Caro think of Max’s comment about her?
                <div style='display:flex'>
                    <div style='margin-left:20px;margin-right:10px'>
                        <input id=3 type='Circle'/>
                    </div>
                    <div>
                        She’s really annoyed.<br>
                        She admits that Max’s comment could be right.<br>
                        She criticises Max in self-defence.<br>
                        She does not trust his opinion of her.<br>
                    </div>
                </div>
                <b>5</b>&ensp;What does Max agree with Jill about?
                <div style='display:flex'>
                    <div style='margin-left:20px;margin-right:10px'>
                        <input id=4 type='Circle'/>
                    </div>
                    <div>
                        That Caro is easily fooled.<br>
                        That Caro is more good-natured than he is.<br>
                        That Ian’s story is better than his.<br>
                        That Ian should buy the coffees.<br>
                    </div>
                </div>
            
            </div>
                  
          
        `,
    },
  },
};

export default json;
