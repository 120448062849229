import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P8-E1",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page8/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 150, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the words below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
          <div style='width:26cm'>
              <b>1</b>&ensp;I don’t really understand Kevin or his jokes. We really aren’t on the same #.<br>
              <b>2</b>&ensp;Sara feels # for me for failing my exam, but it was my own fault.<br>
              <b>3</b>&ensp;We’ve got a lot in # with each other and we share quite a few interests.<br>
              <b>4</b>&ensp;My mother doesn’t see # to eye with me about my plans for the weekend.<br>
              <b>5</b>&ensp;Although Jack has moved to London, we are still very # and are in contact every day.<br>
              <b>6</b>&ensp;I’m a bit # of giving my email address to people online that I don’t know.<br>
          </div>
          
          `,
        answer: ["wavelength", "sorry", "common", "eye", "close", "wary"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P8-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page8/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 200,
      paddingTop: 8,
    },
    // stylesTextInput: { width: 150, paddingTop: 3 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adore",
          "envy",
          "look down on",
          "look up to",
          "respect",
          "trust",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Replace the underlined words with the correct form of
the verbs below.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
          <div style='width:26cm'>
              <b>1</b>&ensp;We should treat the environment <u>carefully</u> and not pollute it. #<br>
              <b>2</b>&ensp;Andy <u>thinks very highly of</u> his big brother Tom and wants to be just like him. #<br>
              <b>3</b>&ensp;Some of his friends <u>are jealous of</u> the success that he has had. #<br>
              <b>4</b>&ensp;My sister warned me not to <u>have confidence in</u> him. #<br>
              <b>5</b>&ensp;She’s afraid they <u>think they are better than</u> her because she doesn’t have a job. #<br>
              <b>6</b>&ensp;They have one son and they <u>really love</u> him. #<br>
          </div>
          
          `,
        answer: [
          "respect",
          "looks up to",
          "envy",
          "trust",
          "look down on",
          "adore",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P8-E3",
    audio: "Audios/1.02.mp3",
    exerciseKey: "img/FriendsPlus/Page8/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      border: "2px solid",
      width: 40,
      height: 40,
      borderRadius: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.02 color=white colorimg='#d1b4d8'></headphone> Listen and choose the correct answers (a–c).`,
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-8", "1-0", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
           
            <b>1</b>&ensp;The man in dialogue 1 suggests that he and Eva ___ .
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>are very close to each other <br> adore each other <br> have nothing in common with each other</div>
                 </div>
            <b>2</b>&ensp;The woman in the second dialogue ___ her friend’s family.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>looks down on <br> feels sorry for <br> adores</div>
                 </div>
            <b>3</b>&ensp;The two people in dialogue 3 ___ the man they see.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>are wary of <br> are on the same wavelength as <br> respect</div>
                 </div>
            
          
            <b>4</b>&ensp;How does the girl in dialogue 4 feel about her brother?
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                      <div>She envies him. <br> She looks up to him. <br> She despises him.</div>
                  </div>
              
           
            
        `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P8-E4",
    // audio: "Audios/2-09.mp3",
    exerciseKey: "img/FriendsPlus/Page8/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the prepositions below.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 100, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["about (x3)", "for (x2)", "on"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
    <div style='width:25cm'>
            <b>1</b>&ensp;I was complimented # my winning entry for the poetry competition by the head teacher.<br>
            <b>2</b>&ensp;My dad is always being teased # his terrible cooking.<br>
            <b>3</b>&ensp;We were warned # the dangers of cycling at night.<br>
            <b>4</b>&ensp;Sam was told off # not taking his medicine.<br>
            <b>5</b>&ensp;The whole class might be lectured # behaving well when in public places.<br>
            <b>6</b>&ensp;We’ve all been praised # our performance in the play.<br>
    </div>
          
          `,
        answer: ["on", "about", "about", "for", "about", "for"],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P8-E5",
    // audio: "Audios/2-10.mp3",
    exerciseKey: "img/FriendsPlus/Page8/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -70,
      marginLeft: 20,
      width: 700,
      paddingTop: 3,
    },
    stylesTextInput: { width: 100, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["flatter", "insult", "nag", "praise", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Match the verbs below with the situations.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
         <div style='width:25cm'>
          <b>1</b>&ensp;‘That’s a fantastic piece of work, Lily. Well done!’ #<br>
          <b>2</b>&ensp;‘I really like your new suit. You look great in it!’ #<br>
          <b>3</b>&ensp;‘You are a complete idiot, aren’t you?’ #<br>
          <b>4</b>&ensp;‘Don’t jump into the sea from the rocks. It’s extremely dangerous!’ #<br>
          <b>5</b>&ensp;‘Oh, please, please, please let me stay out late on Friday night. I promise that if you do, I’ll …’ #<br>
          
          
        </div>
          `,
        answer: ["praise", "flatter", "insult", "warn", "nag"],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P8-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page8/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 185,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with the correct form of the verbs below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
          <div style='position:relative'>
            <img src="img/FriendsPlus/Page8/E6/1.jpg" style='height:18cm'/>
            <div style='position:absolute;top:122px;left:48px;width:179mm'>
              Well, it’s been a bit of a strange day. My mum <br> <sup>1</sup>#
              me off this morning for arriving home late last night, but then
              she <sup>2</sup># me for my good marks in the test! On
              the bus to school, my best mate Harry kept <sup>3</sup>#
              me about lending him my mountain bike. He went on and on
              about it for the whole journey! Once at school, everyone made
              jokes and  <sup>4</sup># me about my new haircut. Well,
              everyone except for Chloe! She <br> <sup>5</sup># me on my
              ‘stylish’ hair and said it looked great! But then I realised that
              I’d left my English homework at home. My English teacher
              <sup>6</sup># me not to forget my homework again and
              gave me extra work as a punishment!
            </div>
            
            
          </div>
          
          
          `,
        answer: [
          "told",
          "praised",
          "nagging",
          "teased",
          "complimented",
          "warned",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P8-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page8/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
