import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P30-E1",
    audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page30/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 250, paddingTop: 7 },
    hintBox: [
      {
        src: ["bonds", "touch", "time", "commitment", "isolation"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=1.20   color=white colorimg='#d1b4d8'></headphone> Listen to a student doing the task below. Which
of the four prompts (A–D) does she not mention?
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["A <br> B <br> C <br> D"],
        answers: ["0-12"],
        initialValue: [],
      },
      Layout: `

      <div style='width:20cm;background:rgb(229,239,230);padding:10px 20px;border-radius:5px'>
         Look at the pictures. Then talk for about one minute about
        how people in your country celebrate International
        Women’s Day.
        
        <div style='display:flex;line-height:47px'>
          <div><input id=0 type=Circle /></div>
          <div>
              What is the history behind the celebration?<br>
              Do people usually give or receive gifts and/or cards?<br>
              Are there any other annual traditions?<br>
              Do you personally take part in any celebrations?<br>
          </div>
        </div>
      </div>
      

           
            
              
        `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P30-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page30/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 200, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["far", "now", "something", "subject"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:rgb(0,147,69)'>Reading Strategy</span>. Complete phrases (1–4) with the
words below.`,
        width: "26cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
         <img src="img/FriendsPlus/Page30/E2/1.jpg" style='height:6cm'/> <br>
          <b>1</b>&ensp;As # as the history of the event is concerned, …<br>
          <b>2</b>&ensp;Turning # to the topic of …<br>
          <b>3</b>&ensp;That brings me to the # of …<br>
          <b>4</b>&ensp;There’s # else I’d like to talk about: …<br>

        
        
          
          `,
        // InputRong: true,
        answer: ["far", "now", "subject", "something"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P30-E3",
    audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page30/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      margin: "0px 10px",
      background: "none",
      width: 40,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.20   color=white colorimg='#d1b4d8'></headphone> Listen again. Match phrases (1–4) from exercise 2
with endings (a–f) below. There are two extra endings.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
            
            <b>a</b>&ensp;#marches on International Women’s Day.<br>
            <b>b</b>&ensp;#home-made greetings cards.<br>
            <b>c</b>&ensp;#national holidays.<br>
            <b>d</b>&ensp;#gifts, the most common one is flowers.<br>
            <b>e</b>&ensp;#I’m not really an expert.<br>
            <b>f</b>&ensp;#celebrations at school.<br>
        </div>

       
        
          
          `,
        InputRong: true,
        answer: ["3", "", "4", "2", "1", ""],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P30-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page30/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Look at the task above. Make notes for each of the four
prompts.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],

    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page30/E4/1.jpg" style='height:7.5cm'/> <br>
        <div>
            <b>1</b>&ensp;Does food or drink play a part in the celebration?<br>#<br>
            <b>2</b>&ensp;Do people usually give or receive gifts and/or cards?<br>#<br>
            <b>3</b>&ensp;Are there any other traditions?<br>#<br>
            <b>4</b>&ensp;What do you personally usually do to celebrate?<br>#<br>
        </div>

       
        
          
          `,
        InputRong: true,
        answer: ["3", "", "4", "2", "1", ""],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P30-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page30/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      // marginBottom: -70,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 600, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write sentences to illustrate the meaning of the phrasal
verbs in the table in exercise 4.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=5 ></textarea>
          `,
        answer: ["few", "one", "a", "none", "both", "no"],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P30-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page30/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 100,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with one word in each gap`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
          <div style='width:25cm'>
            In <sup>1</sup># recent survey, two thousand British
            men and women were asked about their friendships.
            The women turned out to be twice as good at making
            friends for life, with one in three saying that their best
            friend was someone they had met at school. In contrast,
            only one in six men were best friends with <sup>2</sup>#
            school friend. Although the men had between fifty
            and a hundred Facebook friends and fifty friends’
            numbers in their phones, they only had a <sup>3</sup>#
            close friends – on average, about five. <sup>4</sup>#
            sexes admitted to having at least one person in their
            friendship group they couldn’t stand; although not
            quite as <sup>5</sup># men as women confessed to this, it
            was still a surprising 13%! <sup>6</sup># survey concluded
            that all <sup>7</sup># us can expect to have fewer close
            friends as we get older, with only about three.
            
          </div>
          
          
          `,
        answer: ["a", "a", "few", "Both", "many", "The", "of"],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P30-E7",
    audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page30/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
