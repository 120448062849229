import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P46-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 200,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: ["something", "sure", "say", "looks"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences about photos A and B with the
words below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='width:27cm'>
            <b>1</b>&ensp;I can’t be #, but I don’t think that it’s a job interview.<br>
            <b>2</b>&ensp;It # like they’re in the open air.<br>
            <b>3</b>&ensp;The interviewee is giving her opinions, or # like that.<br>
            <b>4</b>&ensp;I’d # that the interviewee is very confident and assertive.<br>
             
        </div>
          <img src="img/FriendsPlus/Page46/E1/1.jpg" style='height:18cm'/>
          `,
        answer: ["sure", "looks", "something", "say"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P46-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 250, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cook", "learn", "look", "travel", "wait", "work"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the gaps in sentences (1–5) with the phrases (a–f) below.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `






            <div style='display:flex;margin-bottom:20px;line-height:50px'>
              <div>
                <b>1</b>&ensp;In both photos, you can see.<br>
                <b>2</b>&ensp;The common theme in the photos is.<br>
                <b>3</b>&ensp;The main difference between the photos is that.<br>
                <b>4</b>&ensp;In the first photo , whereas in the second photo.<br>
                <b>5</b>&ensp;Unlike the second photo, the first photo shows.<br>
              </div>
              <div style='margin:0px 50px'>
                <input text-align=center border="2px solid" border-radius=5px width=100px height=40px maxlength=4 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=100px height=40px maxlength=4 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=100px height=40px maxlength=4 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=100px height=40px maxlength=4 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=100px height=40px maxlength=4 /><br>
              </div>
            </div>
              <div>
                 <b>a</b>&ensp;there must be others helping with recording the interview<br>
                 <b>b</b>&ensp;giving interviews<br>
                 <b>c</b>&ensp;the interview is filmed automatically<br>
                 <b>d</b>&ensp;the interviewer is holding a microphone<br>
                 <b>e</b>&ensp;the interviewer and interviewee seem to be having a chat<br>
                 <b>f</b>&ensp;someone is answering questions and the interview is recorded<br>
               </div>

            
            
          `,
        answer: ["f", "b", "a", "e, d", "c"],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P46-E3",
    audio: "Audios/1.28.mp3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },

    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      // padding: 0,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "25cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "awful",
          "delighted",
          "ecstatic",
          "exhausted",
          "fascinated",
          "hilarious",
          "miserable",
          "starving",
          "terrible",
          "terrified",
          "thrilled",
          "wonderful",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone color=white name=1.28 colorimg='#d1b4d8'></headphone> Read the <span style='color:#009345'>Listening Strategy</span>. Read the  and the task above.
Then listen to a student doing the task. Which three of
the extreme adjectives below does she use? Match them
with adverbs (1–3).
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
       <div style='width:27cm;line-height:50px'>
            <b>1</b>&ensp;really#<br>
            <b>2</b>&ensp;completely#<br>
            <b>3</b>&ensp;totally#<br>
            
           
        </div>

          `,
        answer: ["wonderful", "thrilled", "miserable"],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P46-E4",
    audio: "Audios/1.28.mp3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone color=white name=1.28 colorimg='#d1b4d8'></headphone> Listen again. Which two of the points (a–c) does
the student mention in answer to the question in the task?
`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 900, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 200, textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓"],
        answers: ["1-0", "2-0"],
        initialValue: [],
      },
      Layout: `
            <div style='line-height:50px;width:25cm'>
            <b>a</b>&ensp;They find the interviewer very competent.&ensp;<span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=0 type=Circle /></span> <br>
            <b>b</b>&ensp;The interviewees know a lot about the topic and feel enthusiastic about giving their opinions.&ensp;<span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=1 type=Circle /></span> <br>
            <b>c</b>&ensp;They are self-confident because they are in a relaxed atmosphere.&ensp;<span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none" id=2 type=Circle /></span> <br>
           
            </div>

  `,
    },
  },
  5: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P46-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      marginLeft: 10,
      width: 900,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 800,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["date", "enjoy", "find", "not imagine", "live", "star", "think"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Look at the task and the photos below. Then read the
questions (1–4) and write notes.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
                <b>1</b>&ensp;What is the common theme of the photos? <textarea id=0 rows=2></textarea>
                <b>2</b>&ensp;What can you see in both photos? <textarea id=1 rows=2></textarea>
                <b>3</b>&ensp;What is the main difference between the photos? <textarea id=2 rows=2></textarea>
                <b>4</b>&ensp;Do you think celebrities should spend more time with their fans? Why? / Why not? <textarea id=3 rows=2></textarea>
           
        </div>

        <img src="img/FriendsPlus/Page46/E1/1.jpg" style='height:18cm'/>
        
            
            
          `,

        answer: [
          "The common theme is celebrities and their fans.",
          "There are celebrities and lots of fans in both photos. Some of the fans have pens and paper for autographs. Both celebrities have sunglasses.",
          "In photo B there are lots of phones and a girl is taking a selfie. The celebrity is a man, whereas in photo A it's a woman.",
          "",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P46-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      // padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Now do the task using your notes from exercise 5.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    questions: [
      {
        title: `

         
            
            
          `,
        answer: [
          "hadn't played tennis",
          "had eaten her dinne",
          "had been saving for ages",
          "because he had broken his arm.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P46-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P46-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
