import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P60-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page60/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "cover your mouth",
          "cross yourlegs",
          "give a thumbs up point",
          "scratch your head",
          "shrug your shoulders",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match the sentences halves.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div style='display:flex;margin-bottom:20px;line-height:50px'>
              <div>
                <b>1</b>&ensp;Nowadays there is a constant demand<br>
                <b>2</b>&ensp;Do you have any objections<br>
                <b>3</b>&ensp;Many people have a preference<br>
                <b>4</b>&ensp;It’s unlikely that the existence<br>
                <b>5</b>&ensp;It’s easy to develop an addiction<br>
                <b>6</b>&ensp;Does anyone think our dependence<br>
                <b>7</b>&ensp;The 1950s Space Age had a great effect<br>
              </div>
              <div style='margin:0px 50px'>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
              </div>
              <div>
                 <b>a</b>&ensp;to inviting Emma for dinner?<br>
                 <b>b</b>&ensp;on computers nowadays is dangerous?<br>
                 <b>c</b>&ensp;on people’s belief in aliens.<br>
                 <b>d</b>&ensp;for watching sci-fi rather than fantasy films.<br>
                 <b>e</b>&ensp;to video games if you play them too often.<br>
                 <b>f</b>&ensp;for better and faster technology.<br>
                 <b>g</b>&ensp;of life on other planets will ever be proved.<br>
               </div>
            </div>

            
            
          `,
        answer: ["f", "a", "d", "g", "e", "b", "c"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P60-E2",
    exerciseKey: "img/FriendsPlus/Page60/Key/E2answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      width: 146,
      borderBottom: "none",
      background: "none",
      paddingTop: 3,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "belief",
          "difference",
          "evidence",
          "increase",
          "interest",
          "need",
          "obsession",
          "reason",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],

    titleQuestion: [
      {
        num: "2",
        title: `Complete the text with the words below.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page60/E2/1.jpg" style='height:27cm'/>
          <div style='position:absolute;top:583px;left:561px'>#</div>
          <div style='position:absolute;top:613px;left:406px'>#</div>
          <div style='position:absolute;top:642px;left:323px'>#</div>
          <div style='position:absolute;top:701px;left:119px'>#</div>
          <div style='position:absolute;top:760px;left:194px'>#</div>
          <div style='position:absolute;top:849px;left:119px'>#</div>
          <div style='position:absolute;top:879px;left:328px'>#</div>
          <div style='position:absolute;top:938px;left:190px'>#</div>
        </div>
        `,
        answer: [
          "interest",
          "belief",
          "evidence",
          "obsession",
          "increase",
          "reason",
          "difference",
          "need",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P60-E3",
    exerciseKey: "img/FriendsPlus/Page60/Key/E3answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      borderBottom: "none",
      width: 71,
      background: "none",
      paddingTop: 3,
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Read the <i style='color:#92278f'>Vocab boost!</i> box. Then complete the mind maps 
          with the correct prepositions.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page60/E3/1.jpg" style='height:6cm'/>
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page60/E3/2.jpg" style='height:11cm'/>
          <div style='position:absolute;top:103px;left:296px'>#</div>
          <div style='position:absolute;top:357px;left:296px'>#</div>
        </div>
        
        `,
        answer: ["in", "for"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P60-E4",
    exerciseKey: "img/FriendsPlus/Page60/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the correct prepositions.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 70, paddingTop: 3 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#92278f",
        marginLeft: 0,
      },
    ],

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;Is there really no possibility# you changing your mind? <br>
          <b>2</b>&ensp;This is probably a matter# the police. <br>
          <b>3</b>&ensp;There’s a big difference# price between taking the bus and getting a taxi.<br>
          <b>4</b>&ensp;Is your taste# clothes the same as your friends’? <br>
          <b>5</b>&ensp;There’s been a sharp rise# temperatures this month. <br>
          <b>6</b>&ensp;The police didn’t find any evidence# a break-in.<br>
        </div>
        `,
        answer: ["of", "for", "in", "in", "in", "of"],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P60-E5",
    exerciseKey: "img/FriendsPlus/Page60/Key/E5answerKey.png",
    component: Circle_Write,
    stylesTextInput: { marginLeft: 25, width: 900 },
    titleQuestion: [
      {
        num: "5",
        title: `Use a dictionary and correct the mistakes with prepositions 
          in the sentences. Tick the two sentences that do not 
          contain any mistakes.`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { fontSize: 24, marginLeft: 30, width: 900 },
        answers: [
          "No one expected the band to rise to fame so quickly.",
          "",
          "Bill and his girlfriend have had a difference of opinion and aren't speaking.",
          "You look very pale - is something the matter with you?",
          "",
        ],
        initialValue: [],
      },
      Circle: {
        TickRong: true,
        initialWordStyle: {
          marginLeft: 10,
          padding: "1px 10px",
          border: "2px solid",
          borderRadius: 5,
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          borderRadius: 5,
          // borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓", "✓", "✓", "✓"],
        answers: ["1-0", "4-0"],
        initialValue: [],
      },
      Layout: `

            <div style='line-height:45px;width:27cm'>
            <b>1</b>&ensp;No one expected the band to rise in fame so quickly.<input id=0 type=Circle /> <br> # <br>
            <b>2</b>&ensp;My cousin’s gap year gave her a real taste for travel.<input id=1 type=Circle /> <br> # <br>
            <b>3</b>&ensp;Bill and his girlfriend have had a difference in opinion and aren’t speaking.<input id=2 type=Circle /> <br> # <br>
            <b>4</b>&ensp;You look very pale – is something the matter of you?<input id=3 type=Circle /> <br> # <br>
            <b>5</b>&ensp;Customers have recently been experiencing difficulty in using this website. <input id=4 type=Circle /> <br> # <br>
           
            </div>

  `,
    },
  },
  6: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P60-E6",
    exerciseKey: "img/FriendsPlus/Page60/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: { width: 950, paddingTop: 3 },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthfu"],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the sentences with the correct preposition and 
          your own ideas.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style=''>
          <b>1</b>&ensp;I’ve never understood the point. #<br>
          <b>2</b>&ensp;Where I live, there’s been a rise. #<br>
          <b>3</b>&ensp;Most of my friends have an interest. #<br>
          <b>4</b>&ensp;I wish we could find a solution.#<br>
          <b>5</b>&ensp;I’d love to prove the existence. #<br>
          <b>6</b>&ensp;Nowadays, there’s very little demand. #<br>
        </div>
          `,
        answer: [
          "Greg apologised to Katya for not going to her barbecue the day before. Katya admitted to Greg that she'd been quite upset about it. Greg insisted that it hadn't been his fault. Katya refused to listen to any more of hislies. Greg explained that he hadn't been able to go because he'd broken his wrist and had had to go to hospital. Katya suggested that he go over that afternoon. Greg promised that he'd definitely be there that time. Katya reminded him to take her birthday present with him.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P60-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page60/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P60-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page60/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
