import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P63-E1",
    exerciseKey: "img/FriendsPlus/Page63/Key/E1answerKey.png",
    component: T6,
    // textAlign:'center',
    // maxLength:1,
    stylesTextInput: { width: 560, background: "none" },
    hintBox: [
      {
        src: [
          "admit",
          "announce",
          "argue",
          "complain",
          "deny",
          "insist",
          "promise",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the task and the summary below. Does the writer.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div style='position:relative'>
              <div style='position:absolute;top:350px;left:10px'>
                •&emsp;include every detail in the table? <br>#<br>
                •&emsp;give any prediction for the future trend?<br>#<br>
                •&emsp;follow the instruction of the word limit?<br>#<br>
              </div>
              <img src="img/FriendsPlus/Page63/E1/1.jpg" style='height:17.5cm'/><br>
            </div>
        `,
        answer: [
          "does not include every detail in the table.",
          "does not give ant prediction for the future trend.",
          "follows the instruction of the word limit.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P63-E2",
    exerciseKey: "img/FriendsPlus/Page63/Key/E2answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 400, background: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "belief",
          "difference",
          "evidence",
          "increase",
          "interest",
          "need",
          "obsession",
          "reason",
        ],
        width: 1000,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: -50,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#2385c6'>Writing Strategy</span>. Did the writer follow the 
          strategy?`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page63/E2/1.jpg" style='height:8cm'/>
        <div>
          Did the writer follow the strategy? #<br>
          Pick out the detail in the summary showing a comparison. <br> <textarea id=1 rows=3></textarea>
          Which detail in the summary would you like to leave out? <br><textarea id=2 rows=3></textarea> <br>
          <div style='margin-top:-60px'>
            Which detail in the table would you like to add in? <br><textarea id=3 rows=3></textarea> <br>
          </div>
        
        </div>
        `,
        answer: [
          "The writer follows the strategy.",
          "As seen from the figure in 2010, fifty percent of the population in the city owned a smartphone, while a smart watch was first introduced and used by only 2 percent",
          "The detail which can be left out: the number of users became nearly 40 times of that.",
          "The idea which could be added in: People used neither tablet nor smart watch in 2005.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P63-E3",
    exerciseKey: "img/FriendsPlus/Page63/Key/E3answerkey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 71, background: "none", paddingTop: 3 },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<b  style='color:#92278f'>KEY PHRASES</b> Put the following phrases in the correct 
          groups.`,
        color: "black",
        left: 50,
      },
    ],
    selectStyle: {
      color: "black",
      width: 250,
      marginLeft: 20,
      textAlign: "center",
      fontSize: 20,
    },
    selectOptionRandom: true,
    selectOptionValues: ["Introducing the table", "Giving remarks"],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <div style='display:flex'>•	 This table is a representation of the process for … <select id='0'></select> </div>
          <div style='display:flex'>•	 There has been a sharp rise in the amount of …. <select id='1'></select> </div>
          <div style='display:flex'>•	 On the contrary, the figure of …. <select id='2'></select> </div>
          <div style='display:flex'>•	 This table uses data from the Z Database. <select id='3'></select> </div>
          <div style='display:flex'>•	 The table gives information …. <select id='4'></select> </div>
          <div style='display:flex'>•	 Another detail worth discussing is … <select id='5'></select> </div>
          <div style='display:flex'>•	 Compared to ….., ….. <select id='6'></select> </div>
        </div>
        
        `,
        answer: [
          "Introducing the table",
          "Giving remarks",
          "Giving remarks",
          "Introducing the table",
          "Introducing the table",
          "Giving remarks",
          "Giving remarks",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P63-E4",
    exerciseKey: "img/FriendsPlus/Page63/Key/E4answerKey.png",
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#2385c6'>Writing Strategy</span> again and the task below. 
          Make notes under the headings.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 520, paddingTop: 3 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#92278f",
        marginLeft: 0,
      },
    ],

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page63/E4/1.jpg" style='height:11cm'/>

        <div>
        What to include in the introduction:<br>
        What? <br>#<br> Where? <br>#<br> When? <br>#<br>
        What to include in the remarks:<br>
        In general, …
        The highest / The lowest percentage …
        <textarea id=3 rows=10></textarea>
        </div>
        `,
        answer: [
          "How students use the Internet for their study.",
          "In Youth City",
          "From 2019 to 2021",
          `In general, the percentage of students using the Internet for three activities was on steady increase from 2019 to 2021. Although in 2019 the percentage of the students attending online courses took up only 3%, it rose / soared up to 80% in 2021, the highest percentage in the table (among the three activities).In 2019, the lowest percentage was the students doing online tests and exams. However, in 2021, the lowest switched to the activity of reading online (just 54%).The activity of reading online in 2021 accounted for 54% of the students, three times of the percentage in 2020.`,
        ],
        initialValueTextArea: ["", "", "", "In general"],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P63-E6",
    exerciseKey: "img/FriendsPlus/Page63/Key/E6answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    isHello: true,
    // hideBtnFooter: true,
    question: {
      leftContent: 100,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          height: "32px",
          textAlign: "center",
          width: 35,
          fontSize: "22px",
          marginBottom: "10px",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          fontSize: "22px",
          color: "black",
        },
        limitSelect: 4,
        listWords: ["✓  ✓  ✓  ✓", "b"],
        answers: ["0-0", "1-4"],
        initialValue: [],
      },
      Layout: `


        <div style='position:relative'>
          <img src="img/FriendsPlus/Page63/E5/1.jpg" style='height:8cm'/><br>
          <div style='display:flex;flex-direction:column;height:4cm;position:absolute;top:106px;left:67px'>
            <input id=0 type='Circle'/>

          </div>
          
        </div>
        
        `,
    },
  },
  5: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P63-E5",
    exerciseKey: "img/FriendsPlus/Page63/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: { width: 950, paddingTop: 3 },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthfu"],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write the summary of the table.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <textarea id=0 rows=7></textarea>
          `,
        answer: [
          "Greg apologised to Katya for not going to her barbecue the day before. Katya admitted to Greg that she'd been quite upset about it. Greg insisted that it hadn't been his fault. Katya refused to listen to any more of hislies. Greg explained that he hadn't been able to go because he'd broken his wrist and had had to go to hospital. Katya suggested that he go over that afternoon. Greg promised that he'd definitely be there that time. Katya reminded him to take her birthday present with him.",
        ],
      },
    ],
  },
};

export default json;
