import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P14-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { marginLeft: 20, width: 1000, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Rewrite the sentences using <i>must have, may (not) have,</i>
or <i>can’t have</i>.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `


          <div style='width:27cm'>
            <b>1</b>&ensp;I’m sure that they were good friends before the argument as they were always together.<br>#<br>
            <b>2</b>&ensp;It’s possible that there was a misunderstanding.<br>#<br>
            <b>3</b>&ensp;It’s impossible that she said that about her friend.<br>#<br>
            <b>4</b>&ensp;It’s possible that he wasn’t attracted to her.<br>#<br>
            <b>5</b>&ensp;It’s impossible that they went out together.<br>#<br>
          </div>
          
          
          `,
        answer: [
          "They must have been good friends before the argument as they were always together.",
          "There may have been a misunderstanding.",
          "She can't have said that about her friend.",
          "He may not have been attracted to her.",
          "They can't have gone out together.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P14-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 210, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "get over",
          "not make up",
          "not turn up",
          "put through",
          "talk into",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Look at the photo and write sentences about it. Use <i>must be / may be / can’t be</i>.`,
        color: "black",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `


        <img src="img/FriendsPlus/Page14/E2/1.jpg" style='height:10cm'/>
          <textarea id=0 rows=10></textarea>
          
          
          `,
        answer: [
          "didn't turn up",
          "haven't made up",
          "getting over",
          "put",
          "through",
          "talking",
          "into",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P14-E3",
    audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.07 color=white colorimg='#d1b4d8'></headphone> Read the <span style='color:#009345'>Reading Strategy</span> Complete the text with the correct particles.`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <div style='width:20cm;'>
          <b>1</b>&ensp;The photo seems to show a family #.<br>
          <b>2</b>&ensp;It looks as if the son is #.<br>
          <b>3</b>&ensp;Judging by their expressions, I’d say that the parents are #.<br>
          <b>4</b>&ensp;I might be wrong, but I think the mother is asking him to #.<br>
          <b>5</b>&ensp;I can’t be certain, but I don’t think she’s #.<br>
          <b>6</b>&ensp;The father looks #.<br>
       </div>
          
          
          `,
        answer: [
          "discussion",
          "in trouble",
          "worried about him",
          "explain something",
          "shouting at him",
          "quite cross",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P14-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Look at photos A and B in exercise 5 below. Complete
these sentences with your own ideas.
`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 300, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page14/E4/1.jpg" style='height:12cm'/>
        <br>
        <b>Photo A</b><br>
        Judging by the mother’s expression, I’d say  #.<br>
        It looks as if the daughter is  #.<br>
        I might be wrong, but I think the father is #.<br>
        <b>Photo B</b><br>
        The photo seems to show  #.<br>
        I can’t be certain, but I don’t think the son  #.<br>
        The son looks  #.<br>
       
      
          `,
        answer: [
          "bring up, call off, let down, turn down",
          "get over, look after, run into, take after",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P14-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 600, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Look at the task and the photos below. Then read the
questions (1–5) and write answers.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
         <img src="img/FriendsPlus/Page14/E5/1.jpg" style='height:16cm'/>
         <div style='width:25cm'>

            <b>1</b>&ensp;What is the common theme of the photos? <textarea id=0 rows=2></textarea>
            <b>2</b>&ensp;What can you see in both photos? <textarea id=1 rows=2></textarea>
            <b>3</b>&ensp;What is the main difference between the photos? <textarea id=2 rows=2></textarea>
            <b>4</b>&ensp;Which are more serious: family arguments or arguments between friends? <textarea id=3 rows=2></textarea>
            <b>5</b>&ensp;What are the reasons for your answer to question 4? <textarea id=4 rows=2></textarea>
         
          
        </div>
          `,
        answer: [
          "The common theme of the photos is family arguments.",
          "In both photos I can see a man pointing his finger and shouting.",
          "In photo A the family is sitting down, whereas in photo B, the adults are standing up.",
          "",
          "",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P14-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 100,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Now do the task using your notes from exercise 5.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
          
          
          
          `,
        answer: ["a", "a", "few", "Both", "many", "The", "of"],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P14-E7",
    audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
