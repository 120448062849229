import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E1",
    exerciseKey: "img/FriendsPlus/Page1/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      fontWeight: "bold",
      width: 147,
      background: "none",
      paddingTop: 3,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the text with the correct form of the verbs below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page1/E1/1.jpg" style='height:20cm'/>
          <div style='position:absolute;top:33px;left:309px'>#</div>
          <div style='position:absolute;top:65px;left:493px'>#</div>
          <div style='position:absolute;top:96px;left:455px'>#</div>
          <div style='position:absolute;top:160px;left:445px'>#</div>
          <div style='position:absolute;top:224px;left:432px'>#</div>
          <div style='position:absolute;top:287px;left:78px'>#</div>
        </div>`,
        answer: [
          "lie",
          "exaggerate",
          "influence",
          "photoshop",
          "pass",
          "disguise",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E2",
    exerciseKey: "img/FriendsPlus/Page1/Key/E2answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 180, paddingTop: 3 },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the verbs.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            <b>1</b>&ensp;Sam was told to leave the exam because he was using his mobile phone and# .<br>
            <b>2</b>&ensp;The children were# when they said that the dog ate the cake.<br>
            <b>3</b>&ensp;You have to# to tell the truth in a court of law.<br>
            <b>4</b>&ensp;The business tried to# positive news about their products.<br>
            <b>5</b>&ensp;Jack# his parents about what he had really been doing on Friday evening.<br>
        </div>
        
        `,
        answer: ["cheating", "lying", "swear", "disseminate", "misled"],
      },
    ],
  },

  3: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E3",
    exerciseKey: "img/FriendsPlus/Page1/Key/E3answerKey.png",
    component: Circle_Write,
    audio: "Audios/2-07.mp3",
    // hideBtnFooter:true,
    // textAlign:"center",
    textareaStyle: {
      width: 1000,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 1000, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.07 colorimg='#92278f'></headphone> Listen to the dialogues and choose the correct 
          answers (a–c). `,
        color: "black",
        // width:'cm',
        left: -50,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-0", "1-8", "2-4", "3-0", "4-8", "5-4"],
        initialValue: [],
      },
      Layout: `
      <b>Dialogue 1</b> <br>
      <b>1</b>&ensp;What is Jessica doing at the beginning of the dialogue?
      <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=0 type='Circle'/></div>
          <div>Making an excuse <br> Telling the truth <br> Cheating</div>
      </div> 
      <b>2</b>&ensp;What does Tom want Jessica to do?
      <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=1 type='Circle'/></div>
          <div>Deceive somebody <br> Lie about something to somebody <br> Own up to something</div>
      </div> 
      <b>3</b>&ensp;What is Jessica doing at the beginning of the dialogue?
      <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=2 type='Circle'/></div>
          <div>Tell a lie <br> Tell the truth <br> Fool Tom</div>
      </div> 
      <b>Dialogue 2</b> <br>
      <b>4</b>&ensp;What is Grace doing?
      <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=3 type='Circle'/></div>
          <div>Photoshopping a picture <br> Disguising her friend <br> Telling the truth</div>
      </div> 
      <b>5</b>&ensp;What does Grace say she hasn’t done?
      <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=4 type='Circle'/></div>
          <div>Improved the story <br> Made the story more amusing <br> Fabricated a story</div>
      </div> 
      <b>6</b>&ensp;What does Leo think a newspaper should do?
      <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=5 type='Circle'/></div>
          <div>Portray things as more interesting than they are <br> Tell the truth <br>  Reveal secrets</div>
      </div> 
        
      `,
    },
  },

  4: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E4",
    exerciseKey: "img/FriendsPlus/Page1/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the correct verbs.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 100, paddingTop: 3 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#92278f",
        marginLeft: 0,
      },
    ],

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
          <b>1</b>&ensp;A lot of people won’t tell you anything face to face, but then r# all their secrets on the internet.<br>
          <b>2</b>&ensp;Nicola l# to me about the date of her party!<br>
          <b>3</b>&ensp;He always m# excuses for his mistakes and never accepts responsibility for anything!<br>
          <b>4</b>&ensp;Alex keeps telling us that he is the best singer in class, and we all know he is f# himself.<br>
          <b>5</b>&ensp;The machine d# his voice so you didn’t know who was speaking.<br>
        </div>
        `,
        answer: ["eveal", "ied", "akes up", "lattering", "isguised"],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E5",
    exerciseKey: "img/FriendsPlus/Page1/Key/E5answerKey.png",

    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct words to complete the sentences.`,
        color: "black",
        left: -30,
        width: "25cm",
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#92278f",
        marginLeft: 0,
      },
    ],

    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "dishonest / daunting",
          "biased / informative",
          "direct / neutral",
          "genuine / encouraging",
          "provocative / honest",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
      <div style='width:26cm'>
        <b>1</b>&ensp;It’s so <input id=0 type='Circle'/> to tell people to help when you don’t do anything yourself!<br>
        <b>2</b>&ensp;The lecture of Professor Jenkins was very <input id=1 type='Circle'/> and it seemed as if we had read a few books. <br>
        <b>3</b>&ensp;I like Sara’s <input id=2 type='Circle'/> manner, even if she sometimes criticises what I have done.<br>
        <b>4</b>&ensp;Carlos has had a good decision of his career thanks to the teacher’s <input id=3 type='Circle'/> remark.<br>
        <b>5</b>&ensp;I wanted to give Frank a(n) <input id=4 type='Circle'/> answer, but I couldn’t tell him his suit looked awful, could I?<br>
      </div>
      `,
    },
  },
  6: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E6",
    exerciseKey: "img/FriendsPlus/Page1/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: { width: 160, background: "none", paddingTop: 3 },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthfu"],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Match the adjectives below with the descriptions.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:25cm'>
          <b>1</b>&ensp;Someone who is honest and says what they think. #<br>
          <b>2</b>&ensp;Someone who does not support or help either side in a disagreement. #<br>
          <b>3</b>&ensp;Something which is not genuine. #<br>
          <b>4</b>&ensp;Someone who can be relied upon to do the right thing and to be honest. #<br>
          <b>5</b>&ensp;Someone who says only what is true. #<br>
        </div>`,
        answer: ["straight", "neutral", "fake", "trustworthy", "truthful"],
      },
    ],
  },
};

export default json;
