import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P41-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 200,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "adventure holiday",
          "backpacking",
          "beach holiday",
          "beach house",
          "caravan",
          "cruise",
          "guest house",
          "holiday home",
          "self-catering apartment",
          "surfing",
          "tent",
          "time-share apartment",
          "volunteering",
          "winter sports holiday",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the past perfect simple
form of the verbs in brackets.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='width:27cm'>
            <b>1</b>&ensp;He made a dentist’s appointment because he #(break) a tooth.<br>
            <b>2</b>&ensp;We #(not leave) enough time, so we missed our train.<br>
            <b>3</b>&ensp;After she #(choose) a new skirt, she looked for a top to match it.<br>
            <b>4</b>&ensp;I couldn’t make my new tablet work because I #(lose) the instructions.<br>
            <b>5</b>&ensp;When I checked the receipt later, I realised I #(pay) too much for my shopping.<br>
            <b>6</b>&ensp;Sam forgot to take a hat on the camping trip, but luckily his friend #(bring) a spare one.<br>
        </div>

          `,
        answer: [
          "had broken",
          "hadn't left|had not left",
          "had chosen",
          "had lost",
          "had paid",
          "had brought ",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P41-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 250, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cook", "learn", "look", "travel", "wait", "work"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the past perfect continuous
form of the verbs below.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:27cm'>
            <b>1</b>&ensp;We # for less than an hour when the coach broke down.<br>
            <b>2</b>&ensp;I # for my friend for an hour when he phoned to say he couldn’t come.<br>
            <b>3</b>&ensp;When I met Dean, he # as a teacher in Argentina since 2012.<br>
            <b>4</b>&ensp;I could tell my mum # because the kitchen smelled of curry.<br>
            <b>5</b>&ensp;They # for their dog for two days when it turned up outside their house.<br>
            <b>6</b>&ensp;My cousin # to drive for over a year before she passed her test<br>
           
        </div>

          `,
        answer: [
          "had been travelling",
          "had been waiting",
          "had been working",
          "had been cooking",
          "had been looking",
          "had been learning ",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P41-E3",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },

    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 700,
      // padding: 0,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Write the words in the correct order to make sentences.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <div style='width:27cm'>
            <b>1</b>&ensp;you / went / eaten / Had / you / breakfast / out / before / ?<br>#<br>
            <b>2</b>&ensp;long / They / bus / waiting / the / hadn’t / arrived / been / when<br>#<br>
            <b>3</b>&ensp;been / swimming / because / just / was / He / cold / he’d<br>#<br>
            <b>4</b>&ensp;before / London / he / living / to / Where / he / had / been / moved / ?<br>#<br>
            <b>5</b>&ensp;so / expected / presents / get / hadn’t / She / to / many<br>#<br>
           
        </div>

          `,
        answer: [
          "Had you eaten breakfast before you went out?",
          "They hadn't been waiting long when the bus arrived.|They had not been waiting long when the bus arrived.",
          "He was cold because he'd just been swimming.",
          "Where had he been living before he moved to London?",
          "She hadn't expected to get so many presents.|She had not expected to get so many presents.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P41-E4",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the words in brackets. Use
the past perfect simple or past perfect continuous.


`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 900, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
 
              <div style='width:26cm'>
                <b>1</b>&ensp;I (never / snorkel) before Mia took me last year.<br>#<br>
                <b>2</b>&ensp;Clara was annoyed because the book she’d ordered (not / arrive).<br>#<br>
                <b>3</b>&ensp;How long (you / play) the guitar before we met?<br>#<br>
                <b>4</b>&ensp;By the time the shops opened, (they / queue) for nearly an hour.<br>#<br>
                <b>5</b>&ensp;I looked for my bag, but (I forgot) where I’d put it.<br>#<br>
                <b>6</b>&ensp;Greg was tired last night because (he / play) basketball.<br>#<br>
                
              </div>
             
            
            
          `,
        // InputRong: true,
        answer: [
          "I had never snorkelled before Mia took me last year.",
          "Clara was annoyed because the book she'd ordered hadn't arrived.|Clara was annoyed because the book she had ordered hadn't arrived.|Clara was annoyed because the book she'd ordered had not arrived.|Clara was annoyed because the book she had ordered had not arrived.",
          "How long had you been playing the guitar before we met?",
          "By the time the shops opened, they had been queuing for nearly an hour.",
          "I looked for my bag, but I had forgotten where I'd put it.|I looked for my bag, but I'd forgotten where I'd put it.|I looked for my bag, but I'd forgotten where I had put it.|I looked for my bag, but I had forgotten where I had put it.",
          "Greg was tired last night because he had been playing basketball.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P41-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["date", "enjoy", "find", "not imagine", "live", "star", "think"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the text with the past perfect simple or past
perfect continuous form of the verbs below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
                Jennifer Lawrence was born in the United
                States in 1990. Once, before she became
                famous, she <sup>1</sup># about
                being a doctor and <sup>2</sup>#
                that she’d ever become an actress. That
                changed when she was fourteen. She
                <sup>3</sup># a spring break with
                her parents when a stranger spotted her
                and asked to do a screen test. Then things
                progressed fast. Not long after she <sup>4</sup># work
                in commercials for MTV, she appeared in a TV series. Her
                family <sup>5</sup># in Kentucky for many years, but
                they moved to Los Angeles when her career took off. Two
                years before filming The Hunger Games in 2012, she <sup>6</sup># in Winter’s Bone and been nominated
                for an Oscar. She <sup>7</sup># Nicholas Hoult, her
                co-star from X-Men, for two years when they split up in 2013.
              
              
           
        </div>

        
            
            
          `,
        answer: [
          "had thought",
          "hadn't imagined|had not imagined",
          "had been enjoying",
          "had found",
          "had been living",
          "had starred",
          "had been dating",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P41-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      // padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the second sentence with a past perfect tense
so that it means the same as the first.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

       <div style='display:flex'> 
            <div><b>1</b></div> 
              <div style='margin-left:15px;width:25cm'>
                It was months since he’d played tennis.<br>
                He # for months.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>2</b> </div>
              <div style='margin-left:15px;width:25cm'>
                She ate her dinner and then went to bed.<br>
                After she #, she went to bed.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>3</b></div> 
              <div style='margin-left:15px;width:25cm'>
                Zoe saved for ages and then bought a new watch.<br>
                Zoe # before she bought a new watch.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>4</b></div> 
              <div style='margin-left:15px;width:25cm'>
                Eric broke his arm so he couldn’t go to school.<br>
                Eric couldn’t go to school #.
              </div>
          </div>
         
            
            
          `,
        answer: [
          "hadn't played tennis|had not played tennis",
          "had eaten her dinner",
          "had been saving for ages",
          "because he had broken his arm.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P41-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P41-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
