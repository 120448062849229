import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P58-E1",
    audio: "Audios/2.07.mp3",
    exerciseKey: "img/FriendsPlus/Page58/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 177,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: ["panel", "departments", "candidate", "context", "skills"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.07 color=white colorimg='#d1b4d8'></headphone> Read the <span style='color:#C40B44'>Reading Strategy</span>. Then listen to the
sentences and choose the tone of voice (a or b) each
speaker uses.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-0", "5-0", "6-0", "7-4"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page58/E1/1.jpg" style='height:6.5cm'/>
      <div style='display:flex'>
        <div style='margin-right:150px'>
            <b>1</b>&ensp;Is that letter for me?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>nervous <br> surprised</div>
                 </div>
            <b>2</b>&ensp;No, that’s not right. Let me show you.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>angry <br> patient</div>
                 </div>
            <b>3</b>&ensp;This is my seat.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>certain <br> uncertain</div>
                 </div>
            <b>4</b>&ensp;That makes me feel much better.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>sarcastic <br> enthusiastic</div>
                 </div>
        </div>
        <div>
            <b>5</b>&ensp;That didn’t hurt at all.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>surprised <br> sarcastic</div>
                 </div>
            <b>6</b>&ensp;The film’s quite good.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=5 type=Circle /></div>
                    <div>unsure <br> enthusiastic</div>
                 </div>
            <b>7</b>&ensp;He’s never on time.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=6 type=Circle /></div>
                    <div>irritated <br> patient</div>
                 </div>
            <b>8</b>&ensp;This party’s brilliant.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=7 type=Circle /></div>
                    <div>sarcastic <br> enthusiastic</div>
                 </div>
        </div>
      </div>

            
            
           
              
        `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P58-E2",
    audio: "Audios/2.07.mp3",
    exerciseKey: "img/FriendsPlus/Page58/Key/E2answerKey.png",
    component: T6,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 250, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cook", "learn", "look", "travel", "wait", "work"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.07 color=white colorimg='#d1b4d8'></headphone> Listen again and repeat the sentences, copying
the tone of voice.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
              
             
            
            
          `,
        // InputRong: true,
        answer: [
          "I had never snorkelled before Mia took me last year.",
          "Clara was annoyed because the book she'd ordered hadn't arrived.",
          "How long had you been playing the guitar before we met?",
          "By the time the shops opened, they had been queuing for nearly an hour.",
          "I looked for my bag, but I'd forgotten where I'd put it.",
          "Greg was tired last night because he had been playing basketball.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P58-E3",
    // audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page58/Key/E3answerKey.png",
    component: T6,
    recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },

    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 700,
      // padding: 0,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Now try saying the sentences in exercise 1 in the other
tone of voice.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
             
             
            
            
          `,
        // InputRong: true,
        answer: [
          "I had never snorkelled before Mia took me last year.",
          "Clara was annoyed because the book she'd ordered hadn't arrived.",
          "How long had you been playing the guitar before we met?",
          "By the time the shops opened, they had been queuing for nearly an hour.",
          "I looked for my bag, but I'd forgotten where I'd put it.",
          "Greg was tired last night because he had been playing basketball.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P58-E4",
    audio: "Audios/2.08.mp3",
    exerciseKey: "img/FriendsPlus/Page58/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.08 color=white colorimg='#d1b4d8'></headphone> Listen and repeat the different intonation
patterns for statements.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 900, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page58/E4/1.jpg" style='height:5.5cm'/>
              
             
            
            
          `,
        // InputRong: true,
        answer: [
          "I had never snorkelled before Mia took me last year.",
          "Clara was annoyed because the book she'd ordered hadn't arrived.",
          "How long had you been playing the guitar before we met?",
          "By the time the shops opened, they had been queuing for nearly an hour.",
          "I looked for my bag, but I'd forgotten where I'd put it.",
          "Greg was tired last night because he had been playing basketball.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P58-E5",
    audio: "Audios/2.09.mp3",
    exerciseKey: "img/FriendsPlus/Page58/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    maxLength: 1,
    stylesTextInput: {
      // borderBottom: "none"
      border: "2px solid",
      borderRadius: 5,
      background: "none",
      width: 38,
      height: 35,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["date", "enjoy", "find", "not imagine", "live", "star", "think"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=2.09 color=white colorimg='#d1b4d8'></headphone> Listen to these statements being said in two
different ways. Number them 1 and 2 in the order you
hear them. Practise saying them.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <div style='line-height:40px;display:flex;width:26cm'>
          <div style='margin-right:100px'>
              <b>1</b>&ensp;You like this kind of story.<br>
                  <div style='margin-left:25px'><b>a</b>&ensp;certain # <br>  <b>b</b>&ensp;uncertain #<br></div>
              <b>2</b>&ensp;This is just what I wanted.<br>
                  <div style='margin-left:25px'><b>a</b>&ensp;certain # <br>  <b>b</b>&ensp;sarcastic #<br></div>
              <b>3</b>&ensp;This isn’t quite what I was expecting.<br>
                  <div style='margin-left:25px'><b>a</b>&ensp;qualifying # <br>  <b>b</b>&ensp;certain #<br></div>
          </div>
          <div>
              <b>4</b>&ensp;He thinks it’s a reasonable price.<br>
                  <div style='margin-left:25px'><b>a</b>&ensp;qualifying # <br>  <b>b</b>&ensp;uncertain #<br></div>
              <b>5</b>&ensp;This steak is the best I’ve ever eaten.<br>
                  <div style='margin-left:25px'><b>a</b>&ensp;sarcastic # <br>  <b>b</b>&ensp;certain #<br></div>
              <b>6</b>&ensp;That’s really nice of him.<br>
                  <div style='margin-left:25px'><b>a</b>&ensp;uncertain # <br>  <b>b</b>&ensp;sarcastic #<br></div>
          </div>
        </div>
        
            
            
          `,
        // InputRong: true,
        answer: ["1", "2", "2", "1", "1", "2", "2", "1", "2", "1", "1", "2"],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P58-E6",
    audio: "Audios/2.10.mp3",
    exerciseKey: "img/FriendsPlus/Page58/Key/E6answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      // padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name=2.10 color=white colorimg='#d1b4d8'></headphone>  Listen to the conversation. Choose the correct
answers (a–d).`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 200, textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        // TickRong: true,
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-4", "1-12", "2-0", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `


           <b>1</b>&ensp;How does Max feel when he starts telling his story?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>Reluctant to tell it. <br> Impatient with interruptions. <br> Unsure how the story goes. <br> Worried that it is too scary.</div>
                 </div>
            <b>2</b>&ensp;What is the story about?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>A ghost. <br> A student who is lost. <br> What to do in bad weather. <br> The inhabitants of a village in Ireland.</div>
                 </div>
            <b>3</b>&ensp;What do the people listening think of the story?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>It’s gripping. <br> It’s hilarious throughout. <br> It’s annoying. <br> It’s too similar to the first one.</div>
                 </div>
            <b>4</b>&ensp;What does Caro think of Max’s comment about her?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>She’s really annoyed. <br> She admits that Max’s comment could be right. <br> She criticises Max in self-defence. <br> She does not trust his opinion of her.</div>
                 </div>
            <b>5</b>&ensp;What does Max agree with Jill about?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>That Caro is easily fooled. <br> That Caro is more good-natured than he is. <br> That Ian’s story is better than his. <br> That Ian should buy the coffees.</div>
                 </div>
      
     

  `,
    },
  },
  7: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P58-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page58/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P58-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page58/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
