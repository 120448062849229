import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P70-E1",
    audio: "Audios/2.17.mp3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 25,
      background: "none",
      width: 200,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "got ahead",
          "helps out",
          "set up",
          "squeeze in",
          "stepped down",
          "thinking up",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.17 color=white colorimg='#d1b4d8'></headphone> Read the task above and listen to two students
doing the task. Answer the questions. Which three measures
do the students agree will be particularly effective?
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <b>Answer:</b><textarea id=0 rows=2></textarea>
        </div>
        <img src="img/FriendsPlus/Page70/E1/1.jpg" style='height:19cm'/>
          `,
        answer: [
          "using more renewable energy, using public transport more often, using fewer pesticides and chemical fertiliser",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P70-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    maxLength: 2,
    stylesTextInput: {
      // borderBottom: "none"
      border: "2px solid",
      borderRadius: 5,
      background: "none",
      width: 40,
      height: 40,
      padding: 0,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the sentence halves in each group
below to make phrases.
`,
        color: "black",
        left: 50,
        width: "27cm",
        prefix: [{ text: "KEY PHRASES", icon: "", color: "gray" }],
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

       <div style='display:flex;line-height:48px; border-left:5px solid gray;padding-left:10px'>
              <div style='margin-right:100px'>
                 <b>Starting a discussion</b><br>
                <b>1</b>&ensp;Shall we <br>
                <b>2</b>&ensp;Let’s begin <br>
                <b>3</b>&ensp;We could start <br>
                <b>Expressing an opinion</b><br>
                <b>4</b>&ensp;I’m of the <br>
                <b>5</b>&ensp;For me, the <br>
                <b>6</b>&ensp;Personally, I would <br>
                <b>7</b>&ensp;I feel quite <br>
                <b>Adding an opinion</b><br>
                <b>8</b>&ensp;Not <br>
                <b>9</b>&ensp;Yes, and another <br>
                <b>10</b>&ensp;Good point. And I <br>
                <b>Agreeing and disagreeing</b><br>
                 <b>11</b>&ensp;Absolutely. I couldn’t <br>
                <b>12</b>&ensp;I’m with <br>
                <b>13</b>&ensp;Yes, I think <br>
                <b>14</b>&ensp;That’s true to <br>
                <b>15</b>&ensp;I’m not sure I <br>
                <b>16</b>&ensp;I take a rather <br>
                <b>17</b>&ensp;That’s not quite <br>
              </div>
              <div>
                <br>
                <b>a</b>&ensp;with … , shall we? #<br>
                <b>b</b>&ensp;by talking about … #<br>
                <b>c</b>&ensp;start with … ? #<br>
                <br>
                <b>d</b>&ensp;chief consideration is … #<br>
                <b>e</b>&ensp;strongly that … #<br>
                <b>f</b>&ensp;say that … #<br>
                <b>g</b>&ensp;opinion that … #<br>
                <br>
                <b>h</b>&ensp;only that, … #<br>
                <b>i</b>&ensp;also believe that … #<br>
                <b>j</b>&ensp;thing. … #<br>
                <br>
                <b>k</b>&ensp;some extent. However, … #<br>
                <b>l</b>&ensp;agree more. #<br>
                <b>m</b>&ensp;you on that. #<br>
                <b>n</b>&ensp;you’re right. #<br>
                <b>o</b>&ensp;different view on that. #<br>
                <b>p</b>&ensp;how I see it. #<br>
                <b>q</b>&ensp;agree.  #<br>
              </div>
       </div>
        
            
            
          `,
        // InputRong: true,
        answer: [
          "2",
          "3",
          "1",
          "5",
          "7",
          "6",
          "4",
          "8",
          "10",
          "9",
          "14",
          "11",
          "12",
          "13",
          "16",
          "17",
          "15",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P70-E3",
    audio: "Audios/2.17.mp3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 25,
      width: 300,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.17 color=white colorimg='#d1b4d8'></headphone> Listen again and tick the phrases in exercise 2
that you hear.
`,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: /[/|]/,
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { fontSize: 24, marginLeft: 30, width: 900 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        TickRong: true,
        initialWordStyle: {
          marginLeft: 10,
          padding: "1px 10px",
          border: "2px solid",
          borderRadius: 5,
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          borderRadius: 5,
          // borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: [
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
        ],
        answers: [
          "0-0",
          "3-0",
          "5-0",
          "6-0",
          "7-0",
          "10-0",
          "11-0",
          "12-0",
          "16-0",
        ],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex;line-height:48px; border-left:5px solid gray;padding-left:10px'>
              <div style='margin-right:100px'>
                 <b>Starting a discussion</b><br>
                <b>1</b>&ensp;Shall we <input id=0 type=Circle /><br>
                <b>2</b>&ensp;Let’s begin <input id=1 type=Circle /><br>
                <b>3</b>&ensp;We could start <input id=2 type=Circle /><br>
                <b>Expressing an opinion</b><br>
                <b>4</b>&ensp;I’m of the <input id=3 type=Circle /><br>
                <b>5</b>&ensp;For me, the <input id=4 type=Circle /><br>
                <b>6</b>&ensp;Personally, I would <input id=5 type=Circle /><br>
                <b>7</b>&ensp;I feel quite <input id=6 type=Circle /><br>
                <b>Adding an opinion</b><br>
                <b>8</b>&ensp;Not <input id=7 type=Circle /><br>
                <b>9</b>&ensp;Yes, and another <input id=8 type=Circle /><br>
                <b>10</b>&ensp;Good point. And I <input id=9 type=Circle /><br>
                <b>Agreeing and disagreeing</b><br>
                 <b>11</b>&ensp;Absolutely. I couldn’t <input id=10 type=Circle /><br>
                <b>12</b>&ensp;I’m with <input id=11 type=Circle /><br>
                <b>13</b>&ensp;Yes, I think <input id=12 type=Circle /><br>
                <b>14</b>&ensp;That’s true to <input id=13 type=Circle /><br>
                <b>15</b>&ensp;I’m not sure I <input id=14 type=Circle /><br>
                <b>16</b>&ensp;I take a rather <input id=15 type=Circle /><br>
                <b>17</b>&ensp;That’s not quite <input id=16 type=Circle /><br>
              </div>
              <div>
                <br>
                <b>a</b>&ensp;with … , shall we? <br>
                <b>b</b>&ensp;by talking about … <br>
                <b>c</b>&ensp;start with … ? <br>
                <br>
                <b>d</b>&ensp;chief consideration is … <br>
                <b>e</b>&ensp;strongly that … <br>
                <b>f</b>&ensp;say that … <br>
                <b>g</b>&ensp;opinion that … <br>
                <br>
                <b>h</b>&ensp;only that, … <br>
                <b>i</b>&ensp;also believe that … <br>
                <b>j</b>&ensp;thing. … <br>
                <br>
                <b>k</b>&ensp;some extent. However, … <br>
                <b>l</b>&ensp;agree more. <br>
                <b>m</b>&ensp;you on that. <br>
                <b>n</b>&ensp;you’re right. <br>
                <b>o</b>&ensp;different view on that. <br>
                <b>p</b>&ensp;how I see it. <br>
                <b>q</b>&ensp;agree.  <br>
              </div>
       </div>

           

  `,
    },
  },
  4: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P70-E4",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginTop: 20,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#009346'>Speaking Strategy</span>. Then complete
the phrases with the words below.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "KEY PHRASES", icon: "", color: "gray" }],
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      // fontSize: 23,
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 200,
      // padding: 0,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "26cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "agree",
          "Can",
          "come",
          "interrupt",
          "moment",
          "need",
          "only",
          "reach",
          "settled",
          "think",
          "What",
          "view",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <img src="img/FriendsPlus/Page70/E4/1.jpg" style='height:6cm'/>
       <div style='line-height:48px; border-left:5px solid gray;padding-left:10px'>
              <b>Involving your partner</b><br>
              Would you <sup>1</sup># with that? <br>
              … , don’t you <sup>2</sup>#? <br>
              <sup>3</sup># we agree that … ? <br>
              What’s your <sup>4</sup># on that? <br>
              <sup>5</sup># do you think about … ? <br>
              <b>Interrupting</b><br>
              Sorry to <sup>6</sup>#, but … <br>
              Hang on a <sup>7</sup>#. I think … <br>
              Yes, and not <sup>8</sup># that, … <br>
              <b>Reaching an agreement</b><br>
              So, shall we <sup>9</sup># a decision? <br>
              We need to <sup>10</sup># to a decision. <br>
              We <sup>11</sup># to decide what / which … <br>
              That’s <sup>12</sup>#, then. <br>
       </div>
        
            
            
          `,
        // InputRong: true,
        answer: [
          "agree",
          "think",
          "Can",
          "view",
          "What",
          "interrupt",
          "moment",
          "only",
          "reach",
          "come",
          "need",
          "settled",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P70-E5",
    audio: "Audios/2.18.mp3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E5answerKey.png",
    component: Circle_Write,
    // textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,

      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=2.18 color=white colorimg='#d1b4d8'></headphone> Complete the second sentence so that it means the same
as the first.`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: /[|/]/,
    // isHello: true,
    // KeyMau: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { fontSize: 24, marginLeft: 30, width: 900 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        TickRong: true,
        initialWordStyle: {
          marginLeft: 10,
          padding: "1px 10px",
          border: "2px solid",
          borderRadius: 5,
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          borderRadius: 5,
          // borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓"],
        answers: ["2-0", "3-0", "4-0", "6-0", "9-0", "11-0"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page70/E5/1.jpg" style='height:10cm'/>
       <div style='line-height:48px; border-left:5px solid gray;padding-left:10px'>
              <b>Involving your partner</b><br>
              Would you <sup>1</sup>_________ with that? <input id=0 type=Circle /><br>
              … , don’t you <sup>2</sup>_________? <input id=1 type=Circle /><br>
              <sup>3</sup>_________ we agree that … ? <input id=2 type=Circle /><br>
              What’s your <sup>4</sup>_________ on that? <input id=3 type=Circle /><br>
              <sup>5</sup>_________ do you think about … ? <input id=4 type=Circle /><br>
              <b>Interrupting</b><br>
              Sorry to <sup>6</sup>_________, but … <input id=5 type=Circle /><br>
              Hang on a <sup>7</sup>_________. I think … <input id=6 type=Circle /><br>
              Yes, and not <sup>8</sup>_________ that, … <input id=7 type=Circle /><br>
              <b>Reaching an agreement</b><br>
              So, shall we <sup>9</sup>_________ a decision? <input id=8 type=Circle /><br>
              We need to <sup>10</sup>_________ to a decision. <input id=9 type=Circle /><br>
              We <sup>11</sup>_________ to decide what / which … <input id=10 type=Circle /><br>
              That’s <sup>12</sup>_________, then. <input id=11 type=Circle /><br>
       </div>
      

           

  `,
    },
  },
  6: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P70-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 176,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 800,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthful"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Look at the task in exercise 1 again and the options above.
Write notes about how effective each measure is.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page70/E1/1.jpg" style='height:19cm'/>
        <textarea id=0 rows=7></textarea>
        `,
        answer: ["it", "One / The", "what", "One", "all", "one"],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P70-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Use the notes in exercise 6 to have a discussion with your
friends.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P70-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
