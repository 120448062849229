import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P13-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 250, paddingTop: 7 },
    hintBox: [
      {
        src: ["bonds", "touch", "time commitment", "isolation"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the words below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `




        <hintbox id=0></hintbox>
          <div style='width:26cm'>
            <b>1</b>&ensp;A simple # or hug can convey warmth and affection.<br>
            <b>2</b>&ensp;Working from home can sometimes lead to a sense of #.<br>
            <b>3</b>&ensp;Maintaining strong connections with others requires a # and effort.<br>
            <b>4</b>&ensp;Siblings often develop lifelong #, sharing experiences and growing up together.<br>
          </div>
          
          
          `,
        answer: ["touch", "isolation", "time commitment", "bonds"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P13-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 210, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "get over",
          "not make up",
          "not turn up",
          "put through",
          "talk into",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#42429C'>Reading Strategy</span>. Then read the text and try
to guess the meaning of the underlined words from the
context. Check your answers in a dictionary. `,
        width: "26cm",
        color: "black",
        left: 50,
      },
    ],
    character: ";",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page13/E2/1.jpg" style='height:6.5cm'/>
        <div style='width:25cm'>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>A</span>&ensp;Although couples will rarely believe it, scientific
            <u>factors</u> have a lot to do with falling in love.
            Research has shown that scent plays an important
            role in choosing a mate. Other experiments have
            demonstrated that love is caused by combinations
            of chemicals in the brain. Furthermore, New York
            psychologist Arthur Aron applied the principles of
            science to demonstrate that the process of falling in
            love could be speeded up … to just 45 minutes!<br>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>B</span>&ensp;Some scientists claim that we take between
            ninety seconds and four minutes to decide if we
            <u>fancy</u> someone. However, the <u>leap</u> from being
            attracted to someone to falling in love with them
            is a big one, and Aron wanted to find out if the
            closeness associated with feelings of being in love
            could be created artificially.<br>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>C</span>&ensp;Aron produced a paper that included 36
            questions designed to encourage <u>intimacy</u> if couples
            answered them together. The questions came in
            three sets with each set covering increasingly
            intimate ground. They covered many personal
            opinions and experiences; for example, ‘Would
            you like to be famous?’, ‘What does
            friendship mean to you?’ and
            ‘When did you last cry in
            front of another person?’
            After answering the
            questions together, the
            couples were directed to
            stare into each other’s eyes
            for four minutes without
            saying anything.<br>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>D</span>&ensp;Aron tested his questions on
            pairs of strangers and then asked
            them to stare at each other. All
            of the participants reported feeling
            close to their partners and many of
            them <u>swapped</u> contact details after the
            experiment. So if you’re keen on someone
            and would like to get closer to them, it would
            seem that Aron’s questions could help you to
            achieve your objective.<br>

        </div>
          <div style='display:flex'>
            <b>Answer:</b><textarea id=0 rows=4></textarea>
          </div>  
          
          `,
        answer: [
          "factor - noun: a fact or situation that influences the result of something; fancy - verb: to be attracted to someone; leap - noun: a sudden movement from one place to another; intimacy - noun: a close relationship with someone; swap - verb: exchange",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P13-E3",
    // audio: "Audios/1.04.mp3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 40, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Answer the questions about the article.`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
            <b>Which paragraph mentions …</b><br>
            <b>1</b>&ensp;relationships being formed? #<br>
            <b>2</b>&ensp;conversations about views, dreams and beliefs? #<br>
            <b>3</b>&ensp;the science of love? #<br>
            <b>4</b>&ensp;the aim of the experiment?  #<br>
        </div>

        <div style='margin-top:10px;width:25cm'>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>A</span>&ensp;Although couples will rarely believe it, scientific
            factors have a lot to do with falling in love.
            Research has shown that scent plays an important
            role in choosing a mate. Other experiments have
            demonstrated that love is caused by combinations
            of chemicals in the brain. Furthermore, New York
            psychologist Arthur Aron applied the principles of
            science to demonstrate that the process of falling in
            love could be speeded up … to just 45 minutes!<br>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>B</span>&ensp;Some scientists claim that we take between
            ninety seconds and four minutes to decide if we
            fancy someone. However, the leap from being
            attracted to someone to falling in love with them
            is a big one, and Aron wanted to find out if the
            closeness associated with feelings of being in love
            could be created artificially.<br>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>C</span>&ensp;Aron produced a paper that included 36
            questions designed to encourage intimacy if couples
            answered them together. The questions came in
            three sets with each set covering increasingly
            intimate ground. They covered many personal
            opinions and experiences; for example, ‘Would
            you like to be famous?’, ‘What does
            friendship mean to you?’ and
            ‘When did you last cry in
            front of another person?’
            After answering the
            questions together, the
            couples were directed to
            stare into each other’s eyes
            for four minutes without
            saying anything.<br>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>D</span>&ensp;Aron tested his questions on
            pairs of strangers and then asked
            them to stare at each other. All
            of the participants reported feeling
            close to their partners and many of
            them swapped contact details after the
            experiment. So if you’re keen on someone
            and would like to get closer to them, it would
            seem that Aron’s questions could help you to
            achieve your objective.<br>

        </div>
        
          
          `,
        answer: ["D", "C", "A", "B"],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P13-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Choose the correct answers (a–d).
`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],

    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-12", "1-8", "2-12", "3-0"],
        initialValue: [],
      },
      Layout: `


            <b>1</b>&ensp;Thanks to Arthur Aron, we have learned that
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>couples are mistaken about why they fell in love. <br> smell attracts people to each other. <br> love is the result of a chemical reaction. <br> people can fall in love quickly.</div>
                 </div>
            <b>2</b>&ensp;The aim of Arthur Aron’s research was to see
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>if people would fall in love after four minutes. <br> if there was a big difference between fancying someone and loving them. <br> if intimacy could be provoked. <br> how many questions it would take to make people feel close.</div>
                 </div>
            <b>3</b>&ensp;Aron explained that his questions
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>would make people famous. <br> should be done while people stare at each other. <br> would help people have experiences together. <br> would make people feel that they had a connection.</div>
                 </div>
            <b>4</b>&ensp;The experiment that tested Aron’s theory resulted in
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>some people hoping to stay in contact. <br> a few marriages. <br> all the participants quickly falling in love. <br> only a few of the partners forming a connection.</div>
                 </div>
              
        `,
    },
  },
  5: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P13-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      // marginBottom: -70,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 600, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write sentences to illustrate the meaning of the phrasal
verbs in the table in exercise 4.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <div style='width:25cm'>
            <b>1</b>&ensp;#(call off)<br>
            <b>2</b>&ensp;#(let down)<br>
            <b>3</b>&ensp;#(look after)<br>
            <b>4</b>&ensp;#(run into)<br>
            <b>5</b>&ensp;#(take after)<br>
            <b>6</b>&ensp;#(turn down)<br>
         
          
        </div>
          `,
        answer: ["few", "one", "a", "none", "both", "no"],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P13-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 100,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with one word in each gap`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
          <div style='width:25cm'>
            In <sup>1</sup># recent survey, two thousand British
            men and women were asked about their friendships.
            The women turned out to be twice as good at making
            friends for life, with one in three saying that their best
            friend was someone they had met at school. In contrast,
            only one in six men were best friends with <sup>2</sup>#
            school friend. Although the men had between fifty
            and a hundred Facebook friends and fifty friends’
            numbers in their phones, they only had a <sup>3</sup>#
            close friends – on average, about five. <sup>4</sup>#
            sexes admitted to having at least one person in their
            friendship group they couldn’t stand; although not
            quite as <sup>5</sup># men as women confessed to this, it
            was still a surprising 13%! <sup>6</sup># survey concluded
            that all <sup>7</sup># us can expect to have fewer close
            friends as we get older, with only about three.
            
          </div>
          
          
          `,
        answer: ["a", "a", "few", "Both", "many", "The", "of"],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P13-E7",
    audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
