import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P53-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page53/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 178,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "amounts",
          "diet",
          "dishes",
          "flavours",
          "leftovers",
          "menu",
          "portion",
          "slice",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the text with the words below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
            <div style='position:relative'>
                <img src="img/FriendsPlus/Page53/E1/1.jpg" style='height:15cm'/>
              <div style='position:absolute;top:26px;left:344px'>#</div>
              <div style='position:absolute;top:142px;left:261px'>#</div>
              <div style='position:absolute;top:181px;left:194px'>#</div>
              <div style='position:absolute;top:219px;left:354px'>#</div>
              <div style='position:absolute;top:297px;left:203px'>#</div>
              <div style='position:absolute;top:375px;left:206px'>#</div>
              <div style='position:absolute;top:452px ;left:124px'>#</div>
              <div style='position:absolute;top:491px ;left:41px'>#</div>
            </div>

            
            
          `,
        answer: [
          "diet",
          "amounts",
          "slice",
          "portion",
          "leftovers",
          "menu",
          "flavours",
          "dishes",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P53-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page53/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 150, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: "25cm",
      marginBottom: -20,
      marginLeft: 20,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["breath", "high", "mouth", "old", "thought", "time"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the text and answer the questions.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    character: /[;,and]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `


              <div style='width:26cm'>
                <b>1</b>&ensp;What reasons are used to support an insect-based diet?
                <textarea id=0 rows=3></textarea>
                <b>2</b>&ensp;How are supporters of insects as food trying to persuade Americans to eat them?
                <textarea id=1 rows=3></textarea>
                <div style='margin-top:20px'>
                       With an abundance of cheap food and a weakness
                      for junk food, the dietary habits of many Americans
                      (and people in other Western countries) have led to an
                      obesity epidemic. As a result, a growing number of food
                      experts are saying it’s time to replace traditional dishes
                      with more nutritious and also more economical and
                      ecological dishes: dishes that contain insects.<br>
                      If you put a plate of bugs (as insects are often called
                      in the USA) in front of an American, they, like most
                      Westerners, would probably refuse to eat them.
                      Insects are seen as pests that destroy food crops rather
                      than delicacies to be eaten. <sup>1</sup>____ The challenge for the
                      promoters of insect-based meals is to make Americans
                      see them in the same way.<br>
                      During the 20th century, if you had visited a research
                      department at an American food and agricultural
                      university, you would have heard about how they were
                      trying to reduce insect numbers due to the negative
                      impact they have on crops and seeds. <sup>2</sup>____ Furthermore,
                      insect fairs such as the BugFest at the North Carolina
                      Museum or The Great Insect Fair at Penn State
                      University explain that grasshoppers, moths and their
                      cousins are an excellent source of nutrients like protein,
                      minerals and vitamins. The fairs also offer the more
                      adventurous visitors ‘bug banquets’, which serve
                      mouth-watering insect dishes.<br>
                      Apart from the scientific community, a growing number
                      of market stalls and restaurants are providing insect
                      meals too. <sup>3</sup>____ What is more, fans of insect-based
                      cooking like Daniella Martin are spreading the word that
                      bugs are good for you. Martin’s blog Girl Meets Bug,
                      her internet cooking videos and her newspaper columns
                      are all contributing to changing Americans’ perceptions
                      of insects. <sup>4</sup>____ While entomophagists (people who eat
                      insects) agree they face an uphill struggle, with
                      70 million obese citizens and millions more who want to
                      be environmentally friendly, it seems that this could be
                      the right time for insects to invade America’s kitchens.
                                  
                </div>
              </div>

            
            
          `,
        answer: [
          "the current obesity epidemic; financial, nutritional and ecological reasons",
          "Universities hold insect fairs and more market stalls and restaurants are offering insect meals. Furthermore, specialist blogs like Daniella Martin's are promoting the eating of insects.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P53-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page53/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 50,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#42429C'>Reading Strategy</span>. Then read the text again.
Match sentences (A–E) with gaps (1–4). There is one extra
sentence.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page53/E3/1.jpg" style='height:8.5cm'/>
              <div style='width:26cm'>
                <b>A</b>&ensp;Today, 50% of the same universities promote insects as food.<br>
<b>B</b>&ensp;The popularity of Mexican food in the USA is also helping, as companies such as Don Bugito offer traditional Mexican insect dishes.<br>
<b>C</b>&ensp;Moreover, the amount of land needed to produce enough insects to feed the whole country is much smaller than the land needed to produce meat.<br>
<b>D</b>&ensp;But will it be enough?<br>
<b>E</b>&ensp;However, in parts of Asia, Africa and Central America, where there are plenty of big, juicy insects all year round, they are considered a delicious staple.<br>
                <div style='margin-top:20px'>
                       With an abundance of cheap food and a weakness
                      for junk food, the dietary habits of many Americans
                      (and people in other Western countries) have led to an
                      obesity epidemic. As a result, a growing number of food
                      experts are saying it’s time to replace traditional dishes
                      with more nutritious and also more economical and
                      ecological dishes: dishes that contain insects.<br>
                      If you put a plate of bugs (as insects are often called
                      in the USA) in front of an American, they, like most
                      Westerners, would probably refuse to eat them.
                      Insects are seen as pests that destroy food crops rather
                      than delicacies to be eaten. <sup>1</sup># The challenge for the
                      promoters of insect-based meals is to make Americans
                      see them in the same way.<br>
                      During the 20th century, if you had visited a research
                      department at an American food and agricultural
                      university, you would have heard about how they were
                      trying to reduce insect numbers due to the negative
                      impact they have on crops and seeds. <sup>2</sup># Furthermore,
                      insect fairs such as the BugFest at the North Carolina
                      Museum or The Great Insect Fair at Penn State
                      University explain that grasshoppers, moths and their
                      cousins are an excellent source of nutrients like protein,
                      minerals and vitamins. The fairs also offer the more
                      adventurous visitors ‘bug banquets’, which serve
                      mouth-watering insect dishes.<br>
                      Apart from the scientific community, a growing number
                      of market stalls and restaurants are providing insect
                      meals too. <sup>3</sup># What is more, fans of insect-based
                      cooking like Daniella Martin are spreading the word that
                      bugs are good for you. Martin’s blog Girl Meets Bug,
                      her internet cooking videos and her newspaper columns
                      are all contributing to changing Americans’ perceptions
                      of insects. <sup>4</sup># While entomophagists (people who eat
                      insects) agree they face an uphill struggle, with
                      70 million obese citizens and millions more who want to
                      be environmentally friendly, it seems that this could be
                      the right time for insects to invade America’s kitchens.
                                  
                </div>
              </div>

            
            
          `,
        answer: ["E", "A", "B", "D"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P53-E4",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page53/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Choose the correct options (a–c) to complete the text above.

`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 350, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { marginLeft: 30, width: 600 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "1-0", "2-4", "3-8", "4-0", "5-4", "6-4"],
        initialValue: [],
      },
      Layout: `

           <img src="img/FriendsPlus/Page53/E4/1.jpg" style='height:15cm'/>

                      <div style='display:flex'>  
                      <div style='margin-right:200px'>  
                      <div style='display:flex'>  
                          <b>1</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=0 type=Circle ></div>
                                <div style='margin-left:10px'>single-minded <br> straightforward <br> far-reaching</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>2</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=1 type=Circle ></div>
                                <div style='margin-left:10px'>well-known <br> good-looking <br> well-written</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>3</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=2 type=Circle ></div>
                                <div style='margin-left:10px'>mind-blowing <br> highly respected <br> badly paid</div>
                            </div>
                          </div>
          <div style='display:flex'>
                            <b>4</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=3 type=Circle ></div>
                                <div style='margin-left:10px'>time-saving <br> second-hand <br> much-needed</div>
                            </div>
          </div>
                          </div>
                          <div>
                          
           
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=4 type=Circle ></div>
                <div style='margin-left:10px'>far-fetched <br> half-hearted <br> high-spirited</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>upcoming <br> thought-provoking <br> old-fashioned</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>7</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=6 type=Circle ></div>
                <div style='margin-left:10px'>light-hearted <br> bleary-eyed <br> absent-minded</div>
             </div>
          </div>
          
          </div>
          </div>

  `,
    },
  },
  5: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P53-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page53/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      marginLeft: 17,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["able", "might", "mustn’t", "needn’t", "should (x2)", "supposed"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Read the <span style='color:#92278f'>Vocab boost!</span> box. Then complete the second
part of each sentence with an explanation. Try not to
repeat the words from the compound adjective.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    isHello: true,
    KeyMau: true,
    questions: [
      {
        title: `
        

        <div style='width:20cm'>
           <b>1</b>&ensp;A short-sighted person is someone. <textarea id=0 rows=2 ></textarea>
           <b>2</b>&ensp;A cold-blooded animal is an animal. <textarea id=1 rows=2 ></textarea>
           <b>3</b>&ensp;A last-minute decision is a decision. <textarea id=2 rows=2 ></textarea>
           <b>4</b>&ensp;A world-famous tourist attraction is one. <textarea id=3 rows=2 ></textarea>
           <b>5</b>&ensp;A well-behaved child is a child. <textarea id=4 rows=2 ></textarea>
           <b>6</b>&ensp;An open-minded person is someone. <textarea id=5 rows=2 ></textarea>
        </div>

          `,
        answer: [
          "who can't see well",
          "whose body temperature is affected by the temperature of the air / water",
          "made at the latest possible time",
          "which is known by people all over the world",
          "who has good behaviour",
          "who doesn't judge other people",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P53-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page53/Key/E6answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page53/E6/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:111px;left:70px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:154px;left:70px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:197px;left:70px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
  7: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P53-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page53/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P53-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page53/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
