import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P48-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 159,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "ambitious",
          "inflexible",
          "optimistic",
          "empathetic",
          "creative",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words to complete the text.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "reduce / consume",
          "produce / digest",
          "burn / produce",
          "process / burn",
          "reduces / burns",
          "control / process",
          "consume / boost",
        ],
        answers: ["0-4", "1-0", "2-0", "3-0", "4-0", "5-0", "6-4"],
        initialValue: [],
      },
      Layout: `

            <div style='position:relative'>
              <img src="img/FriendsPlus/Page48/E1/1.jpg" style='height:30cm'/>
              <div style='position:absolute;top:676px;left:26px;font-size:23px;line-height:35px;width:171mm'>
                Our brains account for 2% of our weight, but <sup>1</sup><input id=0 type=Circle />
                20% of the energy that our bodies <sup>2</sup><input id=1 type=Circle />
                after eating.
                When we sleep, our brains continue to <sup>3</sup><input id=2 type=Circle />
                energy
                because they are still working. They <sup>4</sup><input id=3 type=Circle />
                all the day’s
                activities and create memories. The brain also repairs itself,
                but if we don’t sleep enough, it <sup>5</sup><input id=4 type=Circle />
                the time that
                our brains have for this work. Having fewer hours of sleep also
                affects our diets, as it is more difficult for our brains to <sup>6</sup><input id=5 type=Circle /> 
                our weight if we don’t sleep enough. If we want to
                <sup>7</sup><input id=6 type=Circle /> 
                our brains’ energy levels, we should do
                exercise. Exercise pumps blood full of oxygen to our brains.

              </div>
            </div>
           
              
        `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P48-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 150, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["boost", "consume", "digest", "process", "produce", "reduce"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the verbs below with the definitions. There is one
extra verb.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
      <div style='width:26cm'>
          <b>1</b>&ensp;to use fuel, energy, or time, especially in large amounts #<br>
          <b>2</b>&ensp;to make something smaller in size, importance, amount, etc. #<br>
          <b>3</b>&ensp;to make something #<br>
          <b>4</b>&ensp;to improve or increase something #<br>
          <b>5</b>&ensp;to change food in your stomach into substances that your body can use #<br>
      </div>
            
            
          `,
        answer: ["consume", "reduce", "produce", "boost", "digest "],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P48-E3",
    // audio: "Audios/1.25.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 120,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the words in the sentences.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
 
 
        <hintbox id=0></hintbox>
 
              <div style='width:26cm'>
                  <b>1</b>&ensp;Sausages, sliced ham and bacon can be tasty, but
                  p# m# is not very good for you.
                  <br><b>2</b>&ensp;You should avoid all types of p#
                  f# such as pre-cooked meals.
                  <br><b>3</b>&ensp;D# p# like milk and cheese are
                  important for growing children because they strengthen
                  their bones.
                  <br><b>4</b>&ensp;It’s much better to eat brown bread made from
                  w# g# flour rather than
                  processed white bread.
                  <br><b>5</b>&ensp;Some p# farmers keep turkeys and ducks
                  as well as chickens.
              </div>
             
             
            
            
          `,
        // InputRong: true,
        answer: [
          "rocessed",
          "eat",
          "rocessed",
          "ood",
          "airy",
          "roducts",
          "hole",
          "rain",
          "oultry",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P48-E4",
    audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.02 color=white colorimg='#d1b4d8'></headphone> Listen to a nutritionist giving advice about the
best food for people who do sport. Tick the things he
mentions.

`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 200, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 200, textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓"],
        answers: ["0-0", "1-0", "4-0", "5-0", "6-0", "7-0", "9-0", "10-0"],
        initialValue: [],
      },
      Layout: `
            <div style='display:flex'>
              <div>
                  <b>1</b>&ensp;calcium <br>
                  <b>2</b>&ensp;calories <br>
                  <b>3</b>&ensp;carbohydrates <br>
                  <b>4</b>&ensp;cholesterol <br>
                  <b>5</b>&ensp;fat <br>
                  <b>6</b>&ensp;fibre<br>
              </div>
              <div style='margin-left:50px;margin-right:200px'>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=0 type=Circle /></span><br>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=1 type=Circle /></span><br>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=2 type=Circle /></span><br>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=3 type=Circle /></span><br>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=4 type=Circle /></span><br>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=5 type=Circle /></span><br>
              </div>
              <div>
                  <b>7</b>&ensp;minerals <br>
                  <b>8</b>&ensp;nutrients <br>
                  <b>9</b>&ensp;preservatives<br>
                  <b>10</b>&ensp;protein <br>
                  <b>11</b>&ensp;vitamins<br>
              </div>
              <div style='margin-left:50px'>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=6 type=Circle /></span><br>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=7 type=Circle /></span><br>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=8 type=Circle /></span><br>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=9 type=Circle /></span><br>
                  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=10 type=Circle /></span><br>
              </div>
            </div>

  `,
    },
  },
  5: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P48-E5",
    audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 161,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=2.02 color=white colorimg='#d1b4d8'></headphone> Listen again and complete the advice with the
words you ticked in exercise 4.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
       <div style='position:relative'>
              <img src="img/FriendsPlus/Page48/E5/1.jpg" style='height:18cm'/>
              <div style='position:absolute;top:73px;left:75px;'>#</div>
              <div style='position:absolute;top:185px;left:79px;'>#</div>
              <div style='position:absolute;top:262px;left:265px;'>#</div>
              <div style='position:absolute;top:296px;left:79px;'>#</div>
              <div style='position:absolute;top:373px;left:395px;'>#</div>
              <div style='position:absolute;top:450px;left:348px;'>#</div>
              <div style='position:absolute;top:485px;left:260px;'>#</div>
              <div style='position:absolute;top:596px;left:79px;'>#</div>
            </div>
          `,
        answer: [
          "nutrients",
          "vitamins",
          "protein",
          "fat",
          "fibre",
          "minerals",
          "calories",
          "calcium",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P48-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the sentences with compound adjectives.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            <b>1</b>&ensp;We went to a restaurant with Andy last night and he was rude and unpleasant to everyone! He’s always been b#-m# .<br>
            <b>2</b>&ensp;You’d have to be very t#-s# not to be upset by his insensitive remarks.<br>
            <b>3</b>&ensp;She devotes all her time to her training. She’s very ambitious and s#-m# when it comes to achieving her goals.<br>
            <b>4</b>&ensp;We saw a romantic comedy at the theatre last weekend which was fun and quite l#-h# .<br>
            <b>5</b>&ensp;Lily was very s#-c# before the exam because she’d studied a lot and knew she would do well.<br>
            <b>6</b>&ensp;I’ve met quite a few relaxed people in my life, but I’ve never known anyone as e#-g# as Mark.<br>
        </div>
          
    `,
        answer: [
          "ad",
          "annered",
          "hick",
          "kinned",
          "ingle",
          "inded",
          "ight",
          "earted",
          "elf",
          "onfident",
          "asy",
          "oing",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P48-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P48-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
