import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P65-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 159,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the second sentence so that it means the same
as the first.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        
            <div><b>1</b>&ensp;Chris lost his phone yesterday.
              <div style='margin-left:25px'>
                It was Chris <input width=550px />. 
              </div>
          
              <div><b>2</b>&ensp;Ella is getting her hair cut tomorrow.
              <div style='margin-left:25px'>
                It’s tomorrow <input width=529px />. 
              </div>
          
              <div><b>3</b>&ensp;His grandparents moved to New Zealand last year.
              <div style='margin-left:25px'>
                It’s New Zealand <input width=496px />. 
              </div>
          
              <div><b>4</b>&ensp;My brother wasn’t born until 2010.
              <div style='margin-left:25px'>
                It wasn’t until <input width=531px />. 
              </div>
          
              <div><b>5</b>&ensp;Nick has been learning to ride a motorbike.
              <div style='margin-left:25px'>
                It’s a <input width=629px />. 
              </div>
          
              <div><b>6</b>&ensp;I have guitar lessons on Saturday mornings.
              <div style='margin-left:25px'>
                It’s on <input width=612px />. 
              </div>
              <div><b>7</b>&ensp;We saw Roberto at the club last night.
              <div style='margin-left:25px'>
                It was Roberto <input width=516px />. 
              </div>
              <div><b>8</b>&ensp;They don’t enjoy watching sci-fi films.
              <div style='margin-left:25px'>
                It’s sci-fi films <input width=529px />. 
              </div>

          `,
        answer: [
          "who lost his phone yesterday",
          "that Ella is getting her hair cut",
          "that his grandparents moved to last year",
          "2010 that my brother was born",
          "motorbike that Nick has been learning to ride",
          "Saturday mornings that I have guitar lessons",
          "that we saw at the club last night",
          "that they don't enjoy watching",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P65-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 400, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Rewrite the sentences to make a contrast. Begin with the
words given and emphasise the words in bold.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div><b>1</b>&ensp;This bag is made of <b>leather</b>. It’s not made of plastic.
              <div style='margin-left:25px'>
                It’s leather that<input width=421px />. 
              </div>
          
              <div><b>2</b>&ensp;The film doesn’t finish at 8 p.m. It finishes at <b>9 p.m</b>.
              <div style='margin-left:25px'>
                It’s at<input width=529px />. 
              </div>
          
              <div><b>3</b>&ensp;My sister didn’t give me this present. <b>Leo</b> did.
              <div style='margin-left:25px'>
                It was<input width=523px />. 
              </div>
          
              <div><b>4</b>&ensp;I prefer the <b>blue</b> dress. I don’t prefer the grey one.
              <div style='margin-left:25px'>
                It’s the<input width=515px />. 
              </div>
          
              <div><b>5</b>&ensp;Tom doesn’t drive a Ferrari. He drives a <b>Porsche</b>.
              <div style='margin-left:25px'>
                It’s a<input width=539px />. 
              </div>
          
              <div><b>6</b>&ensp;Sam isn’t going on holiday to Turkey. <b>Dan</b> is.
              <div style='margin-left:25px'>
                It’s Dan<input width=508px />. 
              </div>
              <div><b>7</b>&ensp;School finishes in <b>June</b>. It doesn’t finish in July.
              <div style='margin-left:25px'>
                It’s in<input width=533px />. 
              </div>
            
            
          `,
        answer: [
          "this bag is made of, not plastic",
          "9 p.m. that the film finishes, not at 8 p.m.",
          "Leo who gave me this present, not my sister",
          "blue dress that I prefer, not the grey one",
          "Porsche that Tom drives, not a Ferrari",
          "who's going on holiday to Turkey, not Sam",
          "June that school finishes, not July",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P65-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 800,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Rewrite the sentences beginning with <i>What</i> or <i>All</i>.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
              <div><b>1</b>&ensp;This chicken needs to be cooked a bit longer.
              <div style='margin-left:25px'>
                What<input width=736px />. 
              </div>
          
              <div><b>2</b>&ensp;You only need to answer two of the exam questions.
              <div style='margin-left:25px'>
                All<input width=769px />. 
              </div>
          
              <div><b>3</b>&ensp;We’ll just have to buy some drinks for the barbecue.
              <div style='margin-left:25px'>
                All<input width=769px />. 
              </div>
          
              <div><b>4</b>&ensp;The only thing I know about our new neighbours is that they are Swedish.
              <div style='margin-left:25px'>
                All<input width=768px />. 
              </div>
          
              <div><b>5</b>&ensp;You mustn’t forget to turn off the TV before bedtime.
              <div style='margin-left:25px'>
                What<input width=737px />. 
              </div>
          `,
        answer: [
          "this chicken needs is to be cooked a bit longer",
          "you need to answer is two of the exam questions / you need to do is answer two of the exam questions",
          "we'll have to buy is some drinks for the barbecue / we will have to buy is some drinks for the barbecue",
          "I know about our new neighbours is that they are Swedish",
          "you mustn't forget is  turn off the TV before bedtime / you mustn't forget to do is to turn off the TV before bedtime",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P65-E4",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginTop: 20,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Write the words in the correct order to make emphatic
sentences
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 600, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div>
              <b>1</b>&ensp;who / Eva / that / The / person / dog / owns / is <br>#<br>
              <b>2</b>&ensp;I / is / thing / spiders / can’t / One / stand <br>#<br>
              <b>3</b>&ensp;often / is / A / to / they’ve / place / Japan / been <br>#<br>
              <b>4</b>&ensp;who / most / Adele / singer / she / is / The / loves <br>#<br>
              <b>5</b>&ensp;doesn’t / green / colour / like / The / Laila / is <br>#<br>
              <b>6</b>&ensp;try / octopus / food / never / One / I’ll / is / !<br>#<br>
              <b>7</b>&ensp;like / snowboarding / try / A / he’d / is / sport / to<br>#<br>
              
            </div>
            
          `,
        answer: [
          "The person who owns that dog is Eva.",
          "One thing I can't stand is spiders.",
          "A place they've  been to  is Japan.|A place they've often been to often is Japan.",
          "The singer she loves most is Adele.",
          "The colour Laila doesn't like is green.",
          "One food I'll never try is octopus!",
          "A sport he'd like to try is snowboarding. ",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P65-E5",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E5answerKey.png",
    component: Circle_Write,
    // textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,

      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 800,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct words to complete the forum post.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "One / What",
          "it_was / it’s",
          "Where / What",
          "a / the",
          "place / thing",
          "it’s / what’s",
          "All / One",
        ],
        answers: ["0-0", "1-4", "2-4", "3-4", "4-0", "5-0", "6-0"],
        initialValue: [],
      },
      Layout: `

            <div style='position:relative'>
              <img src="img/FriendsPlus/Page65/E5/1.jpg" style='height:16cm'/>
              <div style='position:absolute;top:125px;left:74px;font-size:23px;line-height:35px;width:175mm'>
                Why do teenagers always get the blame for everything?
                  <sup>1</sup><input id=0 type=Circle />
                   thing that really annoys me is getting
                  blamed for all the litter in town, when <sup>2</sup><input id=1 type=Circle />
                  often the adults who don’t use the bins. <sup>3</sup><input id=2 type=Circle />
                  the authorities need to do is put CCTV cameras outside
                  so they can see who’s responsible! But <sup>4</sup><input id=3 type=Circle />
                   main
                  problem they need to address is providing somewhere
                  for us to go. One <sup>5</sup><input id=4 type=Circle />
                   where we can hang
                  out is at fast-food restaurants, so <sup>6</sup><input id=5 type=Circle />
                   there
                  that people see us in groups and assume we’re causing
                  trouble. <sup>7</sup><input id=6 type=Circle />
                   we want is somewhere we can go
                  and enjoy ourselves! <b style='color:gray'>dexy33</b>

              </div>
            </div>
           
              
        `,
    },
  },
  6: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P65-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 176,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthful"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with one word in each gap.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page65/E6/1.jpg" style='height:15cm'/>
          <div style='position:absolute;top:46px;left:318px'>#</div>
          <div style='position:absolute;top:152px;left:99px'>#</div>
          <div style='position:absolute;top:223px;left:250px'>#</div>
          <div style='position:absolute;top:293px;left:210px'>#</div>
          <div style='position:absolute;top:364px;left:247px'>#</div>
          <div style='position:absolute;top:435px;left:266px'>#</div>
         
         
         
        </div>
        `,
        answer: ["it", "One / The", "what", "One", "all", "one"],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P65-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P65-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
