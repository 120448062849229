import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P47-E1",
    // audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page47/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { marginLeft: 20, width: 700, paddingTop: 7 },
    hintBox: [
      {
        src: ["bonds", "touch", "time", "commitment", "isolation"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the task and the letter below. In which paragraph
(A–D) does the writer …
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <div style='display:flex;margin-bottom:20px;line-height:50px'>
               <div>
                 <b>1</b>&ensp;conclude the letter?<br>
                 <b>2</b>&ensp;state the reason for writing?<br>
                 <b>3</b>&ensp;mention his/her qualities?<br>
                 <b>4</b>&ensp;include his available time for work <br>
               </div>
              <div style='margin:0px 50px'>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                
              </div>
             
            </div>
              <img src="img/FriendsPlus/Page47/E1/1.jpg" style='height:6cm'/><br>
              <img src="img/FriendsPlus/Page47/E1/2.jpg" style='height:30cm'/>
          `,
        answer: ["D", "A", "B", "C"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P47-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page47/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 350, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "as soon as possible",
          "I can’t wait to hear",
          "give",
          "I think",
          "I want to",
          "needs",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#2385C6'>Writing Strategy</span>. Match the underlined formal
expressions in the letter with the informal ones below.`,
        width: "26cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page47/E1/3.jpg" style='height:5.5cm'/><br>
        <hintbox id=0></hintbox>
        <b>Answer:</b><br>
          <b>1</b>#<br>
          <b>2</b>#<br>
          <b>3</b>#<br>
          <b>4</b>#<br>
          <b>5</b>#<br>
          <b>6</b>#<br>
          <img src="img/FriendsPlus/Page47/E1/2.jpg" style='margin-top:10px;height:30cm'/>
         
          

        
        
          
          `,
        // InputRong: true,
        answer: [
          "I think",
          "I want to",
          "needs",
          "give",
          "I can't wait to hear",
          "as soon as possible",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P47-E3",
    // audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page47/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // margin: "0px 10px",
      background: "none",
      width: 700,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Match the underlined informal parts of sentences (1–5)
with formal phrases (a–e).
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

            <div style='display:flex;margin-bottom:20px;line-height:50px'>
              <div>
                <b>1</b>&ensp;<u>Please write back soon</u>.<br>
                <b>2</b>&ensp;<u>I believe</u> having a part-time job would really benefit me.<br>
                <b>3</b>&ensp;My experience is <u>this</u>.<br>
                <b>4</b>&ensp;I hope you <u>like my application</u>.<br>
                <b>5</b>&ensp;<u>I’d like it</u> if you would consider my application.<br>
              </div>
              <div style='margin:0px 50px'>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
              </div>
              </div>
              <div>
                 <b>a</b>&ensp;In my view<br>
                 <b>b</b>&ensp;I would be grateful<br>
                 <b>c</b>&ensp;as follows<br>
                 <b>d</b>&ensp;I look forward to hearing from you<br>
                 <b>e</b>&ensp;consider my application favourably<br>
               </div>

            
            
          `,
        answer: ["d", "a", "c", "e", "b"],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P47-E4",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page47/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the want ad. Then plan your letter of application. 

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 700, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style=''>
          <img src="img/FriendsPlus/Page47/E4/1.jpg" style='height:14cm'/>
        </div>
        <b>1</b>&ensp;What are your qualities that make you suitable for the job?<br>
        <b>2</b>&ensp;On what day of the week can you work?<br>
          `,
        answer: [
          "I suggest that you review the booking procedure.",
          "I recommend that you announce delays.",
          "I propose you delay your decision.|I propose that you delay your decision.",
          "I demand that my ticket be refunded.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P47-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page47/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 50, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "15cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "itinerary missed out an important place",
          "tour didn’t run on time",
          "tour guide was unenthusiastic / too quiet ",
          "no toilet on the bus",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write your letter. `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <textarea id=0 rows=10></textarea>
        
          `,
        answer: ["d", "b", "e", "a", "c"],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P47-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page47/Key/E7answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page47/E7/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:113px;left:78px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:159px;left:78px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:204px;left:78px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
  6: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P47-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page47/Key/E6answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: " ",
        title: ` `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page47/E6/1.jpg" style='height:7.5cm'/>
        <div style='position:absolute;top:113px;left:68px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:159px;left:68px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:205px;left:68px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
};

export default json;
