import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Cumulative Review 5",
    id: "12WB-2024-CR5-P80-E1",
    audio: "Audios/2.23.mp3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 159,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.23 color=white colorimg='#d1b4d8'></headphone> Listen to four speakers talking about their favourite
TV advertisement. Match speakers (1–4) with sentences (A–E).
There is one extra sentence. 
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:24cm;line-height:47px'>
              <div>
                 <b>A</b>&ensp;He/She recognises a familiar scene in the ad. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>B</b>&ensp;He/She admires the technique used to make the ad. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>C</b>&ensp;He/She praises the clever slogan at the end of the ad. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>D</b>&ensp;He/She enjoys seeing somebody made happy in the ad. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>E</b>&ensp;He/She empathises with one of the characters in the ad. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
              </div>
              
           
        </div>
          `,
        InputRong: true,
        answer: ["3", "1", "", "2", "4"],
      },
    ],
  },
  2: {
    unit: "Cumulative Review 5",
    id: "12WB-2024-CR5-P80-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E2answerKey.png",
    component: T6,
    recorder: true,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 400, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["accommodation", "clothes", "food and drink", "what to see"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `You want to advertise a fundraising event your school is
holding. Say which of these places is the best for advertising
the event and why. Say why you are rejecting the other option.


`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
             <div>
              <b>1</b>&ensp;the circumstances of losing the bag<br>
              <b>2</b>&ensp;a description of the bag<br>
              <b>3</b>&ensp;what was in the bag<br>
              <b>4</b>&ensp;what will happen next<br>
             </div>
          `,
        answer: [
          "I had never met",
          "he wouldn't be exhausted",
          "if they had studied harder",
          "You would be poor",
          "she wouldn't be burned now",
          "I knew your address",
        ],
      },
    ],
  },
  3: {
    unit: "Cumulative Review 5",
    id: "12WB-2024-CR5-P80-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      marginBottom: 10,
      // fontWeight: "bold",
      // marginLeft: 25,
      width: 500,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the article. Match sentences (A–G) with gaps (1–5) in the
article. There are two extra sentences.
`,
        width: "35cm",
        color: "black",
        left: 50,
      },
    ],
    // character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `






          <div style='width:31cm;margin-top:10px'>
        
                 <div style='display:flex;'>
                    <div style='margin-right:15px;margin-right:10px'><b>A</b>&ensp;</div>
                    <div>As a result, Kuapa Kokoo received only half of the cocoa they
                          had paid for and had no money left to buy more. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /></div>
                 </div>
            
                 <div style='display:flex;'>
                    <div style='margin-right:15px;margin-right:10px'><b>B</b>&ensp;</div>
                    <div>The income they received from cocoa was vital for paying for
                          school fees and medical costs. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /></div>
                 </div>
            
                 <div style='display:flex;'>
                    <div style='margin-right:15px;margin-right:10px'><b>C</b>&ensp;</div>
                    <div>Now the company comprises around 85,000 small-scale
                          farmers, and it has its own chocolate brand, Divine. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /></div>
                 </div>
            
                 <div style='display:flex;'>
                    <div style='margin-right:15px;margin-right:10px'><b>D</b>&ensp;</div>
                    <div>Then, they replaced all of the weighing scales to ensure that
                          they hadn’t been fixed to give the farmers a lower price. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /></div>
                 </div>
            
                 <div style='display:flex;'>
                    <div style='margin-right:15px;margin-right:10px'><b>E</b>&ensp;</div>
                    <div>This time, they received a much larger loan to work with and
                          the banks were back to normal, so the farmers could cash their cheques. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /></div>
                 </div>
            
                 <div style='display:flex;'>
                    <div style='margin-right:15px;margin-right:10px'><b>F</b>&ensp;</div>
                    <div>As the weeks progressed, recorders began to hire their own
                          private trucks, taking on more responsibility. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /></div>
                 </div>
            
                 <div style='display:flex;'>
                    <div style='margin-right:15px;margin-right:10px'><b>G</b>&ensp;</div>
                    <div>Genuinely concerned about poorer farmers, he recruited
                          experienced cocoa people, who he knew and trusted, and found an office for the company in the city of Kumasi. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /></div>
                 </div>
                 </div>
           

          <img src="img/FriendsPlus/Page80/E3/1.jpg" style='height:21.5cm'/>
         
          
         
         
         
        `,
        InputRong: true,
        answer: ["4", "", "1", "3", "5", "", "2"],
      },
    ],
  },
  4: {
    unit: "Cumulative Review 5",
    id: "12WB-2024-CR5-P80-E4",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginTop: 20,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the email with one word in each gap to make
mixed conditional sentences.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      fontSize: 23,
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 166,
      padding: 0,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>1</b>&ensp;The most shocking thing about Amina Arraf is that
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>she disappeared and remains missing. <br> she wasn’t a real person. <br> she wasn’t afraid to express her views online. <br> she was very close to an American man.</div>
                 </div>
            <b>2</b>&ensp;The reason why Tom MacMaster blogged under a false identity was
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>to find out what his fellow countrymen and women thought. <br> to deceive people into taking sides with the USA. <br> to appeal to both men and women in the Middle East. <br> to give credibility to his radical ideas.</div>
                 </div>
            <b>3</b>&ensp;Debbie Swenson created Kaycee Nicole because
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>she enjoyed sharing her medical knowledge. <br> she wanted advice on how to treat her daughter’s illness. <br> she needed to feel popular with other people. <br> she felt like showing her support for cancer sufferers.</div>
                 </div>
            <b>4</b>&ensp;The sock puppet Mary Rosh was used by John Lott
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>to give him the voice of authority. <br> to criticise his opponents. <br> to make out that he was married. <br> to encourage others to look on his work favourably.</div>
                 </div>
            <b>5</b>&ensp;The writer of the article concludes that
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>everybody should be wary of people they come across online. <br> the police should create more sock puppets to combat crime. <br> social media sites are safer than they used to be. <br> terrorists pose a bigger threat on the internet than they do in real life.</div>
                 </div>
           <div style='width:23cm;margin-top:10px'>

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page80/E4/1.jpg" style='height:14cm'/>
          <div style='position:absolute;top:129px;left:641px'>#</div>
          <div style='position:absolute;top:162px;left:280px'><input width=179px /></div>
          <div style='position:absolute;top:196px;left:44px'><input width=179px /></div>
          <div style='position:absolute;top:231px;left:152px'><input width=179px /></div>
          <div style='position:absolute;top:299px;left:224px'><input width=179px /></div>
          <div style='position:absolute;top:368px;left:393px'><input width=179px /></div>
          
         
         
         
        </div>
        `,
        answer: ["have", "wasn't / weren't", "hadn't", "wouldn't", "be", "had"],
      },
    ],
  },
  5: {
    unit: "Cumulative Review 5",
    id: "12WB-2024-CR5-P80-E5",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,

      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the text with the correct form of
the verbs in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: /[|/]/,
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `

        <div style='width:26cm'>
          Oxfam is a world leader in delivering emergency
          relief. If it weren’t for this charity, thousands of
          children in Europe <sup>1</sup>#(go) hungry
          during World War II. Today, desperate people
          <sup>2</sup>#(not have) clean water if Oxfam hadn’t made
          this a priority during humanitarian disasters. And if it
          <sup>3</sup>#(not be) for Oxfam, the NGO ActionAid might
          not exist either; there wouldn’t be so much pressure on
          governments to provide healthcare and education and to
          combat climate change if these charities <sup>4</sup>#(not develop). 
          But Oxfam might not be so successful nowadays if
          it <sup>5</sup>#(not recognise) the importance of linking
          business with charity. Oxfam employs thousands of people
          worldwide, but it couldn’t have become the UK’s biggest
          high-street seller of second-hand books if it <sup>6</sup>#(not be) 
          also for the thousands of volunteers who give their
          time working in these important charity shops.

         
          
         
         
         
        </div>
        `,
        answer: [
          "would have gone",
          "would not have|wouldn't have",
          "had not been|hadn't been",
          "had not developed|hadn't developed",
          "had not recognised / did not recognise|hadn't recognised / didn't recognise",
          "was not / were not|wasn't / weren't",
        ],
      },
    ],
  },
  6: {
    unit: "Cumulative Review 5",
    id: "12WB-2024-CR5-P80-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 176,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthful"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with one word in each gap.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page80/E6/1.jpg" style='height:15cm'/>
          <div style='position:absolute;top:46px;left:318px'>#</div>
          <div style='position:absolute;top:152px;left:99px'>#</div>
          <div style='position:absolute;top:223px;left:250px'>#</div>
          <div style='position:absolute;top:293px;left:210px'>#</div>
          <div style='position:absolute;top:364px;left:247px'>#</div>
          <div style='position:absolute;top:435px;left:266px'>#</div>
         
         
         
        </div>
        `,
        answer: ["it", "One / The", "what", "One", "all", "one"],
      },
    ],
  },
  7: {
    unit: "Cumulative Review 5",
    id: "12WB-2024-CR5-P80-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Cumulative Review 5",
    id: "12WB-2024-CR5-P80-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
