import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P42-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page42/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 177,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: ["panel", "departments", "candidate", "context", "skills"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the text with words below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
              <img src="img/FriendsPlus/Page42/E1/1.jpg" style='height:10.5cm'/>
              <div style='position:absolute;top:70px;left:36px'>#</div>
              <div style='position:absolute;top:111px;left:36px'>#</div>
              <div style='position:absolute;top:152px;left:36px'>#</div>
              <div style='position:absolute;top:235px;left:380px'>#</div>
              <div style='position:absolute;top:276px;left:359px'>#</div>
              
              
           
        </div>

          `,
        answer: ["candidate", "departments", "context", "skills", "panel"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P42-E2",
    audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page42/Key/E2answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 250, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cook", "learn", "look", "travel", "wait", "work"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.26 color=white colorimg='#d1b4d8'></headphone> Read the <span style='color:#C40B44'>Reading Strategy</span>. Then listen to four
dialogues and choose the word the second speaker uses.`,
        color: "black",
        left: -20,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "shy / bad-mannered",
          "creative / colourful",
          "shrewd / judgemental",
          "open-minded / single-minded",
        ],
        answers: ["0-4", "1-0", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `

            <div style='width:20cm'>
                <b>1</b>&ensp;The second speaker describes the new person she met as 
                <input id=0 type=Circle />.<br>
                <b>2</b>&ensp;The second speaker says that the singer’s costume was 
                <input id=1 type=Circle />.<br>
                <b>3</b>&ensp;The second speaker describes the footballer as being 
                <input id=2 type=Circle />.<br>
                <b>4</b>&ensp;The second speaker thinks the politician is 
                <input id=3 type=Circle />.<br>
            </div>
           
              
        `,
    },
  },
  3: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P42-E3",
    audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page42/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },

    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 700,
      // padding: 0,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.26 color=white colorimg='#d1b4d8'></headphone> For each speaker, choose the correct attitude
(a–c). Use your answers to exercise 2 to help you. Listen
again and check.
`,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-0", "1-8", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `


            <b>1</b>&ensp;The second speaker was when she met her friend’s new partner.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>unimpressed <br> bored <br> enthusiastic</div>
                 </div>
            <b>2</b>&ensp;The second speaker the singer’s outfit.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>disapproves of <br> disbelieves <br> admires</div>
                 </div>
            <b>3</b>&ensp;The second speaker is when talking about the footballer.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>disapproving <br> unimpressed <br> uninterested</div>
                 </div>
            <b>4</b>&ensp;The second speaker is in her attitude to the politician.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>critical <br> approving <br> disbelieving</div>
                 </div>
           
              
        `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P42-E4",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page42/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Say these lines from the dialogues. Try to express the
attitude in brackets.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 900, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
              <div style='width:25cm'>
                <b>1</b>&ensp;Hey, did you enjoy the party? Who was there? Tell me all about it! (enthusiastic)<br>
                <b>2</b>&ensp;Did you see the outfit that Rihanna was wearing at the Music Awards? Rather bright, wasn’t it? (critical)<br>
                <b>3</b>&ensp;I wish that footballer would keep his views to himself. He’s always got too much to say about the other players. (annoyed)<br>
                
                
              </div>
             
            
            
          `,
        // InputRong: true,
        answer: [
          "I had never snorkelled before Mia took me last year.",
          "Clara was annoyed because the book she'd ordered hadn't arrived.",
          "How long had you been playing the guitar before we met?",
          "By the time the shops opened, they had been queuing for nearly an hour.",
          "I looked for my bag, but I'd forgotten where I'd put it.",
          "Greg was tired last night because he had been playing basketball.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P42-E5",
    audio: "Audios/1.27.mp3",
    exerciseKey: "img/FriendsPlus/Page42/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["date", "enjoy", "find", "not imagine", "live", "star", "think"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=1.27 color=white colorimg='#d1b4d8'></headphone> Listen and match speakers (1–4) with sentences
(A–E). There is one extra sentence.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
              <div>
                 <b>1</b>&ensp;The speaker is angry about the lack of accuracy in news reporting.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>2</b>&ensp;The speaker is critical of people’s lack of interest in improving society.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>3</b>&ensp;The speaker admits to an interest in celebrity gossip.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>4</b>&ensp;The speaker feels that news about celebrities is unrealistic.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>5</b>&ensp;The speaker feels that news reporting is pessimistic. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 
               </div>
              
           
        </div>
        
            
            
          `,
        InputRong: true,
        answer: ["E", "B", "D", "C", ""],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P42-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page42/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      // padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the second sentence with a past perfect tense
so that it means the same as the first.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

       <div style='display:flex'> 
            <div><b>1</b></div> 
              <div style='margin-left:15px;width:25cm'>
                It was months since he’d played tennis.<br>
                He # for months.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>2</b> </div>
              <div style='margin-left:15px;width:25cm'>
                She ate her dinner and then went to bed.<br>
                After she #, she went to bed.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>3</b></div> 
              <div style='margin-left:15px;width:25cm'>
                Zoe saved for ages and then bought a new watch.<br>
                Zoe # before she bought a new watch.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>4</b></div> 
              <div style='margin-left:15px;width:25cm'>
                Eric broke his arm so he couldn’t go to school.<br>
                Eric couldn’t go to school #.
              </div>
          </div>
         
            
            
          `,
        answer: [
          "hadn't played tennis",
          "had eaten her dinne",
          "had been saving for ages",
          "because he had broken his arm.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P42-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page42/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P42-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page42/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
