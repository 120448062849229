import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P51-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 400,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "adrenalin",
          "burn",
          "burst",
          "convert",
          "energy",
          "high-intensity",
          "pump",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the passive sentences with the correct form of
the modal verbs and verbs in brackets.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        

        <div style='width:27cm'>
           <b>1</b>&ensp;It isn’t true that sleepwalkers #(can’t / wake) safely.<br>
          <b>2</b>&ensp;It seems that more research into the new drug #(ought to / do) before it was made available.<br>
          <b>3</b>&ensp;Surely the results of the tests #(must / check) before they were published?<br>
          <b>4</b>&ensp;Our sleep #(can / disturb) by artificial lighting.<br>
          <b>5</b>&ensp;Perhaps not all health myths #(should / dismiss) completely.<br>
          <b>6</b>&ensp;We #(might / lie) to for decades by the drinks industry about how much water we need.<br>
          <b>7</b>&ensp;Some health advice #(may / misunderstand) in the past.<br>
          <b>8</b>&ensp;Do you think most smokers #(could / encourage) to quit by using e-cigarettes?<br>
        </div>
          `,
        answer: [
          "can't be woken|can not be woken",
          "ought to have been done",
          "must have been checked",
          "can be disturbed",
          "should be dismissed",
          "might have been lied",
          "may have been misunderstood",
          "could be encouraged",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P51-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 300, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "attend",
          "be",
          "beat",
          "do",
          "go",
          "keep",
          "lift",
          "pedal",
          "push",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the text with the correct passive infinitive or
<i>-ing</i> form of the verbs in brackets.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `     
              <div style='width:26cm'>
                  Imagine <sup>1</sup>#(wake up) by a member of your
                  family to discover that you have not only got out of bed and
                  turned on your computer while asleep, but have emailed your
                  friends without knowing it. Apparently, cases of
                  ‘zzz-mailing’ are starting <sup>2</sup>#(report)
                  more frequently, and experts say they expect
                  <sup>3</sup>#(tell) about even more of these strange
                  events. Robert Wood, who suffers from bizarre night-time
                  wanderings, desperately hopes <sup>4</sup>#(cure)
                  after his wife, who didn’t expect <sup>5</sup>#(meet)
                  by such a worrying sight, found the Scottish chef cooking
                  chips in their kitchen while fast asleep! However, another sufferer,
                  nurse Lee Hadwin, definitely doesn’t want <sup>6</sup>#(prevent) from sleepwalking and admits <sup>7</sup>#(amaze) by his unconscious talents! He certainly deserves
                  <sup>8</sup>#(know) as a gifted artist after producing
                  amazing drawings on tablecloths, clothes and walls –
                  although it seems he has no such ability while he’s awake.

              </div>
              

            
            
          `,
        answer: [
          "being woken up",
          "to be reported",
          "to be told",
          "to be cured",
          "to be met",
          "to be prevented",
          "to being amazed|being amazed",
          "to be known",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P51-E3",
    // audio: "Audios/2.03.mp3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      marginLeft: 20,
      marginBottom: -20,
      width: 700,
      paddingTop: 2,
      resize: "none",
    },

    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      // padding: 0,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "25cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "awful",
          "delighted",
          "ecstatic",
          "exhausted",
          "fascinated",
          "hilarious",
          "miserable",
          "starving",
          "terrible",
          "terrified",
          "thrilled",
          "wonderful",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Rewrite the sentences in two ways using passive
structures. Begin with the words given.
`,
        width: "29cm",
        color: "black",
        left: 50,
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
            <div><b>1</b>&ensp;We know that too much sugar is bad for us.</div> 
              <div style='margin-left:25px'>
                It is <input width=1002px />. <br> Too much sugar <input  width=860px />.
              </div>
          
              <div><b>2</b>&ensp;They estimate that a third of babies born in the UK in 2013 have a life expectancy of 100. </div>
              <div style='margin-left:25px'>
                It is <input width=1000px />. <br> A third <input width=965px />.
              </div>
          
              <div><b>3</b>&ensp;They say that vitamin C is good for colds.</div> 
              <div style='margin-left:25px'>
                It is <input width=1000px />. <br> Vitamin C <input width=933px />.
              </div>
          
              <div><b>4</b>&ensp;People once thought that carrots were good for your eyesight.</div> 
              <div style='margin-left:25px'>
                It was once <input width=916px />. <br> Carrots were once <input width=837px />.
              </div>
          
              <div><b>5</b>&ensp;People believe that the ancient Egyptians were great doctors.</div> 
              <div style='margin-left:25px'>
                It is <input width=1001px />. <br> The ancient Egyptians <input width=797px />.
              </div>
          
              <div><b>6</b>&ensp;They say that the actor put on ten kilos for this film.</div> 
              <div style='margin-left:25px'>
                It is <input width=1001px />. <br> The actor <input width=934px />.
              </div>

          `,
        answer: [
          "known that too much sugar is bad for us",
          "is known to be bad for us",
          "estimated that a third of babies born in the UK in 2013 have a life expectancy of 100",
          "of babies born in the UK in 2013 are estimated to have a life expectancy of 100",
          "said that vitamin C is good for colds",
          "is said to be good for colds",
          "thought that carrots were good for your eyesight",
          "thought to be good for your eyesight",
          "believed that the ancient Egyptians were great doctors",
          "are believed to have been great doctors",
          "said that the actor put on ten kilos for this film",
          "is said to have put on ten kilos for this film",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P51-E4",
    // audio: "Audios/2.04.mp3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the text with one word in each gap
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 200,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page51/E4/1.jpg" style='height:18cm'/>
          <div style='position:absolute;top:73px;left:229px'><input width=162px /></div>
          <div style='position:absolute;top:115px;left:327px'><input width=202px /></div>
          <div style='position:absolute;top:242px;left:213px'><input width=162px /></div>
          <div style='position:absolute;top:327px;left:205px'><input width=162px /></div>
          <div style='position:absolute;top:370px;left:436px'><input width=162px /></div>
          <div style='position:absolute;top:497px;left:207px'><input width=162px /></div>
        </div>

        
            
            
          `,

        answer: ["being", "It", "been", "have", "is", "be"],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P51-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      marginLeft: 10,
      width: 900,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 800,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["date", "enjoy", "find", "not imagine", "live", "star", "think"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Look at the task and the photos below. Then read the
questions (1–4) and write notes.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
                <b>1</b>&ensp;What is the common theme of the photos? <textarea id=0 rows=2></textarea>
                <b>2</b>&ensp;What can you see in both photos? <textarea id=1 rows=2></textarea>
                <b>3</b>&ensp;What is the main difference between the photos? <textarea id=2 rows=2></textarea>
                <b>4</b>&ensp;Do you think celebrities should spend more time with their fans? Why? / Why not? <textarea id=3 rows=2></textarea>
           
        </div>

        <img src="img/FriendsPlus/Page51/E1/1.jpg" style='height:18cm'/>
        
            
            
          `,

        answer: [
          "The common theme is celebrities and their fans.",
          "There are celebrities and lots of fans in both photos. Some of the fans have pens and paper for autographs. Both celebrities have sunglasses.",
          "In photo B there are lots of phones and a girl is taking a selfie. The celebrity is a man, whereas in photo A it's a woman.",
          "",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P51-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      // padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Now do the task using your notes from exercise 5.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    questions: [
      {
        title: `

         
            
            
          `,
        answer: [
          "hadn't played tennis",
          "had eaten her dinne",
          "had been saving for ages",
          "because he had broken his arm.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P51-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P51-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
