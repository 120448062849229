import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P71-E1",
    // audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page71/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 350,
      paddingTop: 2,
      resize: "none",
    },
    maxLength: 1,
    stylesTextInput: { marginLeft: 20, width: 700, paddingTop: 7 },
    hintBox: [
      {
        src: ["bonds", "touch", "time", "commitment", "isolation"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the task and the report below. How many measures
does the writer:
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: /[,|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `<img src="img/FriendsPlus/Page71/E1/1.jpg" style='height:5cm'/>
          <div style='display:flex;justify-content:space-between'>
            <div><b>a</b>&ensp;assess? <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /> <textarea id=2 rows=3></textarea></div>
            <div><b>b</b>&ensp;suggest? <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /> <textarea id=3 rows=3></textarea></div>
          </div>

          <div style='padding:10px 20px;width:24cm;box-shadow:2px 3px 3px 6px gray'>
              <b><u>Introduction</u></b><br>
The aim of this report is to assess the measures taken by the
council and to put forward recommendations for further
action.
        <br><b><u>Benefits of the measures taken</u></b><br>
According to most residents, the new pedestrian zone
is a great success. It was also thought that the bike lanes
improved road safety. The coach park on the outskirts of
the town has also met with approval, as coaches no longer
drive into the centre where the streets are very narrow.
        <br><b><u>Disadvantages of the measures</u></b><br>
The increase in parking charges has discouraged people
from driving into town, but local shops and businesses are
suffering because fewer people come into town to shop.
What is more, it would seem that these charges adversely
affect disabled people and elderly people who rely on their
cars.
        <br><b><u>Suggestions for future improvements</u></b><br>
The majority of residents are of the opinion that there are
not enough open green spaces. In my opinion, it would
therefore be worth creating a new park on the wasteland
near the station. I would suggest that more free parking
places for disabled and elderly people are created. In
conclusion, the council has made a good start, but a lot
more could be done.
          </div>
            
            
          

        
        
          
          `,
        InputRong: true,
        answer: [
          "4",
          "2",
          "|new pedestrian zone, bike lanes, new coach park, increase in parking charges",
          "|create a new park, more free parking for disabled and elderly people",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P71-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 700, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "26cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "It is recommended that …",
          "It was reported that …",
          "It was thought that …",
          "On balance, …",
          "Taking all the points into consideration, …",
          "This report is intended to …",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Put the phrases in the correct groups.
Which are in the article?`,
        width: "26cm",
        color: "black",
        left: 50,
        prefix: [{ text: "KEY PHRASES", icon: "", color: "gray" }],
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style=' border-left:5px solid gray;padding-left:10px'>
              <b>Introduction</b><br>
              The aim of this report is to …<br>
              The report is based on / draws on …<br>
              <sup>1</sup>#<br>
              <b>Reporting views</b><br>
              It would seem that …<br>
              According to …<br>
              (People) were of the opinion that …<br>
              <sup>2</sup>#<br>
              <sup>3</sup>#<br>
              Summing up / Giving your opinion<br>
              In conclusion, … To conclude, … To sum up, …<br>
              <sup>4</sup>#<br>
              <sup>5</sup>#<br>
              <b>Recommending / Suggesting</b><br>
              I would suggest …<br>
              In my opinion, it would be worth …<br>
              <sup>6</sup>#<br>
       </div>

        
        
          
          `,
        // InputRong: true,
        answer: [
          "This report is intended to …",
          "It was thought that … / It was reported that …",
          "It was reported that … / It was thought that …",
          "On balance, … / Taking all the points into consideration, …",
          "Taking all the points into consideration, … / On balance, …",
          "It is recommended that …",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P71-E3",
    // audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page71/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // margin: "0px 10px",
      background: "none",
      width: 700,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#2385C6'>Writing Strategy</span>. and the task below. Make
notes under the headings.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
       
          <img src="img/FriendsPlus/Page71/E3/1.jpg" style='height:5cm'/><br>

          <b>Your opinion of measures taken</b><br>
          <b>1</b>#<br>
          <b>2</b>#<br>
          <b>3</b>#<br>
          <b>Suggestions for further improvements</b><br>
          <b>1</b>#<br>
          <b>2</b>#<br>
        
          
          `,
        // InputRong: true,
        answer: [
          "I suggest that your online booking system be reviewed.|I request that there be bottled water available in extremely hot conditions.",
          "I suggest that your online booking system be reviewed.|I request that there be bottled water available in extremely hot conditions.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P71-E4",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page71/Key/E4answerKey.png",
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Write your report.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 700, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <textarea id=0 rows=10></textareaa>
          `,
        answer: [
          "I suggest that you review the booking procedure.",
          "I recommend that you announce delays.",
          "I propose you delay your decision.|I propose that you delay your decision.",
          "I demand that my ticket be refunded.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P71-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page71/Key/E5answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 50, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "15cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "itinerary missed out an important place",
          "tour didn’t run on time",
          "tour guide was unenthusiastic / too quiet ",
          "no toilet on the bus",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page71/E5/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:111px;left:78px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:157px;left:78px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:202px;left:78px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
  7: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P71-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page71/Key/E7answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Pag719/E7/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:113px;left:78px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:159px;left:78px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:204px;left:78px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
  6: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P71-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page71/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Write your letter of complaint.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <textarea id=0 rows=7></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
