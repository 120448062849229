import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P10-E1",
    audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page10/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { marginLeft: 20, width: 800, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=1.03 color=white colorimg='#d1b4d8'></headphone> Read the <span style='color:#ca2a5a'>Listening Strategy</span>. Listen to a student
talking about his sister. Answer the questions.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page10/E1/1.jpg" style='height:8cm'/>
          <div style='width:26cm'>
              <b>1</b>&ensp;Rarely (so / she / felt / unhappy / had).<br>#<br>
              <b>2</b>&ensp;Nowhere (find / will / you / a / friend / better).<br>#<br>
              <b>3</b>&ensp;Only (argued / have / ever / once / we).<br>#<br>
              <b>4</b>&ensp;In no (did / to / upset / I / anyone / way / mean)!<br>#<br>
              <b>5</b>&ensp;At (should / lies / tell / time / no / you).<br>#<br>
          </div>
          
          `,
        answer: [
          "She behaved like an angel.",
          "He was energetic and noisy.",
          "Why can't you be more like your sister?",
          "He sometimes hated her.",
          "When he got better at school and people stopped comparing them.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P10-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page10/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderRadius: 5,
      border: "2px solid",
      marginLeft: 20,
      background: "none",
      width: 40,
      height: 40,
      paddingTop: 8,
    },
    maxLength: 1,
    // stylesTextInput: { width: 150, paddingTop: 3 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adore",
          "envy",
          "look down on",
          "look up to",
          "respect",
          "trust",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the features of fast speech (a–c) with examples
(1–3).`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `


          <div style='width:27cm'>
            <div style='display:flex;line-height:50px'>
                <div>
                  <b>a</b>&ensp;<i>Tell everybody sounds like Tell leverybody.</i><br>
                  <b>b</b>&ensp;<i>That’s my … sounds like Thas my …</i><br>
                  <b>c</b>&ensp;<i>So early sounds like so wearly; my uncle sounds like my yuncle</i><br>
                </div>
                <div>
                  #<br>
                  #<br>
                  #<br>
                </div>
            </div>
            <b>1</b>&ensp;Consonants are sometimes inserted between two vowel sounds.<br>
            <b>2</b>&ensp;Consonant sounds at the end of words run into a following vowel sound.<br>
            <b>3</b>&ensp;Sounds, usually consonants, sometimes disappear.<br>
          </div>
          
          
          `,
        answer: ["2", "3", "1"],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P10-E3",
    audio: "Audios/1.04.mp3",
    exerciseKey: "img/FriendsPlus/Page10/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { marginLeft: 20, width: 600, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.04 color=white colorimg='#d1b4d8'></headphone> Listen to the sentences from the text. Write
down one or two features of fast speech that you hear in
each.`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
              <b>1</b>&ensp;My sister was born with big, blue eyes …<br>#<br>
              <b>2</b>&ensp;She looked like an angel.<br>#<br>
              <b>3</b>&ensp;This was not good news for me.<br>#<br>
              <b>4</b>&ensp;Sometimes I pushed her away.<br>#<br>
              <b>5</b>&ensp;Later, when I got better at school …<br>#<br>
          </div>
          
          `,
        answer: [
          "My sister was born with big, blue eyes. 1",
          "She looked like an angel. 2",
          "This was no good news for me. 3|This was not good news for me. 3",
          "Sometimes I pushed her away. 3",
          "Later, when I go better. 4|Later, when I got better. 4",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P10-E4",
    audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page10/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.05 color=white colorimg='#d1b4d8'></headphone> Listen to two dialogues and a monologue.
Choose the correct answers (a–c).`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["about (x3)", "for (x2)", "on"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "1-4", "2-8"],
        initialValue: [],
      },
      Layout: `



            <b>1</b>&ensp;How does the first dialogue end?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>The speakers refuse to listen to each other’s point of view. <br> The speakers eventually come to an acknowledgment of each other’s viewpoints. <br> The speakers decide that the disagreement can’t be resolved.</div>
                 </div>
            <b>2</b>&ensp;What caused the speaker of the monologue to feel happier during her childhood?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>Her parents had triplets. <br> A relative moved in with them. <br> Her brothers spent more time playing with her.</div>
                 </div>
            <b>3</b>&ensp;In the second dialogue, what do Linda and Cathy tell the interviewer?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>How their parents’ divorce affected them emotionally. <br> How Cathy continually annoyed Linda. <br> What the main cause of problems between them was.</div>
                 </div>
            
          
           
            
        `,
    },
  },
  5: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P10-E5",
    audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page10/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      // marginBottom: -70,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { marginLeft: 20, width: 600, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["flatter", "insult", "nag", "praise", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=1.06 color=white colorimg='#d1b4d8'></headphone> Listen and write the sentences from the listening.
Pay attention to the features of fast speech from exercise 2.
Then practise saying the sentences.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
         <div style='width:25cm'>
         <div style='display:flex'> 
            <b>1</b> 
              <textarea id=0 rows=2 ></textarea>
          </div>
          <div style='display:flex'>
              <b>2</b> 
              <textarea id=1 rows=2 ></textarea>
          </div>
          <div style='display:flex'>
              <b>3</b> 
              <textarea id=2 rows=2 ></textarea>
          </div>
          <div style='display:flex'>
              <b>4</b> 
              <textarea id=3 rows=2 ></textarea>
          </div>
         
          
        </div>
          `,
        answer: [
          "You're always acting the yolder brother and it's soannoying.",
          "That's cos I yam the yolder brother ran I'm looking outfor you.",
          "You can nimagine what my mum thought when thedoctor told her the news!",
          "I was two wand da half when the triplets were born an Ithink it was a real problem for me.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P10-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page10/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 185,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with the correct form of the verbs below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
          <div style='position:relative'>
            <img src="img/FriendsPlus/Page10/E6/1.jpg" style='height:18cm'/>
            <div style='position:absolute;top:122px;left:48px;width:179mm'>
              Well, it’s been a bit of a strange day. My mum <br> <sup>1</sup>#
              me off this morning for arriving home late last night, but then
              she <sup>2</sup># me for my good marks in the test! On
              the bus to school, my best mate Harry kept <sup>3</sup>#
              me about lending him my mountain bike. He went on and on
              about it for the whole journey! Once at school, everyone made
              jokes and  <sup>4</sup># me about my new haircut. Well,
              everyone except for Chloe! She <br> <sup>5</sup># me on my
              ‘stylish’ hair and said it looked great! But then I realised that
              I’d left my English homework at home. My English teacher
              <sup>6</sup># me not to forget my homework again and
              gave me extra work as a punishment!
            </div>
            
            
          </div>
          
          
          `,
        answer: [
          "told",
          "praised",
          "nagging",
          "teased",
          "complimented",
          "warned",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P10-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page10/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
