import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P35-E1",
    // audio: "Audios/1.15.mp3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 40,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "cover your mouth",
          "cross yourlegs",
          "give a thumbs up point",
          "scratch your head",
          "shrug your shoulders",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct options (a–c) to complete the text.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "1-0", "2-4", "3-4", "5-8", "4-0"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page35/E2/1.jpg" style='height:28cm'/>

                      <div style='display:flex'>  
                      <div style='margin-right:200px'>  
                      <div style='display:flex'>  
                          <b>1</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=0 type=Circle ></div>
                                <div style='margin-left:10px'>you’re arriving <br> you arrive <br> you’ll be arriving</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>2</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=1 type=Circle ></div>
                                <div style='margin-left:10px'>you’ve had <br> you’re having <br> you’ll be having</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>3</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=2 type=Circle ></div>
                                <div style='margin-left:10px'>you’ll have met <br> you’ve met <br> you’ll be meeting</div>
                            </div>
                          </div>
                          </div>
                          <div>
                          
          <div style='display:flex'>
                            <b>4</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=3 type=Circle ></div>
                                <div style='margin-left:10px'>you don’t work <br> you aren’t working <br> you haven’t worked</div>
                            </div>
          </div>
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=4 type=Circle ></div>
                <div style='margin-left:10px'>you’re staying <br> you’ll be staying <br> you’ll have stayed</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>you’ll have left <br> you’ll be leaving <br> you leave</div>
             </div>
          </div>
          </div>
          </div>
        `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P35-E2",
    // audio: "Audios/1.16.mp3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E2answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 300, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["bite", "frown", "grin", "nod", "shake", "yawn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Choose the best verb form to complete the first conditional
sentences.
`,
        color: "black",
        left: -20,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "they_will_have / they_are_having",
          "I_get / I’m_getting",
          "wait / you’ll_wait",
          "won’t_be_finishing / won’t_have_finished",
          "goes / will_go",
          "lend / have_lent",
          "a <br> b <br> c",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
      
      <div style='width:26cm'>
          <b>1</b>&ensp;If they go to Amsterdam,  <input id=0 type=Circle /> a great time.<br>
          <b>2</b>&ensp;If you’re buying a new outfit for the party,  <input id=1 type=Circle /> one too.<br>
          <b>3</b>&ensp;If you get out of school before me,  <input id=2 type=Circle /> for me at the bus stop.<br>
          <b>4</b>&ensp;If we don’t hurry up, we  <input id=3 type=Circle /> this exercise by the end of the lesson.<br>
          <b>5</b>&ensp;If he gets good grades, my brother  <input id=4 type=Circle /> to university.<br>
          <b>6</b>&ensp;If this DVD’s any good, I’ll  <input id=5 type=Circle /> it to you.<br>
      </div>
        `,
    },
  },
  3: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P35-E3",
    // audio: "Audios/1.22.mp3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 350,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the text with the correct form of
the verbs in brackets.

`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    character: /[/,]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
           <div style='width:26cm'>
           
            Hi Otto <br>
            I can’t wait to see you – I can’t believe
            that by the time I <sup>1</sup>#(see)
            you, I <sup>2</sup>#(be) France for a
            month! As soon as you <sup>3</sup>#(arrive), we’ll plan where to go, but can
            you bring a sleeping bag in case we
            <sup>4</sup>#(decide) to go camping?
            Tomorrow, I <sup>5</sup>#(visit) my
            cousins in Paris, so it’s a shame we’ll
            have to wait until Saturday to meet,
            but I should be there the minute your
            train <sup>6</sup>#(get) in. Just
            think: this time on Saturday, we
            <sup>7</sup>#(talk) to each other face
            to face! Lots of love until then. <br>
            Sam
           </div>
            
          `,
        // InputRong: true,
        answer: [
          "see",
          "'ll have been / will have been",
          "have arrived/ 've arrived / arrive",
          "decide",
          "am visiting / 'm visiting / will be visiting / 'll be visiting",
          "gets",
          "will be talking / 'll be talking",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P35-E4",
    audio: "Audios/1.18.mp3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the second sentence so that it
means the same as the first.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 530, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `






       <div style='display:flex'> 
            <div><b>1</b></div> 
              <div style='margin-left:15px;width:25cm'>
                I won’t take suncream unless you do.<br>
                If you don’t # either.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>2</b> </div>
              <div style='margin-left:15px;width:25cm'>
                It may rain later, so take an umbrella.<br>
                Take an umbrella in #.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>3</b></div> 
              <div style='margin-left:15px;width:25cm'>
                I’ll finish packing and then I’ll call a cab.<br>
                After # call a cab.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>4</b></div> 
              <div style='margin-left:15px;width:25cm'>
                You won’t hear from me again until I’m in London.<br>
                By # me again, I’ll be in London.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>5</b></div> 
              <div style='margin-left:15px;width:25cm'>
                He can’t go unless he finds his passport soon.<br>
                If # soon, he won’t be able to go.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>6</b></div> 
              <div style='margin-left:15px;width:25cm'>
                We can’t share a room if you snore!<br>
                We can share a room on condition #!
              </div>
          </div>
            
            
          `,
        answer: [
          "take suncream, I won't",
          "case it rains later",
          "I've finished packing, I'll / I've finished packing, I will / I have finished packing, I'll / I have finished packing, I will / I finish packing, I'll / I finish packing, I will",
          "the time you hear from",
          "he does not find his passport / he doesn't find his passport",
          "that you do not snore|that you don't snore|you don't snore|you do not snore|(that) you don't snore ",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P35-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 100, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["able", "might", "mustn’t", "needn’t", "should (x2)", "supposed"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the text with the words below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: ",",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `

      <div style='display:flex;line-height:47px'>
      <div style='margin-right:100px'>
            <b>1</b>&ensp;I have a short time.<br>
            <b>2</b>&ensp;Could you close the door?<br>
            <b>3</b>&ensp;I got to the train in time.<br>
            <b>4</b>&ensp;It’s amazing!<br>
            <b>5</b>&ensp;He has left.<br>
            <b>6</b>&ensp;We have to buy milk now.<br>
            <b>7</b>&ensp;This film’s so funny!<br>
            <b>8</b>&ensp;Could you repeat your name? <br>
        </div>
        <div>
        <b>a</b>&ensp;for emphasis # <br>
        <b>b</b>&ensp;to mean only # <br>
        <b>c</b>&ensp;for politeness # <br>
        <b>d</b>&ensp;to mean close to the time # <br>
        </div>
        </div>

          `,
        answer: ["4, 7", "1, 6", "2, 8", "3, 5"],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P35-E6",
    audio: "Audios/1.23.mp3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name=1.23 color=white colorimg='#d1b4d8'></headphone> Listen to the conversation. Are the sentences true
(T) or false (F)?`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div style='display:flex;margin-bottom:20px;line-height:50px'>
               <div>
                 <b>1</b>&ensp;The conversation is happening in a restaurant. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>2</b>&ensp;John reacts to both of Ella’s suggestions with decisiveness. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>3</b>&ensp;Ella would prefer to spend the day sightseeing. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>4</b>&ensp;John is worried the bus might be too hot. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>5</b>&ensp;Ella’s attitude is that you should make the most of a trip away <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 
               </div>
              
             
            </div>

            
            
          `,
        // InputRong: true,
        answer: ["F", "F", "T", "T", "T"],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P35-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P35-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
