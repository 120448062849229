import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P32-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page32/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "adventure holiday",
          "backpacking",
          "beach holiday",
          "beach house",
          "caravan",
          "cruise",
          "guest house",
          "holiday home",
          "self-catering apartment",
          "surfing",
          "tent",
          "time-share apartment",
          "volunteering",
          "winter sports holiday",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Put the words below in the correct column.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
       <table style="width: 20cm; border-collapse: collapse;">
        <tr>
            <td style="width: 10cm;background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">Holiday accommodation</td>
            <td style="width: 10cm;background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">Types of holidays</td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><textarea id=0 rows=6></textarea></td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><textarea id=1 rows=6></textarea></td>
        </tr>
        
    </table>

        
            
            
          `,
        answer: [
          "beach house, caravan, guest house, holiday home, self-catering apartment, tent, time-share apartment",
          "adventure holiday, backpacking, beach holiday, cruise, surfing, volunteering, winter sports holiday",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P32-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page32/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 350, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "B&B",
          "hotel",
          "house swap",
          "self-catering apartment",
          "villa",
          "youth hostel",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the holiday accommodation below with the
sentences.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
       <div style='display:flex'> 
            <div><b>1</b></div> 
              <div style='margin-left:15px;width:25cm'>
                ‘The website said it had four stars, but the room was
                really small and there wasn’t any Wi-Fi. If you wanted to use the internet, you had to go and use some computers in the lobby!’#
              </div>
          </div>
          <div style='display:flex'>
              <div><b>2</b> </div>
              <div style='margin-left:15px;width:25cm'>
                ‘We were really lucky because their flat was right in the
                centre of Rome. It also had a lovely balcony with views of the square. I think they were happy with ours because it’s only fifteen minutes from Trafalgar Square on the underground.’#
              </div>
          </div>
          <div style='display:flex'>
              <div><b>3</b></div> 
              <div style='margin-left:15px;width:25cm'>
                ‘Although it was on the coast, it also had a lovely
                swimming pool and a big terrace where we sat in the evenings. There were four bedrooms and enough room for all the family.’#
              </div>
          </div>
          <div style='display:flex'>
              <div><b>4</b></div> 
              <div style='margin-left:15px;width:25cm'>
                ‘It was cheap, but we had to share a room with six
                other people who we didn’t know. One guy spent the whole night talking in his sleep and kept me awake!’#
              </div>
          </div>
          <div style='display:flex'>
              <div><b>5</b></div> 
              <div style='margin-left:15px;width:25cm'>
                ‘The room was fine and the breakfast was enormous. We
                didn’t need to have lunch!’#
              </div>
          </div>
          <div style='display:flex'>
              <div><b>6</b></div> 
              <div style='margin-left:15px;width:25cm'>
                ‘It seemed like a good idea at first because we could eat
                when we wanted, but we spent a lot of time shopping in supermarkets for food.’#
              </div>
          </div>
            
            
          `,
        answer: [
          "hotel",
          "house swap",
          "villa",
          "youth hostel",
          "B&B",
          "self-catering apartment",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P32-E3",
    audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page32/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 200,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.21 color=white colorimg='#d1b4d8'></headphone> Listen to Alex and Jessica choosing a holiday.
Underline the four types of holiday they mention. Which
holiday do they choose to go on?
`,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 500 },
        answers: ["They decide to go on a cycling holiday."],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 30px",
          // border: "2px solid",
          color: "#92278f",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 30px",
          textDecoration: "underline",
          // borderRadius: "50%",
          color: "#92278f",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [
          `city_break cycling_holiday houseboat_holiday
house_swap package_holiday volunteering_holiday`,
        ],
        answers: ["0-8", "0-2", "0-6", "0-10"],
        initialValue: [],
      },
      Layout: `


      <div style='width:26cm'>
        <input id=0 type=Circle />
      </div>
      <b>Answer:</b>#
     

        
        `,
    },
  },
  4: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P32-E4",
    audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page32/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.21 color=white colorimg='#d1b4d8'></headphone> Listen again and complete the sentences with the
adjectives below.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 200, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
 
 
        <hintbox id=0></hintbox>
 
              <div style='width:26cm'>
                <b>1</b>&ensp;Jessica doesn’t think anyone will want to come to their hometown because it is #.<br>
                <b>2</b>&ensp;Jessica doesn’t like Alex’s second suggestion because the holiday destination will be # with tourists and very #.<br>
                <b>3</b>&ensp;Alex doesn’t like Jessica’s suggestion because he thinks the accommodation will be # and the place #.<br>
                <b>4</b>&ensp;They finally decide to visit the west of Ireland, which is #.<br>
                
              </div>
             
             
            
            
          `,
        // InputRong: true,
        answer: [
          "dull",
          "crowded",
          "commercialised",
          "run-down",
          "inaccessible",
          "unspoilt",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P32-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page32/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "breathtaking",
          "dingy",
          "hospitable",
          "remote",
          "unique",
          "vibrant",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Replace the underlined words with the adjectives below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            <b>1</b>&ensp;The view from the top of the mountain is <u>extremely beautiful</u>. #<br>
            <b>2</b>&ensp;Our hotel room was <u>dark and dirty</u>. #<br>
            <b>3</b>&ensp;The village is quite <u>far away</u>, but it’s worth going there as it’s so beautiful and unspoiled. #<br>
            <b>4</b>&ensp;The people are really <u>friendly and helpful</u>. #<br>
            <b>5</b>&ensp;New York is an incredibly <u>energetic and exciting</u> city. #<br>
            <b>6</b>&ensp;This coastal town has a <u>special, unusual</u> atmosphere that will be different from anything you’ve experienced. #<br>
        </div>

          `,
        answer: [
          "breathtaking",
          "dingy",
          "remote",
          "hospitable",
          "vibrant",
          "unique",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P32-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page32/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the email with the words below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
              <img src="img/FriendsPlus/Page32/E6/1.jpg" style='height:15cm'/>
              <div style='position:absolute;top:129px;left:408px'>#</div>
              <div style='position:absolute;top:164px;left:46px'>#</div>
              <div style='position:absolute;top:198px;left:437px'>#</div>
              <div style='position:absolute;top:233px;left:94px'>#</div>
              <div style='position:absolute;top:267px;left:199px'>#</div>
              <div style='position:absolute;top:336px;left:46px'>#</div>
           
        </div>

          `,
        answer: [
          "easy",
          "pamper",
          "physically",
          "adventure",
          "batteries",
          "experiences",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P32-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page32/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P32-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page32/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
