import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P40-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page40/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 159,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "ambitious",
          "inflexible",
          "optimistic",
          "empathetic",
          "creative",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words to complete the passage.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
              <img src="img/FriendsPlus/Page40/E1/1.jpg" style='height:16cm'/>
              <div style='position:absolute;top:67px;left:454px'>#</div>
              <div style='position:absolute;top:173px;left:87px'>#</div>
              <div style='position:absolute;top:208px;left:415px'>#</div>
              <div style='position:absolute;top:314px;left:36px'>#</div>
              <div style='position:absolute;top:349px;left:456px'>#</div>
              
           
        </div>

        
            
            
          `,
        answer: [
          "empathic",
          "inflexible",
          "ambitious",
          "creative",
          "optimistic ",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P40-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page40/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 350, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "bad-tempered",
          "bossy",
          "considerate",
          "creative",
          "cruel",
          "outgoing",
          "quick-witted",
          "selfless",
          "self-satisfied",
          "spontaneous",
          "untrustworthy",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Put the adjectives below in the correct columns.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
      <table style="width: 80%; border-collapse: collapse;">
        <tr>
            <td style="background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">Positive</td>
            <td style="background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">Negative</td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"> <textarea id=0 rows=4 ></textarea> </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"> <textarea id=1 rows=4 ></textarea> </td>
        </tr>
    </table>
            
            
          `,
        answer: [
          "considerate, creative, outgoing, quick-witted, selfless, spontaneous",
          "bad-tempered, bossy, cruel, self-satisfied, untrustworthy",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P40-E3",
    audio: "Audios/1.25.mp3",
    exerciseKey: "img/FriendsPlus/Page40/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 200,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.25 color=white colorimg='#d1b4d8'></headphone> Listen to four descriptions. Match the adjectives
below with descriptions (1–4). There are two extra
adjectives. 
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
 
 
        <hintbox id=0></hintbox>
 
              <div style='display:flex'>
                  <div style='margin-right:100px'>
                    <b>Speaker 1</b>#<br>
                    <b>Speaker 2</b>#<br>
                  </div>
                  <div>
                    <b>Speaker 3</b>#<br>
                    <b>Speaker 4</b>#<br>
                  </div>
              </div>
             
             
            
            
          `,
        // InputRong: true,
        answer: ["judgemental", "vain", "spontaneous", "stubborn"],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P40-E4",
    audio: "Audios/1.25.mp3",
    exerciseKey: "img/FriendsPlus/Page40/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.25 color=white colorimg='#d1b4d8'></headphone> Listen again and choose the correct answers
(a–c).

`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 200, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-0", "1-8", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `

            <b>1</b>&ensp;The speaker thinks that Kevin is ____.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>untrustworthy <br> bossy <br> considerate</div>
                 </div>
            <b>2</b>&ensp;Olivia’s behaviour suggests she could be ____.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>cruel <br> cautious <br> insecure</div>
                 </div>
            <b>3</b>&ensp;The speaker suggests that his grandmother is ____.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>judgemental <br> sympathetic <br> shrewd</div>
                 </div>
            <b>4</b>&ensp;When people disagree with Max, he becomes ____.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>bad-tempered <br> cautious <br> cruel</div>
                 </div>
           
              
        `,
    },
  },
  5: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P40-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page40/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 100, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Replace the underlined words with the adjectives below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            <b>1</b>&ensp;The students are always #-behaved in their English class.<br>
            <b>2</b>&ensp;Amber isn’t very #-minded and she doesn’t like trying new things.<br>
            <b>3</b>&ensp;Sean is usually quite #-working, but he didn’t study a lot for these exams.<br>
            <b>4</b>&ensp;He’s #-witted and he gave fast answers to the questions in the test.<br>
            <b>5</b>&ensp;You need to be #-skinned to be a politician.<br>
            <b>6</b>&ensp;Max is #-confident, but he knows talent is not enough to succeed.<br>
        </div>

          `,
        answer: ["well", "open", "hard", "quick", "thick", "self"],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P40-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page40/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the sentences with compound adjectives.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            <b>1</b>&ensp;We went to a restaurant with Andy last night and he was rude and unpleasant to everyone! He’s always been b#-m# .<br>
            <b>2</b>&ensp;You’d have to be very t#-s# not to be upset by his insensitive remarks.<br>
            <b>3</b>&ensp;She devotes all her time to her training. She’s very ambitious and s#-m# when it comes to achieving her goals.<br>
            <b>4</b>&ensp;We saw a romantic comedy at the theatre last weekend which was fun and quite l#-h# .<br>
            <b>5</b>&ensp;Lily was very s#-c# before the exam because she’d studied a lot and knew she would do well.<br>
            <b>6</b>&ensp;I’ve met quite a few relaxed people in my life, but I’ve never known anyone as e#-g# as Mark.<br>
        </div>
          
    `,
        answer: [
          "ad",
          "annered",
          "hick",
          "kinned",
          "ingle",
          "inded",
          "ight",
          "earted",
          "elf",
          "onfident",
          "asy",
          "oing",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P40-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page40/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P40-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page40/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
