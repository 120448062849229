import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Cumulative Review 1",
    id: "12WB-2024-CR1-P72-E1",
    audio: "Audios/2.19.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E1answerKey.png",
    component: Circle_Write,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 159,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.19 color=white colorimg='#d1b4d8'></headphone> Listen to a radio programme about therapy dogs.
Choose the correct answers (a–d).
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-12", "1-4", "2-8", "3-0", "4-8"],
        initialValue: [],
      },
      Layout: `







            <b>1</b>&ensp;Why does the presenter use the quote about animals at the start of the programm?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>To show how affectionate they are. <br> To explain how considerate they are. <br> To illustrate how ignorant they are. <br> To emphasise how trusting they are.</div>
                 </div>
            <b>2</b>&ensp;According to Freya Wilcox, Alzheimer’s patients are generally
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>easy-going. <br> insecure. <br> thick-skinned. <br> vain.</div>
                 </div>
            <b>3</b>&ensp;During the therapy dogs’ visits, the patients tend to be
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>more sympathetic. <br> hungrier. <br> more upbeat. <br> more single-minded.</div>
                 </div>
            <b>4</b>&ensp;To be a therapy dog, an animal must
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>have the right personality. <br> know a lot of people. <br> be wary of strange noises. <br> understand body language.</div>
                 </div>
            <b>5</b>&ensp;Why do the dogs go home at night?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>In order to spend some time with their trainers. <br> So as not to catch an illness from the patients. <br> To be clean and rested for the next day’s work. <br> So that they don’t disturb the patients.</div>
                 </div>
            
           
              
        `,
    },
  },
  2: {
    unit: "Cumulative Review 1",
    id: "12WB-2024-CR1-P72-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E2answerKey.png",
    component: T6,
    recorder: true,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 400, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Work in pairs. You want to give an elderly relative a pet
to keep him or her company. Say which of these pets you
would choose and why. Say why you are rejecting the
other option.
`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
             
          `,
        answer: [
          "I had never met",
          "he wouldn't be exhausted",
          "if they had studied harder",
          "You would be poor",
          "she wouldn't be burned now",
          "I knew your address",
        ],
      },
    ],
  },
  3: {
    unit: "Cumulative Review 1",
    id: "12WB-2024-CR1-P72-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      marginLeft: 25,
      width: 500,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Match texts (A–C) with questions (1–6) below. Some
texts match with more than one question. 
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>Which meeting …</b>
        <div style='width:24cm'>
              <div>
                 <b>1</b>&ensp;was held in order for an important question to be asked? <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>2</b>&ensp;lasted for less than an hour? <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>3</b>&ensp;involved people of different nationalities? <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>4</b>&ensp;was organised privately? <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>5</b>&ensp;involved the exchange of gifts? <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>6</b>&ensp;resulted in a long-term association between the participants? <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 
                 </div>
              <div style='margin-top:20px'>
                 <span style='margin-right:15px;border:2px solid; padding:0px 5px;border-radius:5px'>A</span>
                    On 4 May 1904, a
                    meeting was held
                    between two British
                    businessmen that resulted
                    in one of the most famous
                    automobile partnerships in
                    history. After purchasing a car
                    whose performance he was dissatisfied
                    with, Frederick Henry Royce had succeeded in creating
                    his own model that gave the occupants a quieter and
                    smoother ride. One of these vehicles was purchased
                    by Royce’s colleague Henry Edmunds, who was friends
                    with a car dealer in London called Charles Stewart Rolls.
                    Edmunds showed Rolls his new car and arranged for
                    Rolls and Royce to meet over lunch at the Midland Hotel
                    in Manchester. The two men began doing business
                    together, and on 15 March 1906, Rolls-Royce Limited
                    was born. Unfortunately, the partnership ended rather
                    abruptly four years later when Rolls was killed in a
                    plane crash. However, the company continued, and
                    the Rolls-Royce went on to become arguably one
                    of the best cars in the world.
                 <span style='margin-right:15px;border:2px solid; padding:0px 5px;border-radius:5px'>B</span>
                    On 17 July 1975, a meeting occurred that a few years
                    earlier would have been unthinkable. On that day, US
                    astronaut Thomas Stafford and Russian cosmonaut Alexei
                    Leonov exchanged the first international handshake in
                    space. The Apollo–Soyuz Test Project, named after the
                    two spacecraft involved, was a joint venture organised
                    by the US and Soviet governments to demonstrate the
                    improved relations between the two nations. The Apollo
                    and the Soyuz were launched on 15 July within seven
                    and a half hours of each other, and they met over France,
                    where the two spacecraft joined. They stayed connected
                    for nearly two days, long enough for the three Americans
                    and two Soviets to visit each other’s ships, conduct
                    experiments together, share meals and converse in each
                    other’s languages. One of the gifts they exchanged was
                    a bag of tree seeds which were later planted in the two
                    countries.
                 <span style='margin-right:15px;border:2px solid; padding:0px 5px;border-radius:5px'>C</span>
                    On 27 March 1997, a rather awkward meeting took place
                    before the press in a Tennessee state prison hospital.
                    The participants were two men: Dexter King, son of the
                    murdered civil rights activist Martin Luther King, and
                    James Earl Ray, the man who had been convicted of the
                    assassination. King was 36 when the meeting happened,
                    and Ray was 69 and very sick – he had to be taken to the
                    meeting room in a wheelchair. During the 25 minutes the
                    two men spent together, King asked Ray if he had killed
                    King’s father. Ray, who confessed to the killing
                    at the time of his arrest, but then withdrew
                    his confession, replied that he hadn’t,
                    to which King responded, ‘I believe
                    you.’ Whether Ray was the killer
                    or the murder was a government
                    conspiracy will always remain
                    a mystery because Ray died
                    of liver disease a year later in
                    prison.

              </div>
           
        </div>
          `,
        answer: ["C", "C", "B", "A", "B", "A"],
      },
    ],
  },
  4: {
    unit: "Cumulative Review 1",
    id: "12WB-2024-CR1-P72-E4",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginTop: 20,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the email with one word in each gap to make
mixed conditional sentences.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      fontSize: 23,
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 166,
      padding: 0,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page72/E4/1.jpg" style='height:14cm'/>
          <div style='position:absolute;top:129px;left:641px'>#</div>
          <div style='position:absolute;top:162px;left:280px'><input width=179px /></div>
          <div style='position:absolute;top:196px;left:44px'><input width=179px /></div>
          <div style='position:absolute;top:231px;left:152px'><input width=179px /></div>
          <div style='position:absolute;top:299px;left:224px'><input width=179px /></div>
          <div style='position:absolute;top:368px;left:393px'><input width=179px /></div>
          
         
         
         
        </div>
        `,
        answer: ["have", "wasn't / weren't", "hadn't", "wouldn't", "be", "had"],
      },
    ],
  },
  5: {
    unit: "Cumulative Review 1",
    id: "12WB-2024-CR1-P72-E5",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,

      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the text with the correct form of
the verbs in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: /[|/]/,
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `

        <div style='width:26cm'>
          Oxfam is a world leader in delivering emergency
          relief. If it weren’t for this charity, thousands of
          children in Europe <sup>1</sup>#(go) hungry
          during World War II. Today, desperate people
          <sup>2</sup>#(not have) clean water if Oxfam hadn’t made
          this a priority during humanitarian disasters. And if it
          <sup>3</sup>#(not be) for Oxfam, the NGO ActionAid might
          not exist either; there wouldn’t be so much pressure on
          governments to provide healthcare and education and to
          combat climate change if these charities <sup>4</sup>#(not develop). 
          But Oxfam might not be so successful nowadays if
          it <sup>5</sup>#(not recognise) the importance of linking
          business with charity. Oxfam employs thousands of people
          worldwide, but it couldn’t have become the UK’s biggest
          high-street seller of second-hand books if it <sup>6</sup>#(not be) 
          also for the thousands of volunteers who give their
          time working in these important charity shops.

         
          
         
         
         
        </div>
        `,
        answer: [
          "would have gone",
          "would not have|wouldn't have",
          "had not been|hadn't been",
          "had not developed|hadn't developed",
          "had not recognised / did not recognise|hadn't recognised / didn't recognise",
          "was not / were not|wasn't / weren't",
        ],
      },
    ],
  },
  6: {
    unit: "Cumulative Review 1",
    id: "12WB-2024-CR1-P72-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 176,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthful"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with one word in each gap.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page72/E6/1.jpg" style='height:15cm'/>
          <div style='position:absolute;top:46px;left:318px'>#</div>
          <div style='position:absolute;top:152px;left:99px'>#</div>
          <div style='position:absolute;top:223px;left:250px'>#</div>
          <div style='position:absolute;top:293px;left:210px'>#</div>
          <div style='position:absolute;top:364px;left:247px'>#</div>
          <div style='position:absolute;top:435px;left:266px'>#</div>
         
         
         
        </div>
        `,
        answer: ["it", "One / The", "what", "One", "all", "one"],
      },
    ],
  },
  7: {
    unit: "Cumulative Review 1",
    id: "12WB-2024-CR1-P72-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Cumulative Review 1",
    id: "12WB-2024-CR1-P72-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
