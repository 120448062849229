import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Introduction",
    id: "12WB-2024-I-P4-E1",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page4/Key/E1answerKey.png",
    component: Circle_Write,
    // textAlign:'center',

    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct verbs to complete the sentences.`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "wondered / insisted / encouraged",
          "blamed / denied / managed",
          "reminded / persuaded / agreed",
          "mention / spend / tell",
        ],
        answers: ["0-8", "1-4", "2-8", "3-0"],
        initialValue: [],
      },
      Layout: `
            <div style='line-height:47px'>
                  <b>1</b>&ensp;Our teacher <input id=0 type=Circle /> us to take part in the contest.<br>
                  <b>2</b>&ensp;The student <input id=1 type=Circle /> taking the money from his classmate.<br>
                  <b>3</b>&ensp;We all <input id=2 type=Circle /> to meet in town at 8 p.m.<br>
                  <b>4</b>&ensp;Did you <input id=3 type=Circle /> the idea of going out for a pizza to Jessica?<br>
            </div>
                  
          
        `,
    },
  },
  2: {
    unit: "Introduction",
    id: "12WB-2024-I-P4-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page4/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 150,
      paddingTop: 8,
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct infinitive or <i>-ing</i>
form of the verbs in brackets.`,
        color: "black",
        left: -20,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='position:relative'>
            <img src="img/FriendsPlus/Page4/E2/1.jpg" style='height:20cm'/>
            <div style='position:absolute;top:387px;left:503px'>#</div>
            <div style='position:absolute;top:452px;left:48px'>#</div>
            <div style='position:absolute;top:484px;left:468px'>#</div>
            <div style='position:absolute;top:549px;left:123px'>#</div>
            <div style='position:absolute;top:549px;left:458px'>#</div>
            <div style='position:absolute;top:614px;left:154px'>#</div>
            <div style='position:absolute;top:646px;left:116px'>#</div>
            <div style='position:absolute;top:679px;left:196px'>#</div>
          </div>
          
          `,
        answer: [
          "thinking",
          "to buy",
          "regretting",
          "to do",
          "looking",
          "to give",
          "to offer",
          "to get",
        ],
      },
    ],
  },
  3: {
    unit: "Introduction",
    id: "12WB-2024-I-P4-E3",
    exerciseKey: "img/FriendsPlus/Page4/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      border: "2px solid",
      width: 40,
      height: 40,
      borderRadius: 5,
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Match (1–6) with (a–f) to make sentences.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
                <div>
                  <b>1</b>&ensp;Kate always begs<br>
                  <b>2</b>&ensp;Jake blamed<br>
                  <b>3</b>&ensp;Anna suggested<br>
                  <b>4</b>&ensp;This ice cream reminds<br>
                  <b>5</b>&ensp;Tom won’t<br>
                  <b>6</b>&ensp;Our neighbour didn’t thank us<br>
                </div>
                <div style='margin-left:10px;margin-right:100px'>
                  #<br>#<br>#<br>#<br>#<br>#<br>
                </div>
                <div>
                  <b>a</b>&ensp;revising for the exams together.<br>
                  <b>b</b>&ensp;for finding his cat.<br>
                  <b>c</b>&ensp;his sister for their late arrival.<br>
                  <b>d</b>&ensp;mind waiting for us at the bus station.<br>
                  <b>e</b>&ensp;me to help her with her homework.<br>
                  <b>f</b>&ensp;me of our holiday in Italy.<br>
                </div>
            </div>
        
        `,
        answer: ["e", "c", "a", "f", "d", "b"],
      },
    ],
  },
  4: {
    unit: "Introduction",
    id: "12WB-2024-I-P4-E4",
    // audio: "Audios/2-09.mp3",
    exerciseKey: "img/FriendsPlus/Page4/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the correct form of the verbs below.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 3 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "accuse",
          "apologise",
          "beg",
          "blame",
          "insist",
          "thank",
          "want",
          "warn",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='line-height:45px'>
          <b>1</b>&ensp;Amy and Ewan don’t # to go to the concert.<br>
          <b>2</b>&ensp;Our parents # us against swimming in the river when we were on holiday.<br>
          <b>3</b>&ensp;Chloë # on using her mobile phone in the cinema even though she was asked not to.<br>
          <b>4</b>&ensp;Alex didn’t # for arriving late.<br>
          <b>5</b>&ensp;We # them to take us on holiday.<br>
          <b>6</b>&ensp;Lucy always # other people for her problems.<br>
          <b>7</b>&ensp;I # Tom for helping me with my homework.<br>
          <b>8</b>&ensp;They # the students of lying.<br>
        </div>
        `,
        answer: [
          "want",
          "warned",
          "insisted",
          "apologise",
          "begged",
          "blames",
          "thanked",
          "accused",
        ],
      },
    ],
  },
  5: {
    unit: "Introduction",
    id: "12WB-2024-I-P4-E5",
    // audio: "Audios/2-10.mp3",
    exerciseKey: "img/FriendsPlus/Page4/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with <i>to do</i> or <i>doing</i>.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          
         <div style='line-height:45px'>
          <b>1</b>&ensp;He admitted # badly in the exam.<br>
          <b>2</b>&ensp;Lucy has always enjoyed # exercise.<br>
          <b>3</b>&ensp;My dad persuaded me # the course.<br>
          <b>4</b>&ensp;The organisers thanked us for # the clearing up.<br>
          <b>5</b>&ensp;Mark insisted on # his homework alone.<br>
          <b>6</b>&ensp;Amy has promised # the washing-up.<br>
        </div>
          `,
        answer: ["doing", "doing", "to do", "doing", "doing", "to do"],
      },
    ],
  },
  6: {
    unit: "Introduction",
    id: "12WB-2024-I-P4-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page4/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 350,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='box-shadow:5px 3px 4px gray;border-radius:15px; background:rgb(239,229,240);padding:10px 30px;width:24cm'>
            I prefer <sup>1</sup># on holiday in the winter rather than in the
            summer, so I’ll never forget <sup>2</sup># in the mountains
            on a skiing holiday last year. I was really excited when it started
            <sup>3</sup># during our first night at the hotel. However, the
            following morning, the snow hadn’t stopped <sup>4</sup># .
            The coach from the hotel tried <sup>5</sup># us to the ski
            station, but the road was in a dangerous condition. The snow on
            the road had started <sup>6</sup># ice and we had to return to
            the hotel. I remember <sup>7</sup># my time looking out of the
            hotel window, hoping that they would clear the road!
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  7: {
    unit: "Introduction",
    id: "12WB-2024-I-P4-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page4/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 200,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: ["to explain", "to take", "scoring", "to have"],
      },
    ],
  },
};

export default json;
