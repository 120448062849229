import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P17-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 200, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the comparative or
superlative form of the adverb in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        
        <div style='width:26cm'>
          <b>1</b>&ensp;Who works #(hard) out of all the students in the class?<br>
          <b>2</b>&ensp;There isn’t much traffic today, so we should arrive #(soon) than we expected.<br>
          <b>3</b>&ensp;If you ate #(slowly), you’d enjoy your food more!<br>
          <b>4</b>&ensp;Erik did #(badly) than I did in this month’s test.<br>
          <b>5</b>&ensp;My grandparents’ house isn’t far, but my aunt lives #(near) of all my family.<br>
        </div>
          
          
          `,
        answer: [
          "the hardest|hardest",
          "sooner",
          "more slowly",
          "worse",
          "the nearest|nearest",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P17-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E2answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 40, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "get over",
          "not make up",
          "not turn up",
          "put through",
          "talk into",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the two halves of the comparative sentences.`,
        color: "black",
        left: -20,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "461px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "461px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "461px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "461px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "461px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "461px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "461px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "353px",
          //     left: "461px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // },
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "400px",
          //     left: "461px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "705px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "705px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "705px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "705px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "705px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "705px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "705px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "705px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "705px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: ["0-10", "1-13", "2-8", "3-7", "4-9", "11-5", "12-6"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "do_you_usually_do / are_you_usually_doing",
          "I’m_seeing / I_see",
          "We’re_learning / We_learn",
          "does_the_World_Cup_Final_start / is_the_World_Cup_Final_starting",
          "Does_your_aunt_work / Is_your_aunt_working",
          "is_always_using / always_uses",
          "Are_you_going / Do_you_go",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
         <input id=0 type=boxMatch />
         <input id=1 type=boxMatch />
<input id=2 type=boxMatch />
<input id=3 type=boxMatch />
<input id=4 type=boxMatch />
<input id=5 type=boxMatch />
<input id=6 type=boxMatch />
<input id=7 type=boxMatch />
<input id=8 type=boxMatch />
<input id=9 type=boxMatch />
<input id=10 type=boxMatch />
<input id=11 type=boxMatch />
<input id=12 type=boxMatch />
<input id=13 type=boxMatch />

            <div style='line-height:47px;display:flex'>
                  <div style='margin-right:300px'>
                    <b>1</b>&ensp;He’s far more handsome in real life<br>
                    <b>2</b>&ensp;The harder you work,<br>
                    <b>3</b>&ensp;I spent more this week<br>
                    <b>4</b>&ensp;The later you leave tonight,<br>
                    <b>5</b>&ensp;The sooner we book,<br>
                    <b>6</b>&ensp;This ice cream is less tasty<br>
                    <b>7</b>&ensp;Our cat’s getting bigger and bigger<br>
                    
                  </div>
                  <div>
                    <b>a</b>&ensp;the colder it will be.<br>
                    <b>b</b>&ensp;than I’d wanted to.<br>
                    <b>c</b>&ensp;the cheaper the tickets will be.<br>
                    <b>d</b>&ensp;than on TV.<br>
                    <b>e</b>&ensp;than it looks.<br>
                    <b>f</b>&ensp;the more we feed it!<br>
                    <b>g</b>&ensp;the better you’ll do.<br>
                   
                  </div>
            </div>
                  
          
        `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P17-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      marginLeft: 20,
      background: "none",
      width: 700,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Write the words in the correct order to make comparative
sentences.`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `




         <div style='width:25cm'>
              <b>1</b>&ensp;that / one / than / is / This / expensive / cap / more / slightly<br>#<br>
              <b>2</b>&ensp;do / far / than / My / scarier / I / finds / brother / spiders<br>#<br>
              <b>3</b>&ensp;lot / cycling / energetic / Walking / is / than / less / a <br>#<br>
              <b>4</b>&ensp;little / planned / Her / than / a / she’d / shopping / took / longer <br>#<br>
              <b>5</b>&ensp;news / you / surprised / I / the / less / than / was / no / at<br>#<br>
          </div>
          
          
          `,
        answer: [
          "This cap is slightly more expensive than that one.",
          "My brother finds spiders far scarier than I do.",
          "Walking is a lot less energetic than cycling.",
          "Her shopping took a little longer than she'd planned.",
          "I was no less surprised at the news than you.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P17-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Choose the correct answers (a–c) to complete the
sentences.
`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { width: 300, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-8", "1-4", "2-0", "3-4", "4-8", "5-4"],
        initialValue: [],
      },
      Layout: `     

            <b>1</b>&ensp;A quarter is much as 25%.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>nowhere near as <br> almost as <br> just as</div>
                 </div>
            <b>2</b>&ensp;A second is long as a minute.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>not quite as <br> nothing like as <br> nearly as</div>
                 </div>
            <b>3</b>&ensp;Eleven forty-five p.m. is late as midnight.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>nearly as <br> nowhere near as <br> just as</div>
                 </div>
            
          
            <b>4</b>&ensp;Ninety-eight cents is much as a euro.
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                      <div>just as <br> not quite as <br> nothing like as</div>
                  </div>
            <b>5</b>&ensp;Spain is cold as Iceland.
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                      <div>nearly as <br> almost as <br> nowhere near as</div>
                  </div>
            <b>6</b>&ensp;The UK is big as Russia.
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=5 type=Circle /></div>
                      <div>almost as <br> nothing like as <br> not quite as</div>
                  </div>
              
           
            
        `,
    },
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P17-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 658, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the second sentence so that it means the same
as the first
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `



         <div style='width:25cm'>
         <div style='display:flex'> 
            <b>1</b> 
              <div style='margin-left:15px'>
                That was the worst holiday I’d ever had!<br>
                I’d never had a holiday as <input width=399px /> one!
              </div>
          </div>
          <div style='display:flex'>
              <b>2</b> 
              <div style='margin-left:15px'>
                If we walk fast, we’ll get there quicker.<br>
                The <input width=526px /> we’ll get there.
              </div>
          </div>
          <div style='display:flex'>
              <b>3</b> 
              <div style='margin-left:15px'>
                Fast food used to be less healthy.<br>
                Fast food is <input width=479px /> used to be.
              </div>
          </div>
          <div style='display:flex'>
              <b>4</b> 
              <div style='margin-left:15px'>
                My jacket is nothing like as comfortable as yours.<br>
                Your jacket is far <input width=480px /> mine.
              </div>
          </div>
          <div style='display:flex'>
              <b>5</b> 
              <div style='margin-left:15px'>
                Your brother is the same age as me.<br>
                Your brother is just <input width=459px /> I am.
              </div>
          </div>
          <div style='display:flex'>
              <b>6</b> 
              <div style='margin-left:15px'>
                Indian food is far spicier than English food.<br>
                English food is nothing <input width=340px /> Indian food.
              </div>
          </div>
          
          
        </div>
          `,
        answer: [
          "bad as that",
          "faster we walk, the quicker",
          "healthier than it / not as unhealthy as it",
          "more comfortable than",
          "as old as",
          "like as spicy as",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P17-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 149,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with the words below.`,
        color: "black",
        left: 70,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page17/E6/1.jpg" style='height:20cm'/>
          <div style='position:absolute;top:123px;left:45px'>#</div>
          <div style='position:absolute;top:154px;left:414px'>#</div>
          <div style='position:absolute;top:217px;left:185px'>#</div>
          <div style='position:absolute;top:248px;left:133px'>#</div>
          <div style='position:absolute;top:280px;left:164px'>#</div>
          <div style='position:absolute;top:343px;left:517px'>#</div>
          <div style='position:absolute;top:406px;left:118px'>#</div>
          <div style='position:absolute;top:469px;left:119px'>#</div>
        </div>
        
          `,
        answer: [
          "nowhere",
          "like",
          "than",
          "more",
          "fewer",
          "far",
          "as",
          "the",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P17-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Write your essay.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P17-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` `,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page17/E8/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:111px;left:80px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:157px;left:80px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:201px;left:80px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
};

export default json;
