import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P28-E1",
    // audio: "Audios/1.15.mp3",
    exerciseKey: "img/FriendsPlus/Page28/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 150,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "cover your mouth",
          "cross yourlegs",
          "give a thumbs up point",
          "scratch your head",
          "shrug your shoulders",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Write the American English equivalents of the words in bold.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
               <div style='width:27cm'>
                 <b>1</b>&ensp;Why don’t you borrow my <b>mobile</b> to call your mum? #<br>
                 <b>2</b>&ensp;I’ll have to buy a new pair of <b>trainers</b> soon; these are almost worn out. #<br>
                 <b>3</b>&ensp;Don’t forget to take a <b>torch</b> when we go camping; it’ll be dark at night. #<br>
                 <b>4</b>&ensp;Taking the <b>motorway</b> will make our journey quicker. #<br>
                 <b>5</b>&ensp;Would you like a <b>biscuit</b> with your tea? #<br>
                 <b>6</b>&ensp;If everyone’s finished, I’ll ask the waiter for the <b>bill</b>. #<br>
                 <b>7</b>&ensp;Her dog’s very well trained and always walks on the <b>pavement</b>. #<br>
                 <b>8</b>&ensp;I’m too tired to walk up the stairs; let’s take the <b>lift</b>. #<br>
               </div>
            
            
          `,
        answer: [
          "cell phone",
          "sneakers",
          "flashlight",
          "highway",
          "cookie",
          "check",
          "sidewalk",
          "elevator",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P28-E2",
    // audio: "Audios/1.16.mp3",
    exerciseKey: "img/FriendsPlus/Page28/Key/E2answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 350, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["bite", "frown", "grin", "nod", "shake", "yawn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Circle the American English spellings.
`,
        color: "black",
        left: -20,
        width: "27cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "licence / license",
          "neighbourhood / neighborhood",
          "airplane / aeroplane",
          "theater / theatre",
          "colors / colours",
          "organization / organisation",
        ],
        answers: ["0-4", "1-4", "2-0", "3-0", "4-0", "5-0"],
        initialValue: [],
      },
      Layout: `
      <div style='width:26cm'>
          <b>1</b>&ensp;In some US states, you only need to be fourteen to hold a driver’s  <input id=0 type=Circle /> .<br>
          <b>2</b>&ensp;In the  <input id=1 type=Circle /> of Beverly Hills, many homes are worth over $10 million.<br>
          <b>3</b>&ensp;The world’s first commercial flight took place in 1914; the  <input id=2 type=Circle /> flew for 23 minutes.<br>
          <b>4</b>&ensp;New York’s oldest  <input id=3 type=Circle /> is the Lyceum, which opened in 1903.<br>
          <b>5</b>&ensp;The  <input id=4 type=Circle /> of the US flag are the same as Australia’s and the UK’s.<br>
          <b>6</b>&ensp;The international Erasmus  <input id=5 type=Circle /> has already helped over three million students study in different countries.<br>
      </div>

            
              
        `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P28-E3",
    audio: "Audios/1.19.mp3",
    exerciseKey: "img/FriendsPlus/Page28/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      background: "none",
      // borderBottom: "none",
      // width: 144,
      // height: 30,
      // padding: 0,
      // fontSize: 23,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["can’t", "could (x2)", "may", "must", "should"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.19 color=white colorimg='#d1b4d8'></headphone> Listen to the different words being pronounced.
Write A (American) or B (British).`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div style='display:flex;margin-bottom:20px;line-height:50px'>
               <div>
                 <b>1</b>&ensp;advertisement<br>
                 <b>2</b>&ensp;brochure<br>
                 <b>3</b>&ensp;garage<br>
                 <b>4</b>&ensp;tomato<br>
                 <b>5</b>&ensp;vitamins<br>
                 <b>6</b>&ensp;yoghurt<br>
                 <b>7</b>&ensp;zebra<br>
               </div>
              <div style='margin:0px 50px'>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
              </div>
             
            </div>

            
            
          `,
        // InputRong: true,
        answer: ["A", "B", "B", "A", "B", "A", "A"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P28-E4",
    // audio: "Audios/1.18.mp3",
    exerciseKey: "img/FriendsPlus/Page28/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#92278f'>Vocab boost!</span> box.  Label the columns <i>American</i>
and <i>British</i>. Then complete the equivalent spellings. Add
examples from exercise 3 and more of your own.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 200, background: "none", paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <img src="img/FriendsPlus/Page28/E4/1.jpg" style='height:7cm'/>
       <table style="width: 20cm; border-collapse: collapse;">
        <tr>
            <td style="width: 10cm;background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><sup>1</sup><input border-color=white color=white />English </td>
            <td style="width: 10cm;background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><sup>2</sup><input border-color=white color=white />English</td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">behaviour </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><sup>3</sup><input /></td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><sup>4</sup><input /> </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">cancelation</td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">centimetre </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><sup>5</sup><input /></td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><sup>6</sup><input /> </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">liter</td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">memorise </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><sup>7</sup><input /></td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><sup>8</sup><input /> </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">marvelous</td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"><sup>9</sup><input /> </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">savory</td>
        </tr>
    </table>
          `,
        answer: [
          "British",
          "American",
          "behavior",
          "cancellation",
          "centimeter",
          "litre",
          "memorize",
          "marvellous",
          "savoury",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P28-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page28/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { marginLeft: 20, width: 950, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "25cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "could",
          "didn’t need to",
          "needn’t",
          "ought not to",
          "was supposed to",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Rewrite the American English sentences in British English
using the correct vocabulary and spelling. Use a dictionary
to help you.

`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;It’s an offense to smoke on the subway.<br>#<br>
            <b>2</b>&ensp;I bought this awesome purse for my friend’s birthday.<br>#<br>
            <b>3</b>&ensp;It seems tonight’s movie has been canceled.<br>#<br>
            <b>4</b>&ensp;Can you get me a liter of water from the faucet, please?<br>#<br>
            <b>5</b>&ensp;There’s a strange odor from the car – I hope the gas isn’t leaking.<br>#<br>
            <b>6</b>&ensp;Sorry, I hadn’t realized you were waiting in the line.<br>#<br>
          </div>
          `,
        answer: [
          "It's an offence to smoke on the underground.|It is an offence to smoke on the underground.",
          "I bought this great / fantastic / marvellous, etc. handbag for my friend's birthday.",
          "It seems tonight's film has been cancelled.",
          "Can you get me a litre of water from the tap, please?",
          "There's a strange odour from the car - I hope the petrol isn't leaking.|There is a strange odour from the car - I hope the petrol isn't leaking.|There is a strange odour from the car - I hope the petrol is not leaking.|There's a strange odour from the car - I hope the petrol is not leaking.",
          "Sorry, I had not realised you were waiting in the queue.|Sorry, I hadn't realised you were waiting in the queue.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P28-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page28/Key/E6answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 850,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      marginLeft: 20,
      width: 850,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Rewrite the sentences using the words in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       
        <div style='width:26cm'>
          <b>1</b>&ensp;Lunch was provided so it wasn’t necessary for them to take sandwiches. (need)<br>#<br>
          <b>2</b>&ensp;She studied hard so she probably passed the exam. (should)<br>#<br>
          <b>3</b>&ensp;I think he’s foreign so it’s possible he didn’t understand you. (might)<br>#<br>
          <b>4</b>&ensp;John isn’t in Rome – I saw him earlier. (can’t)<br>#<br>
          <b>5</b>&ensp;It would have been better if you’d listened to my advice. (ought)<br>#<br>
          <b>6</b>&ensp;Why didn’t you introduce me to your friends? (might)<br>#<br>
        </div>
          `,
        answer: [
          "Lunch was provided so they didn't need to take sandwiches.",
          "She studied hard so she should have passed the exam.",
          "I think he's foreign so he might not have understood you.",
          "John can't be in Rome - I saw him earlier.",
          "You ought to have listened to my advice.",
          "You might have introduced me to your friends!",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P28-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page28/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P28-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page28/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
