import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Introduction",
    id: "12WB-2024-I-P7-E1",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page7/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 7 },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the past simple or past
continuous form of the verb in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
          <div style='width:26cm'>
              <div style='display:flex'>
              <b>1</b>
                  <div style='width:25cm; margin-left:15px'>
                    I #(have) a shower when the postman #(arrive).
                  </div>
              </div>
              <div style='display:flex'>
                  <b>2</b>
                  <div style='width:25cm; margin-left:15px'>
                    Sandra #(drop) her bag while she #(run) for the bus.
                  </div>
              </div>
              <div style='display:flex'>
                  <b>3</b>
                  <div style='width:25cm; margin-left:15px'>
                    He #(read) the letter when he suddenly #(start) to laugh.
                  </div>
              </div>
              <div style='display:flex'>
                  <b>4</b>
                  <div style='width:25cm; margin-left:15px'>
                    The sun #(shine), so we #(decide) to have a barbecue.
                  </div>
              </div>
              <div style='display:flex'>
                  <b>5</b>
                  <div style='width:25cm; margin-left:15px'>
                    She #(hurt) her finger while she #(cut) some bread.
                  </div>
              </div>
              <div style='display:flex'>
                  <b>6</b>
                  <div style='width:25cm; margin-left:15px'>
                    Pat #(live) in Greece when he #(meet) Hannah.
                  </div>
              </div>
              
          </div>
          
          `,
        answer: [
          "was having",
          "arrived",
          "dropped",
          "was running",
          "was reading",
          "started",
          "was shining",
          "decided",
          "hurt",
          "was cutting",
          "was living",
          "met",
        ],
      },
    ],
  },
  2: {
    unit: "Introduction",
    id: "12WB-2024-I-P7-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page7/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 200,
      paddingTop: 8,
    },
    // stylesTextInput: { width: 150, paddingTop: 3 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "25cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "be",
          "complete",
          "decide",
          "finish",
          "get",
          "go",
          "lose",
          "not",
          "know",
          "pass",
          "put",
          "start",
          "take",
          "tell",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the past simple or past
perfect form of the verbs below.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
          <div style='width:26cm'>
              <b>1</b>&ensp;After we # eating, we # to go to the shops.<br>
              <b>2</b>&ensp;By the time they # at the theatre, the play # .<br>
              <b>3</b>&ensp;He only realised he # his keys when he # to his front door.<br>
              <b>4</b>&ensp;Jerry # delighted because he # all his exams.<br>
              <b>5</b>&ensp;When everyone # a chocolate, she # the box away.<br>
              <b>6</b>&ensp;They # out for a pizza after they # the project.<br>
              <b>7</b>&ensp;I # about the accident because no one # me about it.<br>
              
          </div>
          
          `,
        answer: [
          "had finished",
          "decided",
          "arrived",
          "had started",
          "had lost",
          "got",
          "was",
          "had passed",
          "had taken",
          "put",
          "went",
          "had completed",
          "didn't know",
          "had told",
        ],
      },
    ],
  },
  3: {
    unit: "Introduction",
    id: "12WB-2024-I-P7-E3",
    exerciseKey: "img/FriendsPlus/Page7/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      border: "2px solid",
      width: 40,
      height: 40,
      borderRadius: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct verbs to complete the sentences.`,
        color: "black",
        left: -20,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "worked / has_worked",
          "left / has_left",
          "called / has_called",
          "has_decided / decided",
          "never_drove / has_never_driven",
          "bought / have_bought",
          "didn’t_wear / haven’t_worn",
          "haven’t_heard / didn’t_hear",
          "spilled / have_spilled",
          "I_broke / <br> I’ve_broken",
        ],
        answers: [
          "0-4",
          "1-0",
          "2-0",
          "3-0",
          "4-4",
          "5-0",
          "6-4",
          "7-0",
          "8-0",
          "9-6",
        ],
        initialValue: [],
      },
      Layout: `
            <div style='width:25cm'>
                  <b>1</b>&ensp;My father <input id=0 type=Circle > as a policeman since he <input id=1 type=Circle > school.<br>
                  <b>2</b>&ensp;Linda <input id=2 type=Circle > me this morning. She <input id=3 type=Circle > to come out with us later.<br>
                  <b>3</b>&ensp;Tom <input id=4 type=Circle > a car because he’s not old enough.<br>
                  <b>4</b>&ensp;I <input id=5 type=Circle > a new shirt at the weekend, but I <input id=6 type=Circle > it yet.<br>
                  <b>5</b>&ensp;Pablo loves Sam Smith’s new album, but I <input id=7 type=Circle > it.<br>
                  <b>6</b>&ensp;I <input id=8 type=Circle > water on my phone earlier; I think <input id=9 type=Circle > it.<br>
            </div>
                  
        `,
    },
  },
  4: {
    unit: "Introduction",
    id: "12WB-2024-I-P7-E4",
    // audio: "Audios/2-09.mp3",
    exerciseKey: "img/FriendsPlus/Page7/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the dialogue with the present perfect simple or
continuous form of the verbs in brackets.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "emigrate",
          "inherit",
          "learn",
          "to",
          "drive",
          "retire",
          "settle",
          "down",
          "split",
          "up",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

    <div style='display:flex'>
        <div>
            <b>Eve</b><br><br>
            <b>Will</b><br><br>
            <b>Eve</b><br>
            <b>Will</b><br><br><br>
            <b>Eve</b><br><br><br>
            <b>Will</b><br>
        </div>
        <div style='margin-left:20px;width:20cm'>
                Hi, Will. <sup>1</sup>#(you finish) reading that
                magazine yet?
                <br> Not quite. I <sup>2</sup>#(read) this article
                about Paris. It’s really interesting.
                <br> <sup>3</sup>#(you visit) France, then?
                <br> Lots of times – we <sup>4</sup>#(go) there on
                holiday for years. Recently, my aunt and uncle
                <sup>5</sup>#(buy) a house there so we’ll go
                even more often.
                <br> Lucky you. I <sup>6</sup>#(never visit) another
                country. Anyway, I <sup>7</sup>#(make) a cake if
                you’d like a piece?
                <br> That’s really kind, but no thanks. I <sup>8</sup>#(eat) biscuits all morning and I’m full!
        </div>
    </div>
          
          `,
        answer: [
          "Have you finished",
          "have been reading",
          "Have you visited",
          "have been going",
          "have bought",
          "have never visited",
          "have made",
          "have been eating",
        ],
      },
    ],
  },
  5: {
    unit: "Introduction",
    id: "12WB-2024-I-P7-E5",
    // audio: "Audios/2-10.mp3",
    exerciseKey: "img/FriendsPlus/Page7/Key/E5answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -70,
      marginLeft: 20,
      width: 700,
      paddingTop: 3,
    },
    stylesTextInput: { width: 220, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "buy a house or flat",
          "get divorced",
          "get married",
          "go to university",
          "grow up",
          "pass away",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct verbs (a–c) to complete the text.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "✓ <br> ✓ <br> ✓",
          "",
          "",
        ],
        answers: ["0-4", "1-0", "2-8", "3-0", "4-8", "5-0", "6-0", "7-4"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page7/E5/1.jpg" style='height:10cm'/>
      <div style='display:flex'>  
        <div style='margin-right:200px'>  
        <div style='display:flex'>  
          <b>1</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=0 type=Circle ></div>
                <div style='margin-left:10px'>listened <br> was listening <br> had listened</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=1 type=Circle ></div>
                <div style='margin-left:10px'>started <br> had started <br> was starting</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=2 type=Circle ></div>
                <div style='margin-left:10px'>was losing <br> lost <br> had lost</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>4</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=3 type=Circle ></div>
                <div style='margin-left:10px'>didn’t have <br> hadn’t had <br> wasn’t having</div>
             </div>
          </div>
        </div>
        <div>
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=4 type=Circle ></div>
                <div style='margin-left:10px'>was starting <br> started <br> had started</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>began <br> had begun <br> was beginning</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>7</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=6 type=Circle ></div>
                <div style='margin-left:10px'>decided <br> was deciding <br> had decided</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>8</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=7 type=Circle ></div>
                <div style='margin-left:10px'>had waited <br> was waiting <br> waited</div>
             </div>
          </div>
          </div>
          </div>
          <div>
           

        `,
    },
  },
  6: {
    unit: "Introduction",
    id: "12WB-2024-I-P7-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page7/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "be",
          "buy",
          "career",
          "fall",
          "get",
          "job",
          "leave",
          "married",
          "settle",
          "split",
          "start",
          "study",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the sentences with the verbs in brackets. Use the correct past tense.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='line-height:47px;width:27cm'>
              <b>1</b>&ensp;‘Why is your face so red?’ ‘I <input width='400px' />(sit) in the sun all morning.’<br>
              <b>2</b>&ensp;I <input width='400px' />(try) to do this crossword all morning and I still can’t finish it.<br>
              <b>3</b>&ensp;They were driving to the sea when the car #(run out) of petrol.<br>
              <b>4</b>&ensp;After they’d woken up, they #(cook) a big breakfast.<br>
          </div>
          
          
          `,
        answer: [
          "'ve been sitting / have been sitting",
          "'ve been trying / have been trying",
          "ran out",
          "cooked",
        ],
      },
    ],
  },
  7: {
    unit: "Introduction",
    id: "12WB-2024-I-P7-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page7/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
