import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P43-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: ["be", "not have", "not use", "not want"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete each sentence with the correct form of used to
and the verbs below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
              <div style='width:26cm'>
                 <b>1</b>&ensp;She # married, but she isn’t married any more.<br>
                 <b>2</b>&ensp;He # short hair, but now it’s really short!<br>
                 <b>3</b>&ensp;In the past, people # social media, but now they use it all the time.<br>
                 <b>4</b>&ensp;‘You # a motorbike, did you?’ ‘No, but I want one now.’<br>
               </div>

          `,
        answer: [
          "used to be",
          "did not use to have|didn't use to have",
          "did not use to use|didn't use to use",
          "did not use to want|didn't use to want",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P43-E2",
    // audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E2answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 250, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cook", "learn", "look", "travel", "wait", "work"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct answers (a or b) to complete the text.`,
        color: "black",
        left: -20,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
        ],
        answers: ["0-4", "2-0", "1-4", "3-0", "4-0", "5-4", "6-0"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page43/E2/1.jpg" style='height:15cm'/>

                      <div style='display:flex'>  
                      <div style='margin-right:200px'>  
                      <div style='display:flex'>  
                          <b>1</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=0 type=Circle ></div>
                                <div style='margin-left:10px'>wouldn’t <br> didn’t use to</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>2</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=1 type=Circle ></div>
                                <div style='margin-left:10px'>would work <br> used to work</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>3</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=2 type=Circle ></div>
                                <div style='margin-left:10px'>used <br> would</div>
                            </div>
                          </div>
        <div style='display:flex'>
                            <b>4</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=3 type=Circle ></div>
                                <div style='margin-left:10px'>didn’t use to <br> wouldn’t</div>
                            </div>
          </div>
                          </div>
                          <div>
                          
          
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=4 type=Circle ></div>
                <div style='margin-left:10px'>spent <br> used to spend</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>wouldn’t <br> used to</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>7</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=6 type=Circle ></div>
                <div style='margin-left:10px'>would <br> used to</div>
             </div>
          </div>
          </div>
          </div>
        `,
    },
  },
  3: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P43-E3",
    // audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },

    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 250,
      // padding: 0,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the second sentence with <i>used to</i> so that it
means the same as the first.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `



       <div style='display:flex'> 
            <div><b>1</b></div> 
              <div style='margin-left:15px;width:25cm'>
                Ten years ago, this shopping centre wasn’t here.<br>
                Ten years ago, this shopping centre didn’t # here.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>2</b> </div>
              <div style='margin-left:15px;width:25cm'>
                I thought he once lived in New York.<br>
                Didn’t # in New York?
              </div>
          </div>
          <div style='display:flex'>
              <div><b>3</b></div> 
              <div style='margin-left:15px;width:25cm'>
                Wasn’t she a waitress in the past?<br>
                Didn’t # a waitress?
              </div>
          </div>
          <div style='display:flex'>
              <div><b>4</b></div> 
              <div style='margin-left:15px;width:25cm'>
                He’s only had a sports car since he became famous.<br>
                Before he became famous, he # a sports car.
              </div>
          </div>
         
            
            
          `,
        answer: [
          "use to be",
          "he use to live",
          "she use to be",
          "didn't use to have|did not use to have",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P43-E4",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the text with <i>used to</i> or would and the
verbs in brackets. Sometimes both are possible.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 400,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:27cm'>
              In the past, people <sup>1</sup>#(find)
              fame by being an actor, a sports star,
              a singer and so on. And even very
              talented people <sup>2</sup>#(know)
              that they’d only get a ‘big break’ by achieving
              something really special that brought them to
              the public’s attention. Before the rise of social
              media, people <sup>3</sup>#(not become)
              famous just for being famous.
              There <sup>4</sup>#(not be) celebrities
              like Justin Bieber, who rose to fame after
              posting videos of himself online. But
              there’s a downside – perhaps careers
              <sup>5</sup>#(not rise) so fast, but
              they also <sup>6</sup>#(not crash) so
              spectacularly; nowadays a damaging story or
              photo can go viral on the internet in minutes.
              
              
           
        </div>

        
            
            
          `,
        answer: [
          "used to find / would find",
          "used to know",
          "did not use to become / would not become / didn't use to become / wouldn't become",
          "did not use to be / didn't use to be",
          "did not use to rise / didn't use to rise",
          "did not use to crash / didn't use to crash",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P43-E5",
    // audio: "Audios/1.27.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 350,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["bite", "eat", "know", "make", "not think", "visit"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with <i>used to</i> or <i>would</i> and the
correct form of the verbs below. There may be more than
one possible answer.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            <b>1</b>&ensp;I # a lot of meat, but I prefer vegetarian food now.<br>
            <b>2</b>&ensp;We # my grandparents every week when I was a child.<br>
            <b>3</b>&ensp;Eva # about travelling the world until she went to Holland last year.<br>
            <b>4</b>&ensp;She # her fingernails, but she grew out of it.<br>
            <b>5</b>&ensp;My aunt # her own clothes when she was a student.<br>
            <b>6</b>&ensp;I # how to speak French, but I’ve forgotten now.<br>
              
           
        </div>
        
            
            
          `,
        // InputRong: true,
        answer: [
          "used to eat",
          "used to visit / would visit",
          "did not use to think / didn't use to think",
          "would bite / used to bite",
          "used to make / would make",
          "used to know",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P43-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E6answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 850,
      // padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      marginLeft: 20,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Correct the underlined mistakes in the sentences. Only
use the past simple when it is the only correct answer.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

       <div style=''> 
          <b>1</b>&ensp;Most of us <u>would believe</u> in Santa Claus when we were younger.<br>#<br>
          <b>2</b>&ensp;People <u>wouldn’t be</u> so obsessed with celebrities before the internet.<br>#<br>
          <b>3</b>&ensp;<u>Would you use</u> to enjoy eating vegetables as a child?<br>#<br>
          <b>4</b>&ensp;He <u>would study</u> engineering for two years before he became an actor.<br>#<br>
          <b>5</b>&ensp;<u>Would</u> famous actors use to earn so much money in the past?<br>#<br>
          </div>
         
            
            
          `,
        answer: [
          "Most of us used to believe in Santa Claus when we were younger.",
          "People didn't use to be so obsessed with celebrities before the internet.|People did not use to be so obsessed with celebrities before the internet.",
          "Did you use to enjoy eating vegetables as a child?",
          "He studied engineering for two years before he became an actor.",
          "Did famous actors use to earn so much money in the past? ",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P43-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P43-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
