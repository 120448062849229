import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P37-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 150,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: ["early", "friendly", "monthly", "motherly", "yearly"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the texts. Are the sentences true (T) or false (F)?
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

            <div style='width:26cm;margin-bottom:20px;line-height:50px'>
               <div>
                 <b>1</b>&ensp;In the past, adventure holidays were something that only the well-off could afford to do.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>2</b>&ensp;The crocodiles at Crocosaurus swim inside a glass cage that is lowered into the water.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>3</b>&ensp;The spa in Israel is overcrowded.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>4</b>&ensp;The visitors lie on their backs on the massage table.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>5</b>&ensp;You have to walk along the ‘Changong Zhando’ in order to get to the top of Mount Haushan.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>6</b>&ensp;The ‘Changong Zhando’ is through two mountains.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 
               </div>
               <img src="img/FriendsPlus/Page37/E1/3.jpg" style='height:17cm'/>
              <img src="img/FriendsPlus/Page37/E1/1.jpg" style='height:17cm'/><br>
              <img src="img/FriendsPlus/Page37/E1/2.jpg" style='height:22cm'/>
             
            </div>

            
            
          `,
        // InputRong: true,
        answer: ["T", "F", "F", "F", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P37-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { marginLeft: 20, width: 800, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "B&B",
          "hotel",
          "house swap",
          "self-catering apartment",
          "villa",
          "youth hostel",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#42429C'>Reading Strategy</span>. Match texts (A–C) with
questions (1–6) below. Each text may be matched with
more than one question.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page37/E2/1.jpg" style='height:7cm'/><br>
        Which text mentions …
       <div style='display:flex;margin-bottom:20px;line-height:50px'>
               <div>
                 <b>1</b>&ensp;a route that visitors have to take?<br>
                 <b>2</b>&ensp;unexpected sensations?<br>
                 <b>3</b>&ensp;the age of the facilities?<br>
                 <b>4</b>&ensp;the chance to reduce stress?<br>
                 <b>5</b>&ensp;protective equipment?<br>
                 <b>6</b>&ensp;losing weight?<br>
               </div>
              <div style='margin:0px 50px'>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
              </div>
             
            </div>
            <img src="img/FriendsPlus/Page37/E1/3.jpg" style='height:17cm'/>
              <img src="img/FriendsPlus/Page37/E1/1.jpg" style='height:17cm'/><br>
              <img src="img/FriendsPlus/Page37/E1/2.jpg" style='height:22cm'/>
          `,
        answer: ["C", "B", "C", "B", "A", "C"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P37-E3",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 200,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct adverbs (a or b) to complete the
sentences.
`,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
        ],
        answers: ["0-0", "1-0", "2-4", "3-4", "4-0"],
        initialValue: [],
      },
      Layout: `


            <b>1</b>&ensp;The dentist asked her to open her mouth  _____.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>wide <br> widely</div>
                 </div>
            <b>2</b>&ensp;Despite watching  _____, I don’t know how the magician did that!
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>closely <br> close</div>
                 </div>
            <b>3</b>&ensp;I haven’t seen you  _____– is everything ok?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>late <br> lately</div>
                 </div>
            <b>4</b>&ensp;The next train goes  _____ to London without stopping.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>direct <br> directly</div>
                 </div>
            <b>5</b>&ensp;Shall we sit  _____ to the door so we can leave quietly?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>close <br> closely</div>
                 </div>
              
        `,
    },
  },
  4: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P37-E4",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#92278f'>Vocab boost!</span> box. box and complete the pairs of
sentences with the correct form of the adverbs below.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 200, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["close", "flat", "free", "hard", "near"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page37/E4/1.jpg" style='height:7.5cm'/>
        <div style='display:flex'>
            <div>
              <b>1</b><br><br>
              <b>2</b><br> <br>
              <b>3</b><br> <br>
              <b>4</b><br>  <br>
              <b>5</b><br>  
            </div>
            <div style='margin:0px 20px'>
              <b>a</b><br>
              <b>b</b><br>
              <b>a</b><br>
              <b>b</b><br>
              <b>a</b><br>
              <b>b</b><br>
              <b>a</b><br>
              <b>b</b><br>
              <b>a</b><br>
              <b>b</b><br>
            </div>
             <div>
                They’re identical twins; you can # tell them apart.<br>
                It started to rain # just as we left the house.<br>
                This DVD came # with my magazine.<br>
                You can speak # here – we’re all good friends.<br>
                I love cricket so I’ve been following the match #.<br>
                We couldn’t get # enough to ask for his autograph.<br>
                All the students # denied cheating.<br>
                She has a strong voice but a rather # tone.<br>
                I # forgot to pack my swimming costume!<br>
                It’s November and Christmas is getting #.<br>
            </div>
        </div>

          `,
        answer: [
          "hardly",
          "hard",
          "free",
          "freely",
          "closely",
          "close",
          "flatly",
          "flat",
          "nearly",
          "near",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P37-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 150,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "closely",
          "hardly",
          "lately",
          "lively",
          "nearly",
          "rough",
          "widely",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the text with the words below.

`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
        <img src="img/FriendsPlus/Page37/E5/1.jpg" style='height:20cm'/>
        <div style='position:absolute;top:268px;left:343px'>#</div>
        <div style='position:absolute;top:336px;left:299px'>#</div>
        <div style='position:absolute;top:436px;left:163px'>#</div>
        <div style='position:absolute;top:436px;left:442px'>#</div>
        <div style='position:absolute;top:469px;left:348px'>#</div>
        <div style='position:absolute;top:536px;left:199px'>#</div>
        <div style='position:absolute;top:603px;left:162px'>#</div>
        </div>

          `,
        answer: [
          "lively",
          "nearly",
          "rough",
          "closely",
          "widely",
          "hardly",
          "lately",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P37-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the email with the words below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
              <img src="img/FriendsPlus/Page37/E6/1.jpg" style='height:15cm'/>
              <div style='position:absolute;top:129px;left:408px'>#</div>
              <div style='position:absolute;top:164px;left:46px'>#</div>
              <div style='position:absolute;top:198px;left:437px'>#</div>
              <div style='position:absolute;top:233px;left:94px'>#</div>
              <div style='position:absolute;top:267px;left:199px'>#</div>
              <div style='position:absolute;top:336px;left:46px'>#</div>
           
        </div>

          `,
        answer: [
          "easy",
          "pamper",
          "physically",
          "adventure",
          "batteries",
          "experiences",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P37-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P37-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
